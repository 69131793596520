var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: "searchModal", role: "dialog" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _c("form", { attrs: { id: "searchModal__form", method: "POST" } }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                attrs: {
                  id: "searchModal__input",
                  type: "text",
                  name: "search",
                  placeholder: "Search Here ...",
                },
                domProps: { value: _vm.search },
                on: {
                  keyup: _vm.getProducts,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.getProducts.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.search !== ""
        ? _c(
            "div",
            { staticClass: "search-results" },
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                { key: index, staticClass: "search-results__product" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "wp-post-image",
                      attrs: {
                        href:
                          _vm.buildUrl(_vm.applicationParams.locale) +
                          product.slug,
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "search-results__image",
                        attrs: {
                          src: product.images.length
                            ? product.images[0].url
                            : _vm.defaultImage.url,
                          alt: product.images.length
                            ? product.images[0].alt
                            : _vm.defaultImage.alt,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-results__info" }, [
                    _c("h5", { staticClass: "search-results__title" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.buildUrl(_vm.applicationParams.locale) +
                              product.slug,
                          },
                        },
                        [_vm._v(_vm._s(product.title))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "search-results__price" }, [
                      _c("span", { staticClass: "price" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "crossed-out": product.prices["RUB"].sale_price,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "woocommerce-Price-amount amount",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "woocommerce-Price-currencySymbol",
                                  },
                                  [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                ),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.showItemPrice(product)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        product.prices["RUB"].sale_price
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-amount amount",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-currencySymbol",
                                    },
                                    [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                  ),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.showItemPrice(product, true)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: { type: "button", "data-dismiss": "modal" },
      },
      [_c("i", { staticClass: "zmdi zmdi-close" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { attrs: { id: "searchModal__submit", type: "submit" } },
      [_c("i", { staticClass: "zmdi zmdi-search" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }