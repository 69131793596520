//Register components for a global usage
import Vue from "vue";
//Header
import CommonHeader from "./components/CommonHeader";
import CommonHeaderEn from "./components/CommonHeaderEn";
//Footer
import CommonFooter from "./components/CommonFooter";
import CommonFooterEn from "./components/CommonFooterEn";
//Profile Sidebar
import ProfileSidebar from "./components/profile/Sidebar";
//Profile Form
import ProfileForm from "./components/profile/ProfileForm";
//Profile Newsletter Form
import NewsletterForm from "./components/profile/NewsletterForm";
//Textarea with counter
import TextareaWithCounter from "./components/common/TextareaWithCounter";
//Toasted
import Toasted from "vue-toasted";
//Slider
import Slider from "./components/slider/Slider";
import SliderEn from "./components/slider/SliderEn";
//Featured
import Featured from "./components/product/Featured";
//Category sidebar
import Sidebar from "./components/category/Sidebar";
import SidebarEn from "./components/category/SidebarEn";
//Category products
import Productgrid from "./components/category/Productgrid";
import ProductgridEn from "./components/category/ProductgridEn";
//Header cart
import DropDownCart from "./components/cart/DropDownCart";
import DropDownCartEn from "./components/cart/DropDownCartEn";
//Cart page
import Cartpage from "./components/cart/Cartpage";
import CartpageEn from "./components/cart/CartpageEn";
//Search
import Search from "./components/home/Search";
//Login
import Login from "./components/profile/Login";
import LoginEn from "./components/profile/LoginEn";
//Product page
import Productpage from "./components/product/Productpage";
import ProductpageEn from "./components/product/ProductpageEn";
import Productrow from "./components/product/ProductRow";
//Wishlist
import Wishlisttable from "./components/wishlist/Wishlisttable.vue";
import WishlisttableEn from "./components/wishlist/WishlisttableEn.vue";
//Breadcrumbs
import Commonbreadcrumbs from "./components/common/Commonbreadcrumbs.vue";
//Checkout page
import Checkoutpage from "./components/cart/Checkoutpage";
import CheckoutpageEn from "./components/cart/CheckoutpageEn";
//Instagram Widget
import InstagramWidget from "./components/home/InstagramWidget";

//Header
Vue.component(CommonHeader.name, CommonHeader);
Vue.component(CommonHeaderEn.name, CommonHeaderEn);

//Footer
Vue.component(CommonFooter.name, CommonFooter);
Vue.component(CommonFooterEn.name, CommonFooterEn);

//Profile
Vue.component(ProfileSidebar.name, ProfileSidebar);
Vue.component(ProfileForm.name, ProfileForm);
Vue.component(NewsletterForm.name, NewsletterForm);

//Common
Vue.component(TextareaWithCounter.name, TextareaWithCounter);

//Home-page Slider
Vue.component(Slider.name, Slider);
Vue.component(SliderEn.name, SliderEn);

//Home-page Featured
Vue.component(Featured.name, Featured);

//Category Sidebar
Vue.component(Sidebar.name, Sidebar);
Vue.component(SidebarEn.name, SidebarEn);

//Category product grid
Vue.component(Productgrid.name, Productgrid);
Vue.component(ProductgridEn.name, ProductgridEn);

//Header Cart
Vue.component(DropDownCart.name, DropDownCart);
Vue.component(DropDownCartEn.name, DropDownCartEn);

//Cart Page
Vue.component(Cartpage.name, Cartpage);
Vue.component(CartpageEn.name, CartpageEn);

//Header search
Vue.component(Search.name, Search);

//Instagram widget
Vue.component(InstagramWidget.name, InstagramWidget);

//Login page
Vue.component(Login.name, Login);
Vue.component(LoginEn.name, LoginEn);

//Product page
Vue.component(Productpage.name, Productpage);
Vue.component(ProductpageEn.name, ProductpageEn);

//Product page / product row
Vue.component(Productrow.name, Productrow);

//Whishlist
Vue.component(Wishlisttable.name, Wishlisttable);
Vue.component(WishlisttableEn.name, WishlisttableEn);

//Breadcrumbs
Vue.component(Commonbreadcrumbs.name, Commonbreadcrumbs);

//Checkout page, your order
Vue.component(Checkoutpage.name, Checkoutpage);
Vue.component(CheckoutpageEn.name, CheckoutpageEn);

//Notifications
Vue.use(Toasted);
