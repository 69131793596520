<template>
  <div class="cart-wrapper">
    <section v-if="cart.productsCount" class="shop-cart-section section-box">
      <div class="woocommerce">
        <div class="container">
          <div class="entry-content">
            <form class="woocommerce-cart-form" method="POST">
              <table class="shop_table shop_table_responsive cart woocommerce-cart-form__contents">
                <thead>
                  <tr>
                    <th class="product-remove"></th>
                    <th class="product-name">Product</th>
                    <th class="product-price">Price</th>
                    <th class="product-quantity">Quantity</th>
                    <th class="product-subtotal">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :key="index"
                    v-for="(item, index) in cart.items"
                    class="woocommerce-cart-form__cart-item cart_item"
                  >
                    <td class="product-remove">
                      <a @click.prevent="removeItem(index)" href="#" class="remove">
                        <i class="zmdi zmdi-close"></i>
                      </a>
                    </td>
                    <td class="product-name d-flex" data-title="Product">
                      <a :href="buildUrl(applicationParams.locale) + item.slug">
                        <img
                          :src="item.imageUrl ? item.imageUrl : defaultImage.url"
                          :alt="item.title"
                        />
                      </a>

                      <div class="item-title-wrapper">
                        <a :href="buildUrl(applicationParams.locale) + item.slug">{{ item.title }}</a>
                        <div class="versions-wrapper">
                          <a
                            class="version-title"
                            v-show="item.versions"
                            :key="index + ver[0].name"
                            v-for="(ver, index) in item.versions"
                          >{{ verTitle(item.versions, index)}}</a>
                        </div>
                      </div>
                    </td>
                    <td class="product-price" data-title="Price">
                      <span class="woocommerce-Price-amount amount">
                        <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                        {{ countCost(item) }}
                      </span>
                    </td>
                    <td class="product-quantity" data-title="Quantity">
                      <div class="quantity">
                        <span class="modify-qty minus" @click="decrementQuantity(index)">-</span>
                        <input
                          type="number"
                          name="quantity"
                          id="quantity"
                          :value="item.quantity"
                          min="1"
                          class="input-text qty text"
                        />
                        <span class="modify-qty plus" @click="incrementQuantity(index)">+</span>
                      </div>
                    </td>
                    <td class="product-subtotal" data-title="Total">
                      <span class="woocommerce-Price-amount amount">
                        <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                        {{ item.total }}
                      </span>
                    </td>
                  </tr>
                  <template v-for="(bundle, index) in cart.bundles">
                    <tr :key="index + 'bundle'" class="woocommerce-cart-form__cart-item cart_item">
                      <td class="product-remove">
                        <a @click.prevent="removeBundle(index)" href="#" class="remove">
                          <i class="zmdi zmdi-close"></i>
                        </a>
                      </td>
                      <td class="product-name" data-title="Product">
                        <a @click.prevent href="#">
                          <img
                            :src="bundle.items[0].product.images ? bundle.items[0].product.images[0].url : defaultImage.url"
                            alt="Bundle image"
                          />
                        </a>
                        <a @click.prevent href="#">{{ bundle.title }}</a>
                      </td>
                      <td class="product-price" data-title="Price">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ bundle.total }}
                        </span>
                      </td>
                      <td class="product-quantity" data-title="Quantity">
                        <div class="quantity">
                          <span class="modify-qty minus" @click="decrementBundleQuantity(index)">-</span>
                          <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            :value="bundle.quantity"
                            min="1"
                            class="input-text qty text"
                          />
                          <span class="modify-qty plus" @click="incrementBundleQuantity(index)">+</span>
                        </div>
                      </td>
                      <td class="product-subtotal" data-title="Total">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ bundle.total * bundle.quantity }}
                        </span>
                      </td>
                    </tr>
                    <tr
                      :key="index + bundle.title"
                      v-for="(item, index) in bundle.items"
                      class="woocommerce-cart-form__cart-item cart_item"
                      :class="{'cart_item--no-border' : index !== (bundle.items.length - 1), 'padding0' : index !== 0, 'padding-top30' : index === 0, 'padding-bottom30' : index === (bundle.items.length - 1)}"
                    >
                      <td class="product-remove product-remove--bundle-item padding15">
                        <a @click.prevent="removeBundle(index)" href="#" class="remove">
                          <i class="zmdi zmdi-close"></i>
                        </a>
                      </td>
                      <td class="product-name padding15" data-title="Product">
                        <a @click.prevent href="#" class="product-name--bundle-item">
                          <div
                            v-if="item.discount_percentage"
                            class="bundles__discount-flag"
                          >{{ '-' + item.discount_percentage + '%' }}</div>
                          <img
                            :src="item.product.images ? item.product.images[0].url : defaultImage.url"
                            alt="Bundle item image"
                          />
                        </a>
                        <a @click.prevent href="#">{{ item.product.title }}</a>
                      </td>
                      <td class="product-price padding15" data-title="Price">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ item.product.prices[activeCurrency.code].price }}
                        </span>
                      </td>
                      <td class="product-quantity padding15" data-title="Quantity">
                        <div class="quantity">
                          <!-- <span class="modify-qty minus" @click="decrementBundleQuantity(index)">-</span> -->
                          <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            value="1"
                            min="1"
                            class="input-text qty text qty--bundle-item"
                          />
                          <!-- <span class="modify-qty plus" @click="incrementBundleQuantity(index)">+</span> -->
                        </div>
                      </td>
                      <td class="product-subtotal padding15" data-title="Total">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ substractPercent(item.product.prices[activeCurrency.code].price, item.discount_percentage) }}
                        </span>
                      </td>
                    </tr>
                  </template>

                  <!-- <tr>
                    <td colspan="6" class="actions">
                      <div class="coupon">
                        <input
                          type="text"
                          name="coupon_code"
                          class="input-text"
                          id="coupon_code"
                          value
                          v-model="coupon.code"
                          placeholder="Coupon code"
                        />
                        <div>
                          <input
                            type="button"
                            @click.prevent="calculateDiscount"
                            class="button au-btn btn-small"
                            name="apply_coupon"
                            value="Apply Coupon"
                          />
                          <span>
                            <i class="zmdi zmdi-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                      <div class="action-btn">
                      <input
                        @click.prevent="setCart"
                        class="button au-btn btn-small"
                        name="update_cart"
                        value="Update Cart"
                      />
                      <span>
                        <i class="zmdi zmdi-arrow-right"></i>
                      </span>
                      </div>
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <td colspan="6" class="actions">
                      <div class="coupon">
                        <input
                          type="text"
                          name="giftcard_code"
                          class="input-text"
                          id="giftcard_code"
                          value
                          v-model="giftcard.code"
                          placeholder="Gift card code"
                        />
                        <div>
                          <input
                            @click.prevent="calculateDiscount"
                            class="button au-btn btn-small"
                            name="apply_giftcard"
                            value="Apply Giftcard"
                          />
                          <span>
                            <i class="zmdi zmdi-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </form>
            <div class="cart-collaterals">
              <div class="cart_totals">
                <h2>Cart totals</h2>
                <table class="shop_table shop_table_responsive">
                  <tbody>
                    <tr class="cart-subtotal">
                      <th>Subtotal</th>
                      <td data-title="Subtotal">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ cart.subtotal }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="discount" class="cart-subtotal">
                      <th>Discount</th>
                      <td data-title="Discount">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ discount }}
                        </span>
                      </td>
                    </tr>
                    <tr class="cart-subtotal">
                      <th>Total</th>
                      <td data-title="Subtotal">
                        <span class="woocommerce-Price-amount amount">
                          <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                          {{ cart.total }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="wc-proceed-to-checkout">
                  <a href="/en/checkout" class="checkout-button button wc-forward au-btn btn-small">
                    Proceed to Checkout
                    <i class="zmdi zmdi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="!cart.productsCount" class="empty-cart">
      <h3 class="empty-cart__text">Your shopping cart is empty. Please add some products to it.</h3>
    </div>
  </div>
</template>

<script>
//Import the data for the cart
import { cartModel, itemModel } from "../../static/cart/cart";

//Event bus
import EventBus from "../../EventBus";

//Library for working with requests
import axios from "axios";

export default {
  name: "CartpageEn",
  data() {
    return {
      coupon: {
        code: ""
      },
      giftcard: {
        code: ""
      },
      cart: {
        items: [],
        bundles: [],
        giftCardCode: "",
        couponCode: ""
      },
      order_items: [],
      // discountedPrice: 0,
      discount: 0,
      activeCurrency: {},
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  watch: {
    "cart.items": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();
        //Count products total quantity
        this.countProducts();

        this.calculateDiscount();

        this.saveCart();
      },
      deep: true
    },
    "cart.bundles": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();

        //Count products total quantity
        this.countProducts();

        this.calculateDiscount();

        this.saveCart();
      },
      deep: true
    }
  },
  computed: {
    cartTotal: function() {
      let total = 0;
      for (let item of this.cart.items) {
        total += item.quantity;
      }
      return total;
    }
  },
  methods: {
    countCost(item) {
      let cost = +item.price;
      if (item.versionsCost) {
        cost = +item.price + item.versionsCost;
      }
      return cost;
    },
    verTitle(versions, index) {
      let verStr = "";
      verStr +=
        versions[index][0].name +
        ": " +
        versions[index][0].versions[versions[index][1]].option_value.value;

      if (index < versions.length - 1) {
        verStr += ", ";
      }
      return verStr;
    },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    saveCart() {
      //Save a cart to the localStorage
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    substractPercent(price, discount) {
      let percentsLeft = 100 - discount;
      percentsLeft *= 0.01;
      return Math.round(price * percentsLeft);
    },
    incrementBundleQuantity(index) {
      let item = this.cart.bundles[index];

      item.quantity += 1;

      //Update checkout products list
      this.sendUpdatedBundles();
    },
    decrementBundleQuantity(index) {
      let item = this.cart.bundles[index];

      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        this.removeBundle(index);
      }
      //Update checkout products list
      this.sendUpdatedBundles();
    },
    removeBundle(index) {
      this.cart.bundles.splice(index, 1);
      this.sendUpdatedBundles();
    },

    calculateDiscount() {
      this.order_items = [];
      this.order_bundles = [];
      this.cart.couponCode = this.coupon.code;
      this.cart.giftCardCode = this.giftcard.code;
      this.order_code = this.activeCurrency.code;
      let note = this.showNotification;
      // this.cart.currency_code = this.activeCurrency.code;

      let requestUrl = this.API.order.calculate;
      let orderItems = this.order_items;
      let orderBundles = this.order_bundles;

      this.cart.items.forEach(function(product) {
        if (product.selected_versions) {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity,
            selected_versions: product.selected_versions
          });
        } else {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity
          });
        }
      });
      if (this.cart.bundles.length) {
        for (let bundle of this.cart.bundles) {
          let bundleObj = {
            bundle_id: bundle.id,
            quantity: bundle.quantity
          };
          orderBundles.push(bundleObj);
        }
      }
      let requestParams = {
        order_items: this.order_items,
        coupon_code: this.cart.couponCode,
        giftcard_code: this.cart.giftCardCode,
        bundles: this.order_bundles,
        currency_code: this.order_code
      };

      axios.post(requestUrl, requestParams).then(
        response => {
          this.discount = response.data.discount;
          console.log("subtotal: ", response.data.subtotal);
          console.log("total: ", response.data.total);
          this.cart.subtotal = response.data.subtotal;
          this.cart.total = response.data.total;
        },
        function() {
          note("error", "Code is not valid!");
        }
      );
    },

    setCart() {
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.setNewCart();
      }
    },
    setNewCart() {
      this.cart = Object.assign({}, cartModel);

      this.cart.items = [];
      this.cart.bundles = [];
      this.cart.couponCode = "";
      this.cart.giftCardCode = "";

      this.cart.currency = this.applicationParams.currency;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    addItem(product) {
      let existingItem = this.cart.items.find(function(item) {
        return item.productId === product.id;
      });

      if (existingItem === undefined) {
        let newItem = Object.assign({}, itemModel);

        newItem.productId = product.id;
        newItem.title = product.title;
        newItem.slug = product.slug;
        newItem.price = product.price;

        if (product.images.length) {
          newItem.imageUrl = product.images[0].url;
        }

        this.cart.items.push(newItem);
      } else {
        let itemIndex = this.cart.items.indexOf(existingItem);

        this.incrementQuantity(itemIndex);
      }

      //Show success notification
      this.showNotification("success", "Product has been added to cart.");
    },
    removeItem(index) {
      this.cart.items.splice(index, 1);
    },
    calculatePrices() {
      let cart = this.cart;
      let subtotal = 0;
      let cur = this.activeCurrency.code;
      if (cart.items.length) {
        cart.items.forEach(function(item) {
          let itemTotal = Math.round(item.price * item.quantity * 100) / 100;

          // Add version price

          if (item.versions) {
            let verPrice = 0;
            item.versions.forEach(function(option) {
              verPrice += option[0].versions[option[1]].version_prices[cur];
            });

            // add version cost to item.price

              if(item.promotion !== null && item.promotion !== undefined){

                  let promo = item.promotion;

                  if(promo.is_fixed_price && verPrice >= promo.value){

                      verPrice -= promo.value;

                  }
                  else{

                      let discount = (verPrice * parseInt(promo.value)) / 100;
                      verPrice -= discount;

                  }

              }

              itemTotal += verPrice * item.quantity;

            item.versionsCost = verPrice;

            // End of add version cost to item.price
          }

          // End of "Add version price'

          item.total = itemTotal;

          subtotal += item.total;
        });
      }

      if (cart.bundles.length) {
        cart.bundles.forEach(function(bundle) {
          subtotal += bundle.price * bundle.quantity;
          bundle.total = bundle.price * bundle.quantity;
        });
      }

      cart.subtotal = subtotal;

      cart.total = subtotal;
    },
    incrementQuantity(index) {
      let item = this.cart.items[index];

      item.quantity += 1;

      //Update checkout products list
      this.sendUpdatedProducts();
    },
    decrementQuantity(index) {
      let item = this.cart.items[index];

      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        this.removeItem(index);
      }

      //Update checkout products list
      this.sendUpdatedProducts();
    },
    countProducts() {
      let productsCount = 0;

      this.cart.items.forEach(function(item) {
        productsCount += item.quantity;
      });
      this.cart.bundles.forEach(function(item) {
        productsCount += item.quantity;
      });

      this.cart.productsCount = productsCount;
    },
    listenEvents() {
      //Receive data form an add to cart button
      // this.EventBus.$on("add-to-cart", product => {
      //   this.addItem(product);
      //   this.calculateDiscount();
      // });

      //Update product list
      this.EventBus.$on("products-updated", updatedList => {
        this.cart.items = updatedList;
        // this.calculateDiscount();
      });

      //Receive a data from a checkout page
      this.EventBus.$on("product-quantity-increment", product => {
        this.incrementQuantity(product);
        // this.calculateDiscount();
      });

      this.EventBus.$on("product-quantity-decrement", product => {
        this.decrementQuantity(product);
        // this.calculateDiscount();
      });

      this.EventBus.$on("bundles-updated", updatedList => {
        this.cart.bundles = updatedList;
      });

      this.EventBus.$on("order-placed", result => {
        this.setNewCart();
      });
    },
    sendUpdatedProducts() {
      //Update a checkout product list
      this.EventBus.$emit("products-updated", this.cart.items);
    },
    sendUpdatedBundles() {
      //Update a checkout product list
      this.EventBus.$emit("bundles-updated", this.cart.bundles);
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },
  mounted() {
    // Set currancy
    this.setCurrency();
    //Set the cart data
    this.setCart();

    //Listen to the events
    this.listenEvents();

    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="scss" scoped>
// .empty-cart
//   text-align: center

.empty-cart__text {
  width: 86.4%;
  margin: 0 auto;
  padding: 150px 0;
}

.product-remove--bundle-item i {
  opacity: 0;
  pointer-events: none;
}

.product-remove--bundle-item {
  pointer-events: none;
}

.product-name--bundle-item {
  @media (min-width: 767px) {
    position: relative;
    margin-left: calc((115px - 15px) / 2);
  }
  @media (max-width: 767px) {
    // margin-left: 0;
  }
}

.cart_item--no-border {
  @media (min-width: 767px) {
    border-bottom: none !important;
  }
}

.qty--bundle-item {
  @media (min-width: 767px) {
    border: none !important;
  }
}

.padding15 {
  @media (min-width: 767px) {
    padding: 15px !important;
  }
}

.padding0 td {
  @media (min-width: 767px) {
    padding-top: 0 !important;
  }
}

.padding-top30 td {
  @media (min-width: 767px) {
    padding-top: 30px !important;
  }
}

.padding-bottom30 td {
  @media (min-width: 767px) {
    padding-bottom: 30px !important;
  }
}

.bundles__discount-flag {
  @media (min-width: 767px) {
    top: 10px !important;
  }
  @media (max-width: 767px) {
    left: calc(50% - 20px) !important;
    top: -80px;
    z-index: 20;
    transform: translateX(-50%);
  }
}

.item-title-wrapper {
  // display: inline-block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    margin-left: auto;
  }
}

.item-title-wrapper div {
  font-size: 12px;
}

.d-flex {
  @media (min-width: 767px) {
    display: flex !important;
    width: auto !important;
    min-width: 350px;
  }
}

.version-title {
  display: flex;
  flex-direction: column;
}
.remove {
  @media (max-width: 767px) {
    // margin: 10px;
    top: 7px !important;
    right: 15px !important;
  }  
}

.section-box {
  @media (max-width: 767px) {
    padding-top: 0; 
  }  
}
</style>