var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout-wrapper" }, [
    _vm.cart.productsCount
      ? _c("section", { staticClass: "checkout-section section-box" }, [
          _c("div", { staticClass: "woocommerce" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "entry-content" }, [
                !_vm.applicationParams.user
                  ? _c("div", { staticClass: "woocommerce-info" }, [
                      _vm._v("\n            Returning customer?\n            "),
                      _c("a", { staticClass: "showlogin" }, [
                        _vm._v("Click here to login"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "form",
                  { staticClass: "checkout_coupon", attrs: { method: "post" } },
                  [
                    _c("p", { staticClass: "form-row-first" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.coupon.code,
                            expression: "coupon.code",
                          },
                        ],
                        staticClass: "input-text",
                        attrs: {
                          type: "text",
                          name: "coupon_code",
                          placeholder: "Coupon code",
                          id: "coupon_code",
                          value: "",
                        },
                        domProps: { value: _vm.coupon.code },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.coupon, "code", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "form-row-last" }, [
                      _c("input", {
                        staticClass: "button au-btn btn-small btn-mobile",
                        attrs: {
                          type: "submit",
                          name: "apply_coupon",
                          value: "Apply Coupon",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.calculateDiscount.apply(null, arguments)
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "woocommerce-checkout-review-order" },
                        [
                          _c("h2", { attrs: { id: "order_review_heading" } }, [
                            _vm._v("Your order"),
                          ]),
                          _vm._v(" "),
                          _c("table", { staticClass: "shop_table" }, [
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.cart.items, function (item, index) {
                                  return _c(
                                    "tr",
                                    { key: index, staticClass: "cart_item" },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "product-name" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.buildUrl(
                                                    _vm.applicationParams.locale
                                                  ) + item.slug,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: item.imageUrl
                                                    ? item.imageUrl
                                                    : _vm.defaultImage.url,
                                                  alt: item.title,
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.buildUrl(
                                                    _vm.applicationParams.locale
                                                  ) + item.slug,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "review-wrap product-quantity--default-pointer",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cart_item_title",
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "product-quantity",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "x" + item.quantity
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "product-total" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "woocommerce-Price-amount amount",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-currencySymbol",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.activeCurrency.code
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.countCost(item) *
                                                      item.quantity
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.cart.bundles,
                                  function (bundle, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index + "bundle",
                                        staticClass: "cart_item",
                                      },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "product-name" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: bundle.items[0].product
                                                  .images
                                                  ? bundle.items[0].product
                                                      .images[0].url
                                                  : _vm.defaultImage.url,
                                                alt: "Bundle image",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "review-wrap" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cart_item_title",
                                                  },
                                                  [_vm._v(_vm._s(bundle.title))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "product-quantity",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        "x" + bundle.quantity
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bundled-products",
                                                  },
                                                  _vm._l(
                                                    bundle.items,
                                                    function (item, index) {
                                                      return _c(
                                                        "a",
                                                        {
                                                          key:
                                                            index +
                                                            item.product,
                                                          attrs: {
                                                            href:
                                                              _vm.buildUrl(
                                                                _vm
                                                                  .applicationParams
                                                                  .locale
                                                              ) +
                                                              item.product.slug,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.product
                                                                  .title +
                                                                  _vm.bundledItemsComa(
                                                                    index,
                                                                    bundle.items
                                                                      .length
                                                                  )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "product-total" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "woocommerce-Price-amount amount",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "woocommerce-Price-currencySymbol",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.activeCurrency.code
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      bundle.total *
                                                        bundle.quantity
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("tfoot", [
                              _c("tr", [
                                _c("td", [
                                  _c("ul", [
                                    _c("li", { staticClass: "cart-subtotal" }, [
                                      _c(
                                        "span",
                                        { staticClass: "review-total-title" },
                                        [_vm._v("Subtotal")]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "woocommerce-Price-amount amount",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "woocommerce-Price-currencySymbol",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.activeCurrency.code
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.cart.subtotal) +
                                                "\n                              "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm.discount
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "cart-subtotal cart-discount",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "review-total-title",
                                              },
                                              [_vm._v("Discount")]
                                            ),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "woocommerce-Price-currencySymbol",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.activeCurrency
                                                            .code
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(_vm.discount) +
                                                      "\n                              "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    +_vm.activeShippingMethod.cost !== 0
                                      ? _c(
                                          "li",
                                          { staticClass: "cart-subtotal" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "review-total-title",
                                              },
                                              [_vm._v("Shipping")]
                                            ),
                                            _vm._v(" "),
                                            !_vm.activeShippingMethod
                                              ? _c("p", [
                                                  _vm._v(
                                                    "there are no shipping methods available. please double check your address, or contact us if you need any help."
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount shipping-price",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "woocommerce-Price-currencySymbol",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.activeCurrency
                                                            .code
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(
                                                        _vm.activeShippingCost
                                                      ) +
                                                      "\n                              "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "order-total" }, [
                                      _c(
                                        "span",
                                        { staticClass: "review-total-title" },
                                        [_vm._v("Total")]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "woocommerce-Price-amount amount",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "woocommerce-Price-currencySymbol",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.activeCurrency.code
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.cart.total +
                                                    +_vm.activeShippingCost
                                                ) +
                                                "\n                              "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "woocommerce-checkout-payment" },
                            [
                              _c(
                                "h2",
                                { staticClass: "payment-method-title" },
                                [_vm._v("Shipping method")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "payment_methods payment_methods--first",
                                },
                                _vm._l(
                                  _vm.shippingMethods,
                                  function (method, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index + "method.name",
                                        staticClass: "wc_payment_method",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "input-radio",
                                          attrs: {
                                            type: "radio",
                                            id: "shipping_method_" + method.id,
                                            name: "shipping_method",
                                          },
                                          domProps: {
                                            checked: index === 0,
                                            value: method.id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.shippingChanged(method)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for:
                                                "shipping_method_" + method.id,
                                            },
                                          },
                                          [_vm._v(_vm._s(method.name))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "shipping-cost" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.shippingCost(
                                                  method.costs[
                                                    _vm.activeCurrency.code
                                                  ]
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "abr-width_100 shipping_box",
                                            class:
                                              "shipping_method_" + method.id,
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(method.description)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                { staticClass: "payment-method-title" },
                                [_vm._v("Payment method")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "payment_methods" },
                                _vm._l(
                                  _vm.paymentMethods,
                                  function (method, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index + "method.name",
                                        staticClass: "wc_payment_method",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "input-radio",
                                          attrs: {
                                            type: "radio",
                                            id: "payment_method_" + method.id,
                                            name: "payment_method",
                                          },
                                          domProps: {
                                            checked: index === 0,
                                            value: method.id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.activePaymentMethod = method
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for:
                                                "payment_method_" + method.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(method.translations.name)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12",
                    },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "checkout woocommerce-checkout",
                          attrs: {
                            name: "billingDetails",
                            id: "billingDetails",
                            method: "POST",
                          },
                          on: {
                            submit: function ($event) {
                              return _vm.submitForm($event)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "woocommerce-billing-fields" },
                            [
                              _c("h2", [_vm._v("Billing details")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "woocommerce-billing-fields__field-wrapper",
                                },
                                [
                                  _c("p", { staticClass: "form-row-first" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.billingDetails.customer_name,
                                          expression:
                                            "billingDetails.customer_name",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        id: "billing_first_name",
                                        placeholder: "First Name *",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.billingDetails.customer_name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "customer_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-last" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.billingDetails.customer_surname,
                                          expression:
                                            "billingDetails.customer_surname",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        name: "billing_last_name",
                                        id: "billing_last_name",
                                        placeholder: "Last Name *",
                                        required: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.billingDetails.customer_surname,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "customer_surname",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-wide" }, [
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.billingDetails.country,
                                            expression:
                                              "billingDetails.country",
                                          },
                                        ],
                                        staticClass: "country_select",
                                        attrs: {
                                          required: "",
                                          id: "billing_country",
                                          name: "billing_country",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.billingDetails,
                                              "country",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.countries,
                                        function (country, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: index + country,
                                              domProps: { value: country },
                                            },
                                            [_vm._v(_vm._s(country))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _vm._m(3),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-wide" }, [
                                    _vm._m(4),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.billingDetails.address_one,
                                          expression:
                                            "billingDetails.address_one",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        name: "billing_address_1",
                                        id: "billing_address_1",
                                        placeholder: "Street address",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.billingDetails.address_one,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "address_one",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-wide" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.billingDetails.address_two,
                                          expression:
                                            "billingDetails.address_two",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        name: "billing_address_2",
                                        id: "billing_address_2",
                                        placeholder:
                                          "Apartment, suite, unit etc.",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.billingDetails.address_two,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "address_two",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-wide" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.billingDetails.city,
                                          expression: "billingDetails.city",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        name: "billing_city",
                                        id: "billing_city",
                                        placeholder: "Town/City *",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.billingDetails.city,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "city",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-wide" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.billingDetails.zip_code,
                                          expression: "billingDetails.zip_code",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Postcode",
                                        name: "billing_postcode",
                                        id: "billing_postcode",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.billingDetails.zip_code,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "zip_code",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-first" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.billingDetails.customer_phone,
                                          expression:
                                            "billingDetails.customer_phone",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "tel",
                                        name: "billing_phone",
                                        id: "billing_phone",
                                        placeholder: "Phone *",
                                        required: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.billingDetails.customer_phone,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "customer_phone",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form-row-last" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.billingDetails.customer_email,
                                          expression:
                                            "billingDetails.customer_email",
                                        },
                                      ],
                                      staticClass: "input-text",
                                      attrs: {
                                        type: "email",
                                        name: "billing_email",
                                        id: "billing_email",
                                        placeholder: "Email Address *",
                                        required: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.billingDetails.customer_email,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.billingDetails,
                                            "customer_email",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "woocommerce-additional-fields" },
                            [
                              _c("h2", [_vm._v("Additional information")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "woocommerce-additional-fields__field-wrapper",
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "notes",
                                      attrs: { id: "order_comments_field" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "order_comments" } },
                                        [_vm._v("Order notes")]
                                      ),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.billingDetails.comment,
                                            expression:
                                              "billingDetails.comment",
                                          },
                                        ],
                                        staticClass: "input-text",
                                        attrs: {
                                          name: "order_comments",
                                          id: "order_comments",
                                          placeholder:
                                            "Note about your order, eg. special notes fordelivery.",
                                        },
                                        domProps: {
                                          value: _vm.billingDetails.comment,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.billingDetails,
                                              "comment",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(6),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.cart.productsCount
      ? _c("div", { staticClass: "empty-cart" }, [
          _c("h3", { staticClass: "empty-cart__text" }, [
            _vm._v(
              "Your shopping cart is empty. Please add some products to it."
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "form",
      {
        staticClass: "woocommerce-form woocommerce-form-login",
        attrs: { method: "POST" },
      },
      [
        _c("p", [
          _vm._v(
            "If you have shopped with us before, please enter your details in the boxes below. If you are a new customer, please proceed to the Billing & Shipping section."
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-row-first" }, [
          _c("input", {
            staticClass: "input-text",
            attrs: {
              type: "text",
              name: "username",
              id: "username",
              placeholder: "Username or email*",
            },
          }),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-row-last" }, [
          _c("input", {
            staticClass: "input-text",
            attrs: {
              type: "password",
              name: "password",
              id: "password",
              placeholder: "Password*",
            },
          }),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-button" }, [
          _c("label", [
            _c("input", {
              staticClass: "woocommerce-Button au-btn btn-small",
              attrs: { type: "submit", name: "login", value: "Login" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "arrow-right" }, [
              _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
            ]),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "woocommerce-form__label" }, [
            _c("input", {
              staticClass: "woocommerce-form__input",
              attrs: {
                name: "rememberme",
                type: "checkbox",
                id: "rememberme",
                value: "forever",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("Create an account?")]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "woocommerce-LostPassword" }, [
          _c("a", { attrs: { href: "lost-password.html" } }, [
            _vm._v("Lost your password?"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "woocommerce-info" }, [
      _vm._v("\n            Have a coupon?\n            "),
      _c("a", { staticClass: "showcoupon" }, [
        _vm._v("Click here to enter your code"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "billing_country" } }, [
      _vm._v("\n                        Country\n                        "),
      _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
        _vm._v("*"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "select-btn" }, [
      _c("i", { staticClass: "zmdi zmdi-caret-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "billing_address_1" } }, [
      _vm._v("\n                        Address\n                        "),
      _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
        _vm._v("*"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "woocommerce-account-fields" }, [
      _c("p", { staticClass: "woocommerce-validated" }, [
        _c("label", { staticClass: "woocommerce-form__label-for-checkbox" }, [
          _c("input", {
            staticClass: "woocommerce-form__input-checkbox policy-chbx",
            attrs: {
              id: "privacy-policy",
              type: "checkbox",
              name: "privacy-policy",
              checked: "",
              required: "",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("I agree with privacy policy")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" },
        [
          _c("div", { staticClass: "woocommerce-checkout-review-order" }, [
            _c("div", { staticClass: "woocommerce-checkout-payment" }, [
              _c("div", { staticClass: "place-order" }, [
                _c("input", {
                  staticClass:
                    "button alt au-btn btn-small active-background active-abr-btn",
                  attrs: {
                    type: "submit",
                    name: "woocommerce_checkout_place_order",
                    id: "place_order",
                    value: "Place Order",
                    form: "billingDetails",
                  },
                }),
                _vm._v(" "),
                _c("span", [_c("i", { staticClass: "zmdi zmdi-arrow-right" })]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }