<template>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <div class="profile-sidebar">
            <div class="profile-sidebar-list">
                <div class="profile-sidebar-list-item">
                    <div class="sidebar-list-parent profile-list-active">
                        <a href="/profile/edit">
                            <span class="parent-title">Personal Information</span>
                        </a>
                    </div>
                </div>
                <div class="profile-sidebar-list-item">
                    <div class="sidebar-list-parent">
                        <a href="/profile/subscription">
                            <span class="parent-title">Newsletter</span>
                        </a>
                    </div>
                </div>
                <div class="profile-sidebar-list-item">
                    <div class="sidebar-list-parent">
                        <a href="/profile/orders">
                            <span class="parent-title">Order History</span>
                        </a>
                    </div>
                </div>
                <div class="profile-sidebar-list-item">
                    <div class="sidebar-list-parent">
                        <a href="/profile/shipping">
                            <span class="parent-title">Shipping Address</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'ProfileSidebar'
    }

</script>