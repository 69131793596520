<template>
  <div class="checkout-wrapper">
    <section v-if="cart.productsCount" class="checkout-section section-box">
      <div class="woocommerce">
        <div class="container">
          <div class="entry-content">
            <div v-if="!applicationParams.user" class="woocommerce-info">
              Вже є обліковий запис?
              <a class="showlogin">Натисніть тут, щоб увійти</a>
            </div>
            <form class="woocommerce-form woocommerce-form-login" method="POST">
              <p>Якщо ви робили покупки у нас раніше, будь ласка, введіть свої дані у полях нижче. Якщо ви новий клієнт, перейдіть до розділу Оплата.</p>
              <p class="form-row-first">
                <input
                  type="text"
                  class="input-text"
                  name="username"
                  id="username"
                  placeholder="Ім'я користувача або адресу Email*"
                />
              </p>
              <p class="form-row-last">
                <input
                  class="input-text"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Пароль*"
                />
              </p>
              <p class="form-button">
                <label>
                  <input
                    type="submit"
                    class="woocommerce-Button au-btn btn-small"
                    name="login"
                    value="Вхід"
                  />
                  <span class="arrow-right">
                    <i class="zmdi zmdi-arrow-right"></i>
                  </span>
                </label>
                <label class="woocommerce-form__label">
                  <input
                    class="woocommerce-form__input"
                    name="rememberme"
                    type="checkbox"
                    id="rememberme"
                    value="forever"
                  />
                  <span>Створити обліковий запис?</span>
                </label>
              </p>
              <p class="woocommerce-LostPassword">
                <a href="lost-password.html">Нагадати пароль?</a>
              </p>
            </form>
            <div class="woocommerce-info">
              Чи є купон?
              <a class="showcoupon">Натисніть тут, щоб ввести купон</a>
            </div>
            <form class="checkout_coupon" method="post">
              <p class="form-row-first">
                <input
                  type="text"
                  name="coupon_code"
                  class="input-text"
                  placeholder="Coupon code"
                  id="coupon_code"
                  value
                  v-model="coupon.code"
                />
              </p>
              <p class="form-row-last">
                <input
                  type="submit"
                  class="button au-btn btn-small btn-mobile"
                  name="apply_coupon"
                  value="Застосувати"
                  @click.prevent="calculateDiscount"
                />
                <!-- <span class="arrow-right">
                  <i class="zmdi zmdi-arrow-right"></i>
                </span> -->
              </p>
            </form>

            <!-- <div class="woocommerce-info">
              Есть подарочная карта?
              <a class="showcard">Нажмите здесь, чтобы ввести свой код карты</a>
            </div>
            <form class="checkout_card" method="post">
              <p class="form-row-first form-row-first--card">
                <input
                  type="text"
                  name="card_code"
                  class="input-text"
                  placeholder="Gift card code"
                  id="card_code"
                  v-model="giftcard.code"
                  value
                />
              </p>
              <p class="form-row-last">
                <input
                  type="submit"
                  class="button au-btn btn-small"
                  name="apply_giftcard"
                  value="Apply Gift Card"
                  @click.prevent="calculateDiscount"
                />
                <span class="arrow-right">
                  <i class="zmdi zmdi-arrow-right"></i>
                </span>
              </p>
            </form>-->
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="woocommerce-checkout-review-order">
                  <h2 id="order_review_heading">Ваше замовлення</h2>
                  <table class="shop_table">
                    <tbody>
                      <tr :key="index" v-for="(item, index) in cart.items" class="cart_item">
                        <td class="product-name">
                          <a :href="buildUrl(applicationParams.locale) + item.slug">
                            <img
                              :src="item.imageUrl ? item.imageUrl : defaultImage.url"
                              :alt="item.title"
                            />
                          </a>
                          <a :href="buildUrl(applicationParams.locale) + item.slug">
                            <div class="review-wrap product-quantity--default-pointer">
                              <span class="cart_item_title">{{ item.title }}</span>

                              <span class="product-quantity">{{ 'x' + item.quantity }}</span>
                            </div>
                          </a>
                        </td>
                        <td class="product-total">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ countCost(item) * item.quantity }}
                          </span>
                        </td>
                      </tr>
                      <tr
                        :key="index + 'bundle'"
                        v-for="(bundle, index) in cart.bundles"
                        class="cart_item"
                      >
                        <td class="product-name">
                          <img
                            :src="bundle.items[0].product.images ? bundle.items[0].product.images[0].url : defaultImage.url"
                            alt="Bundle image"
                          />
                          <div class="review-wrap">
                            <span class="cart_item_title">{{ bundle.title }}</span>
                            <span class="product-quantity">{{ 'x' + bundle.quantity }}</span>
                            <br />
                            <span class="bundled-products">
                              <a
                                :key="index + item.product"
                                v-for="(item, index) in bundle.items"
                                :href="buildUrl(applicationParams.locale) + item.product.slug"
                              >
                                <span>{{ item.product.title + bundledItemsComa(index, bundle.items.length) }}</span>
                              </a>
                            </span>
                          </div>
                        </td>
                        <td class="product-total">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ bundle.total * bundle.quantity }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <ul>
                            <li class="cart-subtotal">
                              <span class="review-total-title">Проміжний підсумок</span>
                              <p>
                                <span class="woocommerce-Price-amount amount">
                                  <span
                                    class="woocommerce-Price-currencySymbol"
                                  >{{ activeCurrency.code }}</span>
                                  {{ cart.subtotal }}
                                </span>
                              </p>
                            </li>
                            <li v-if="discount" class="cart-subtotal cart-discount">
                              <span class="review-total-title">Знижка</span>
                              <p>
                                <span class="woocommerce-Price-amount amount">
                                  <span
                                    class="woocommerce-Price-currencySymbol"
                                  >{{ activeCurrency.code }}</span>
                                  {{ discount }}
                                </span>
                              </p>
                            </li>
                            <li v-if="+activeShippingMethod.cost !== 0" class="cart-subtotal">
                              <span class="review-total-title">Доставка</span>
                              <p
                                v-if="!activeShippingMethod"
                              >немає доступних способів доставки. будь ласка, перевірте вашу адресу або зв'яжіться з нами, якщо вам потрібна допомога.</p>
                              <!-- <p v-if="+activeShippingMethod.cost == 0">Free</p> -->
                              <p>
                                <span class="woocommerce-Price-amount amount shipping-price">
                                  <span
                                    class="woocommerce-Price-currencySymbol"
                                  >{{ activeCurrency.code }}</span>
                                  {{ activeShippingCost }}
                                </span>
                              </p>
                            </li>
                            <li class="order-total">
                              <span class="review-total-title">Підсумок</span>
                              <p>
                                <span class="woocommerce-Price-amount amount">
                                  <span
                                    class="woocommerce-Price-currencySymbol"
                                  >{{ activeCurrency.code }}</span>
                                  {{ cart.total + +activeShippingCost }}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="woocommerce-checkout-payment">
                    <h2 class="payment-method-title">Спосіб доставки</h2>
                    <ul class="payment_methods payment_methods--first">
                      <li
                        :key="index + 'method.name'"
                        v-for="(method, index) in shippingMethods"
                        class="wc_payment_method"
                      >
                        <input
                          type="radio"
                          :id="'shipping_method_' + method.id"
                          class="input-radio"
                          name="shipping_method"
                          @click="shippingChanged(method)"
                          :checked="index === 0"
                          :value="method.id"
                        />
                        <label :for="'shipping_method_' + method.id">{{ method.name }}</label>
                        <span
                          class="shipping-cost"
                        >{{ shippingCost(method.costs[activeCurrency.code]) }}</span>
                        <div
                          class="abr-width_100 shipping_box"
                          :class="'shipping_method_' + method.id"
                        >
                          <p>{{ method.description }}</p>
                        </div>
                      </li>
                    </ul>

                    <h2 class="payment-method-title">Спосіб оплати</h2>
                    <ul class="payment_methods">
                      <li
                        :key="index + 'method.name'"
                        v-for="(method, index) in paymentMethods"
                        class="wc_payment_method"
                      >
                        <input
                          type="radio"
                          :id="'payment_method_' + method.id"
                          class="input-radio"
                          name="payment_method"
                          @click="activePaymentMethod = method"
                          :checked="index === 0"
                          :value="method.id"
                        />
                        <label :for="'payment_method_' + method.id">{{ method.name }}</label>
                        <div
                          class="abr-width_100 payment_box"
                          :class="'payment_method_' + method.id"
                        >
                          <p>{{ method.description }}</p>
                        </div>
                      </li>
                    </ul>

                    <!-- <div class="place-order">
                      <input
                        type="submit"
                        class="button alt au-btn btn-small"
                        name="woocommerce_checkout_place_order"
                        id="place_order"
                        value="Оформить заказ"
                        form="billingDetails"
                      />
                      <span>
                        <i class="zmdi zmdi-arrow-right"></i>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <form
                  @submit="submitForm($event)"
                  name="billingDetails"
                  id="billingDetails"
                  class="checkout woocommerce-checkout"
                  method="POST"
                >
                  <div class="woocommerce-billing-fields">
                    <h2>Платіжні реквізити</h2>
                    <div class="woocommerce-billing-fields__field-wrapper">
                      <p class="form-row-first">
                        <input
                          type="text"
                          class="input-text"
                          id="billing_first_name"
                          placeholder="Ваше Имя *"
                          v-model="billingDetails.customer_name"
                          required
                        />
                      </p>
                      <p class="form-row-last">
                        <input
                          type="text"
                          class="input-text"
                          name="billing_last_name"
                          id="billing_last_name"
                          placeholder="Ваша Фамилия *"
                          v-model="billingDetails.customer_surname"
                          required
                        />
                      </p>
                      <!-- <p class="form-row-wide">
                      <input
                        type="text"
                        class="input-text"
                        name="billing_company"
                        id="billing_company"
                        placeholder="Company Name"
                      />
                      </p>-->
                      <p class="form-row-wide">
                        <label for="billing_country">
                          Країна
                          <abbr class="required" title="required">*</abbr>
                        </label>
                        <select
                          v-model="billingDetails.country"
                          required
                          id="billing_country"
                          class="country_select"
                          name="billing_country"
                        >
                          <option
                            :key="index + country"
                            v-for="(country, index) in countries"
                            :value="country"
                          >{{ country }}</option>
                        </select>
                        <span class="select-btn">
                          <i class="zmdi zmdi-caret-down"></i>
                        </span>
                      </p>
                      <p class="form-row-wide">
                        <label for="billing_address_1">
                          Адрес
                          <abbr class="required" title="required">*</abbr>
                        </label>
                        <input
                          v-model="billingDetails.address_one"
                          type="text"
                          class="input-text"
                          name="billing_address_1"
                          id="billing_address_1"
                          placeholder="Вулиця"
                          required
                        />
                      </p>
                      <p class="form-row-wide">
                        <input
                          type="text"
                          class="input-text"
                          name="billing_address_2"
                          id="billing_address_2"
                          v-model="billingDetails.address_two"
                          placeholder="Квартира / Будинок"
                          required
                        />
                      </p>
                      <p class="form-row-wide">
                        <input
                          type="text"
                          class="input-text"
                          name="billing_city"
                          id="billing_city"
                          v-model="billingDetails.city"
                          placeholder="Місто *"
                          required
                        />
                      </p>
                      <!-- <p class="form-row-wide">
                      <input
                        type="text"
                        class="input-text"
                        placeholder="County *"
                        name="billing_state"
                        id="billing_state"
                        v-model="billingDetails.city"
                      />
                      </p>-->
                      <p class="form-row-wide">
                        <input
                          type="text"
                          class="input-text"
                          placeholder="Поштовий індекс"
                          name="billing_postcode"
                          v-model="billingDetails.zip_code"
                          id="billing_postcode"
                          required
                        />
                      </p>
                      <!-- <p class="form-row-wide form-row-wide-1">
                      
                      <select id="billing_postcode" class="postcode_select" name="billing_postcode">
                        <option value="default" selected="selected">Postcode / Zip *</option>
                        <option value="154000">154000</option>
                        <option value="154100">154100</option>
                        <option value="118500">118500</option>
                        <option value="123000">123000</option>
                      </select>
                      <span class="select-btn">
                        <i class="zmdi zmdi-caret-down"></i>
                      </span>
                      </p>-->
                      <p class="form-row-first">
                        <input
                          type="tel"
                          class="input-text"
                          name="billing_phone"
                          id="billing_phone"
                          placeholder="Телефон *"
                          v-model="billingDetails.customer_phone"
                          required
                        />
                      </p>
                      <p class="form-row-last">
                        <input
                          type="email"
                          class="input-text"
                          name="billing_email"
                          id="billing_email"
                          placeholder="Email *"
                          v-model="billingDetails.customer_email"
                          required
                        />
                      </p>
                    </div>
                  </div>
                  <div class="woocommerce-account-fields">
                    <p class="woocommerce-validated">
                      <label class="woocommerce-form__label-for-checkbox">
                        <input
                          class="woocommerce-form__input-checkbox policy-chbx"
                          id="privacy-policy"
                          type="checkbox"
                          name="privacy-policy"
                          checked
                          required
                        />
                        <span>Я згоден із політикою конфіденційності</span>
                      </label>
                    </p>
                    <!-- <div class="create-account">
                    <p id="account_password_field">
                      <label for="account_password" class>
                        Create account password
                        <abbr class="required" title="required">*</abbr>
                      </label>
                      <input
                        type="password"
                        class="input-text"
                        name="account_password"
                        id="account_password"
                        placeholder="Password"
                      />
                    </p>
                    </div>-->
                  </div>
                  <div class="woocommerce-additional-fields">
                    <h2>Додаткова інформація</h2>
                    <div class="woocommerce-additional-fields__field-wrapper">
                      <p class="notes" id="order_comments_field">
                        <label for="order_comments" class>Примітки до замовлення</label>
                        <textarea
                          name="order_comments"
                          class="input-text"
                          id="order_comments"
                          placeholder="Примітка про ваше замовлення, наприклад. спеціальні примітки для доставки."
                          v-model="billingDetails.comment"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="woocommerce-checkout-review-order">
                  <div class="woocommerce-checkout-payment">
                    <div class="place-order">
                      <input
                        type="submit"
                        class="button alt au-btn btn-small active-background active-abr-btn"
                        name="woocommerce_checkout_place_order"
                        id="place_order"
                        value="Оформити замовлення"
                        form="billingDetails"
                      />
                      <!-- <label id="place_order" tabindex="0" for="billingDetails" class="button alt au-btn btn-small"></label> -->
                      <span>
                        <i class="zmdi zmdi-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <div v-if="!cart.productsCount" class="empty-cart">
      <h3 class="empty-cart__text">Ваш кошик порожній. Додайте товари, що сподобалися в кошик</h3>
    </div>
  </div>
</template>

<script>
//Import the data for the cart
import { cartModel, itemModel } from "../../static/cart/cart";
//Library for working with requests
import axios from "axios";

import { log } from "util";

import Inputmask from "inputmask";

import EventBus from "../../EventBus";

// import countries from "/static/address/structures.js"

import { countries } from "../../static/address/structures.js";

export default {
  name: "Checkoutpage",
  data() {
    return {
      coupon: {
        code: ""
      },
      giftcard: {
        code: ""
      },
      cart: {
        items: [],
        bundles: [],
        giftCardCode: "",
        couponCode: ""
      },
      countries: [],
      discount: "",
      billingDetails: {
        customer_name: "",
        customer_surname: "",
        country: "",
        address_one: "",
        address_two: "",
        city: "",
        zip_code: "",
        customer_email: "",
        customer_phone: "",
        comment: ""
      },
      paymentMethods: [],
      shippingMethods: [],
      activePaymentMethod: "",
      activeShippingMethod: "",
      activeShippingCost: 0,
      activeCurrency: {},
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  watch: {
    "cart.items": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();

        this.calculateDiscount();

        //Count products total quantity
        this.countProducts();

        this.saveCart();
      },
      deep: true
    },
    "cart.bundles": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();

        this.calculateDiscount();

        //Count products total quantity
        this.countProducts();

        this.saveCart();
      },
      deep: true
    }
  },
  methods: {
    countProducts() {
      let productsCount = 0;

      this.cart.items.forEach(function(item) {
        productsCount += item.quantity;
      });
      this.cart.bundles.forEach(function(item) {
        productsCount += item.quantity;
      });

      this.cart.productsCount = productsCount;
    },
    countCost(item) {
      let cost = +item.price;
      if (item.versionsCost) {
        cost = +item.price + item.versionsCost;
      }
      return cost;
    },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    shippingChanged(method) {
      this.activeShippingMethod = method;
      this.activeShippingCost = method.costs[this.activeCurrency.code];
      console.log("shipping cost: ", this.activeShippingCost);
      this.calculateDiscount();
    },
    saveCart() {
      //Save a cart to the localStorage
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    shippingCost(cost) {
      let shippingCost = "";
      if (+cost) {
        shippingCost = this.activeCurrency.code + " " + cost;
      }
      return shippingCost;
    },
    calculateDiscount() {
      this.order_items = [];
      this.order_bundles = [];
      this.cart.couponCode = this.coupon.code;
      this.cart.giftCardCode = this.giftcard.code;
      this.order_code = this.activeCurrency.code;
      let note = this.showNotification;
      // this.cart.currency_code = this.activeCurrency.code;

      let requestUrl = this.API.order.calculate;
      let orderItems = this.order_items;
      let orderBundles = this.order_bundles;

      this.cart.items.forEach(function(product) {
        if (product.selected_versions) {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity,
            selected_versions: product.selected_versions
          });
        } else {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity
          });
        }
      });
      if (this.cart.bundles.length) {
        for (let bundle of this.cart.bundles) {
          let bundleObj = {
            bundle_id: bundle.id,
            quantity: bundle.quantity
          };
          orderBundles.push(bundleObj);
        }
      }
      let requestParams = {
        order_items: this.order_items,
        coupon_code: this.cart.couponCode,
        giftcard_code: this.cart.giftCardCode,
        bundles: this.order_bundles,
        currency_code: this.order_code
      };

      axios.post(requestUrl, requestParams).then(
        response => {
          this.discount = response.data.discount;
          console.log("subtotal: ", response.data.subtotal);
          console.log("total: ", response.data.total);
          this.cart.subtotal = response.data.subtotal;
          this.cart.total = response.data.total;
        },
        function() {
          note("error", "Code is not valid!");
        }
      );
    },
    calculatePrices() {
      let cart = this.cart;
      let subtotal = 0;
      let cur = this.activeCurrency.code;
      if (cart.items.length) {
        cart.items.forEach(function(item) {
          let itemTotal = Math.round(item.price * item.quantity * 100) / 100;

          // Add version price

          if (item.versions) {
            let verPrice = 0;
            item.versions.forEach(function(option) {
              verPrice += option[0].versions[option[1]].version_prices[cur];
            });

            // add version cost to item.price

            if (item.promotion !== null && item.promotion !== undefined) {
              let promo = item.promotion;

              if (promo.is_fixed_price && verPrice >= promo.value) {
                verPrice -= promo.value;
              } else {
                let discount = (verPrice * parseInt(promo.value)) / 100;
                verPrice -= discount;
              }
            }

            itemTotal += verPrice * item.quantity;

            item.versionsCost = verPrice;

            // End of add version cost to item.price
          }

          // End of "Add version price'

          item.total = itemTotal;

          subtotal += item.total;
        });
      }

      if (cart.bundles.length) {
        cart.bundles.forEach(function(bundle) {
          subtotal += bundle.price * bundle.quantity;
          bundle.total = bundle.price * bundle.quantity;
        });
      }

      cart.subtotal = subtotal;

      cart.total = subtotal;
    },
    setCart() {
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.setNewCart();
      }
    },
    setNewCart() {
      this.cart = Object.assign({}, cartModel);

      this.cart.items = [];
      this.cart.bundles = [];

      this.cart.currency = this.applicationParams.currency;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },

    listenEvents() {
      //Update product list
      this.EventBus.$on("products-updated", updatedList => {
        this.cart.items = updatedList;
        // this.calculateDiscount();
      });

      this.EventBus.$on("bundles-updated", updatedList => {
        this.cart.bundles = updatedList;
      });
    },

    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    },
    getPaymentMethods() {
      let requestUrl = this.API.paymentMethod.list;
      axios.get(requestUrl).then(response => {
        this.paymentMethods = response.data;
        setTimeout(this.paymentSlideAnimation, 10);
        this.activePaymentMethod = response.data[0];
      });
    },
    getShippingMethods() {
      let requestUrl = this.API.shippingMethod.list;
      axios.get(requestUrl, { params: { locale: "ru" } }).then(response => {
        this.shippingMethods = response.data;
        setTimeout(this.shippingSlideAnimation, 10);
        this.activeShippingMethod = response.data[0];
        this.activeShippingCost = this.activeShippingMethod.costs[
          this.activeCurrency.code
        ];
        // this.activeShippingMethodCost = response.data[0].cost;
      });
    },
    bundledItemsComa(index, length) {
      let coma = "";
      if (index < length - 1) {
        coma = ", ";
      }
      return coma;
    },
    submitForm(event) {
      event.preventDefault();
      let bundleList = [];
      let orderItems = [];

      this.cart.items.forEach(function(product) {
        if (product.selected_versions) {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity,
            selected_versions: product.selected_versions
          });
        } else {
          orderItems.push({
            product_id: product.productId,
            product_quantity: product.quantity
          });
        }
      });

      if (this.cart.bundles.length) {
        for (let bundle of this.cart.bundles) {
          let bundleObj = {
            bundle_id: bundle.id,
            quantity: bundle.quantity
          };
          bundleList.push(bundleObj);
        }
      }

      let requestUrl = this.API.order.create;
      let requestParams = {
        currency_code: this.activeCurrency.code,
        order_items: orderItems,
        bundles: bundleList,
        coupon_code: this.cart.couponCode,
        giftcard_code: this.cart.giftCardCode,
        customer_name: this.billingDetails.customer_name,
        customer_surname: this.billingDetails.customer_surname,
        customer_email: this.billingDetails.customer_email,
        customer_phone: this.billingDetails.customer_phone,
        address_one: this.billingDetails.address_one,
        address_two: this.billingDetails.address_two,
        country: this.billingDetails.country,
        city: this.billingDetails.city,
        zip_code: this.billingDetails.zip_code,
        payment_method_id: this.activePaymentMethod.id,
        shipping_method_id: this.activeShippingMethod.id,
        comment: this.billingDetails.comment
      };

      axios.post(requestUrl, requestParams).then(response => {

        if (response.status === 200) {

            this.setNewCart();
            this.setCurrency();

          if (
              response.data.confirmation_token !== undefined &&
              response.data.confirmation_token !== null
          ) {
              window.location.href =
                  "/kassa?token=" + response.data.confirmation_token;
          }
          else{
              // Check for PayPal
              if(this.activePaymentMethod.id === 3){
                  console.log('paypal');
                  window.location.href = `/paypal?order_id=${response.data.order_id}`
              }
              else {
                  window.location.href = "/thank-you";
              }
              // window.location.href = "/thank-you";
          }
          // this.$swal({
          //   title: "Спасибо за Ваш заказ!",
          //   confirmButtonText: "Продолжить покупки",
          //   showCancelButton: false,
          //   focusConfirm: false,
          //   buttonsStyling: false,
          //   customClass: {
          //     confirmButton: "swal-confirm-custom"
          //   }
          // });
        }
      });
    },
    saveCart() {
      //Save a cart to the localStorage
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    paymentSlideAnimation() {
      $(".payment_box").hide();

      $('[name="payment_method"]').on("click", function() {
        let $value = $(this).attr("value");

        $(".payment_box").slideUp();
        $(".payment_method_" + $value).slideToggle();
      });
    },
    shippingSlideAnimation() {
      $(".shipping_box").hide();

      $('[name="shipping_method"]').on("click", function() {
        let $value = $(this).attr("value");

        $(".shipping_box").slideUp();
        $(".shipping_method_" + $value).slideToggle();
      });
    },
    setCountries() {
      this.countries = countries;
    },
    setDiscountCodes() {
      this.coupon.code = this.cart.couponCode;
      this.giftcard.code = this.cart.giftCardCode;
    }
  },

  mounted() {
    // Set currancy
    this.setCurrency();
    //Set the cart data
    this.setCart();

    // Set coupon and giftcard
    this.setDiscountCodes();

    // Get payment methods
    this.getPaymentMethods();

    // Get shipping methods
    this.getShippingMethods();

    //Listen to the events
    this.listenEvents();

    // Set default image
    this.setDefaultImage();

    // Load countries
    this.setCountries();

    // Input mask

    if (this.cart.productsCount) {
      $(document).ready(function() {
        let tel = document.querySelector(".phone-mask");
        let im = new Inputmask("+999999999999");
        im.mask(tel);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// .cart-discount {
//   margin-top: -20px;
// }
.product-quantity--default-pointer {
  cursor: default;
}

.product-quantity--default-pointer .cart_item_title {
  cursor: pointer;
}

.bundled-products {
  font-size: 12px;
  white-space: normal;
}

.payment-method-title {
  padding-bottom: 0 !important;
  padding-top: 33px;
}

.payment_methods--first {
  margin-bottom: 0 !important;
}

.shipping-cost {
  float: right;
  font-weight: 700;
}

.shipping-price {
  font-size: 16px;
}

.policy-chbx {
  display: block !important;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.empty-cart__text {
  width: 86.4%;
  margin: 0 auto;
  padding: 150px 0;
}
</style>