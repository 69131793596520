var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wishlist-table" }, [
    !_vm.products.length
      ? _c("div", { staticClass: "empty-cart" }, [
          _c("h3", { staticClass: "empty-cart__text" }, [
            _vm._v(
              "Cписок желаний пуст. Добавляйте товары в список желаний, делитесь списками с друзьями и обсуждайте товары вместе. Если у вас уже есть списки желаний, авторизуйтесь"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.products.length
      ? _c(
          "section",
          { staticClass: "shop-cart-section wishlist-section section-box" },
          [
            _c("div", { staticClass: "woocommerce" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "entry-content" }, [
                  _c(
                    "form",
                    {
                      staticClass: "woocommerce-cart-form",
                      attrs: { method: "POST" },
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass:
                            "shop_table shop_table_responsive cart woocommerce-cart-form__contents wishlist_table",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.products, function (product, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    staticClass:
                                      "woocommerce-cart-form__cart-item cart_item",
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "product-remove" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "remove",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "zmdi zmdi-close",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-name",
                                        attrs: { "data-title": "Product" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.buildUrl(
                                                  _vm.applicationParams.locale
                                                ) + product.slug,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: product.images.length
                                                  ? product.images[0].url
                                                  : _vm.defaultImage.url,
                                                alt: product.images.length
                                                  ? product.images[0].alt
                                                  : _vm.defaultImage.alt,
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.buildUrl(
                                                  _vm.applicationParams.locale
                                                ) + product.slug,
                                            },
                                          },
                                          [_vm._v(_vm._s(product.title))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-price",
                                        attrs: { "data-title": "Price" },
                                      },
                                      [
                                        _c("span", { staticClass: "price" }, [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "crossed-out":
                                                  product.prices["RUB"]
                                                    .sale_price,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "woocommerce-Price-currencySymbol",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.activeCurrency
                                                            .code
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.showItemPrice(
                                                          product
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          product.prices["RUB"].sale_price
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "woocommerce-Price-amount amount",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "woocommerce-Price-currencySymbol",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.activeCurrency
                                                              .code
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.showItemPrice(
                                                            product,
                                                            true
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(1, true),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "product-subtotal product-add-to-cart",
                                        attrs: { "data-title": "Add to Cart" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "au-btn btn-small",
                                            attrs: {
                                              href:
                                                _vm.buildUrl(
                                                  _vm.applicationParams.locale
                                                ) + product.slug,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Заказать сейчас\n                      "
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "zmdi zmdi-arrow-right",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "actions",
                                    attrs: { colspan: "6" },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "empty-wishlist au-btn btn-small",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.clearWishList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Очистить список желаний\n                        "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "zmdi zmdi-arrow-right",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(2),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "product-remove" }),
        _vm._v(" "),
        _c("th", { staticClass: "product-name" }, [_vm._v("Товар")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-price" }, [_vm._v("Цена")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-quantity product-stock-status" }),
        _vm._v(" "),
        _c("th", { staticClass: "product-subtotal product-add-to-cart" }, [
          _vm._v("Добавить в список желаний"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "product-quantity product-stock-status",
        attrs: { "data-title": "Stock Status" },
      },
      [_c("div", { staticClass: "quantity" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "continue au-btn-black btn-small",
        attrs: { href: "/categories" },
      },
      [
        _vm._v(
          "\n                      Продолжить покупки\n                      "
        ),
        _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }