<template>
  <div class="site-header-cart">
    <div class="cart-contents">
      <img src="/images/icons/header-icon-2.png" alt="cart" />
      <span v-if="cart.productsCount" class="cartAmount">{{ this.cart.productsCount }}</span>
    </div>
    <div class="widget_shopping_cart">
      <div class="widget_shopping_cart_content">
        <ul class="woocommerce-mini-cart cart_list product_list_widget">
          <li
            :key="index"
            v-for="(item, index) in cart.items"
            class="woocommerce-mini-cart-item mini_cart_item clearfix"
          >
            <a class="product-image" :href="buildUrl(applicationParams.locale) + item.slug">
              <img :src="item.imageUrl ? item.imageUrl : defaultImage.url" :alt="item.title" />
            </a>
            <a
              class="product-title"
              :href="buildUrl(applicationParams.locale) + item.slug"
            >{{ item.title }}</a>
            <span class="quantity">
              {{ item.quantity }} ×
              <span class="woocommerce-Price-amount amount">
                <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                {{ countCost(item) }}
              </span>
            </span>
            <a @click.prevent="removeItem(index)" href="#" class="remove">
              <span class="lnr lnr-cross"></span>
            </a>
          </li>
          <li
            :key="index + 'bundle'"
            v-show="cart.bundles"
            v-for="(bundle, index) in cart.bundles"
            class="woocommerce-mini-cart-item mini_cart_item clearfix"
          >
            <a class="product-image" @click.prevent href="#">
              <img
                :src="bundle.items[0].product.images[0].url ? bundle.items[0].product.images[0].url : defaultImage.url"
                :alt="bundle.items[0].product.images[0].alt ? bundle.items[0].product.images[0].alt : defaultImage.alt"
              />
            </a>
            <a class="product-title" @click.prevent href="#">{{ bundle.title }}</a>
            <span class="quantity">
              {{ bundle.quantity }} ×
              <span class="woocommerce-Price-amount amount">
                <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                {{ bundle.total }}
              </span>
            </span>
            <a @click.prevent="removeBundle(index)" href="#" class="remove">
              <span class="lnr lnr-cross"></span>
            </a>
          </li>
        </ul>
        <p v-if="cart.items.length" class="woocommerce-mini-cart__total total">
          <span>Subtotal:</span>
          <span class="woocommerce-Price-amount amount">
            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
            {{ cart.subtotal }}
          </span>
        </p>
        <p class="woocommerce-mini-cart__buttons buttons">
          <a
            :href="applicationParams.locale === 'en' ? '/en/cart' : '/cart'"
            class="button wc-forward au-btn btn-small"
          >Перейти до кошику</a>
          <a
            :href="applicationParams.locale === 'en' ? '/en/checkout' : '/checkout'"
            class="button checkout wc-forward au-btn au-btn-black btn-small"
          >Оформити замовлення</a>
        </p>
      </div>
    </div>
  </div>
</template>


<script>
//Import the data for the cart
import { cartModel, itemModel } from "../../static/cart/cart";

//Event bus
import EventBus from "../../EventBus";
import { log } from "util";

export default {
  name: "DropDownCart",
  data() {
    return {
      cart: {
        items: [],
        bundles: []
      },
      activeCurrency: {},
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  watch: {
    "cart.items": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();

        //Count products total quantity
        this.countProducts();

        //Save an updated cart to localStorage
        this.saveCart();

        this.sendUpdateToHeader();
      },
      deep: true
    },
    "cart.bundles": {
      handler(val) {
        //Calculate prices
        this.calculatePrices();

        //Count products total quantity
        this.countProducts();

        //Save an updated cart to localStorage
        this.saveCart();

        this.sendUpdateToHeader();
      },
      deep: true
    }
  },

  methods: {
    countCost(item) {
      let cost = +item.price;
      if (item.versionsCost) {
        cost = +item.price + item.versionsCost;
      }
      return cost;
    },
    sendUpdateToHeader() {
      this.EventBus.$emit("headerCartUpdated", this.cart);
    },

    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    setCart() {
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.setNewCart();
      }
    },
    setNewCart() {
      this.cart = Object.assign({}, cartModel);

      this.cart.items = [];

      this.cart.bundles = [];

      this.cart.currency = this.applicationParams.currency;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    createNewItem(product) {
      let newItem = Object.assign({}, itemModel);

      newItem.productId = product.id;
      newItem.title = product.title;
      newItem.slug = product.slug;
      newItem.price = product.price;

      if (product.prices) {
        newItem.price = product.prices[this.activeCurrency.code].price;
      }

      if (product.prices[this.activeCurrency.code].sale_price) {
        newItem.price = product.prices[this.activeCurrency.code].sale_price;
      }

      if (product.selectedVersions || product.version_items) {
        if (product.selectedVersions) {
          let selVerArr = Object.keys(product.selectedVersions).map(
            i => product.selectedVersions[i]
          );

          // newItem.versions = product.selectedVersions;

          newItem.versions = selVerArr;
        } else {
          let selVerArr = [];
          for (let item of product.version_items) {
            let option = [item, 0];
            selVerArr.push(option);
          }
          newItem.versions = selVerArr;
        }

        newItem.selected_versions = [];

        newItem.versionsCost = 0;

        for (let option of newItem.versions) {
          newItem.versionsCost +=
            option[0].versions[option[1]].version_prices[
              this.activeCurrency.code
            ];
          let verId = 0;

          verId = option[0].versions[option[1]].id;

          newItem.selected_versions.push(verId);
        }

        if(product.promotion !== null && product.promotion !== undefined){

            newItem.promotion = product.promotion;

        }

        // newItem.price += newItem.versionsCost;
      }

      if (product.images.length) {
        newItem.imageUrl = product.images[0].url;
      }

      this.cart.items.push(newItem);
    },

    addItem(product) {
      let existingItem = this.cart.items.find(function(item) {
        return item.productId === product.id;
      });

      if (existingItem === undefined) {
        this.createNewItem(product);
      } else {
        if (product.selectedVersions || product.version_items) {
          if (product.selectedVersions) {
            let selVerArr = Object.keys(product.selectedVersions).map(
              i => product.selectedVersions[i]
            );

            product.selectedVersions = selVerArr;
          } else {
            let selVerArr = [];
            for (let item of product.version_items) {
              let option = [item, 0];
              selVerArr.push(option);
            }
            product.selectedVersions = selVerArr;
          }

          let similarProducts = [];
          let index = 0;
          for (let item of this.cart.items) {
            if (item.productId === product.id) {
              similarProducts.push(index);
            }
            index++;
          }

          // Check if new item has different versions

          let createNew = true;

          let newProdVersions = "";

          for (let option of product.selectedVersions) {
            newProdVersions += option[0].id;
            newProdVersions += option[1];
          }

          for (let i of similarProducts) {
            let existingProdVersions = "";

            for (let option of this.cart.items[i].versions) {
              existingProdVersions += option[0].id;
              existingProdVersions += option[1];
            }

            if (newProdVersions === existingProdVersions) {
              this.incrementQuantity(i);
              createNew = false;
            } else {
            }
          }
          if (createNew) {
            this.createNewItem(product);
          }

          // End of "check if new item has different versions"
        } else {
          let itemIndex = this.cart.items.indexOf(existingItem);

          this.incrementQuantity(itemIndex);
        }
      }

      //Show success notification
      this.showNotification("success", "Товар был добавлен в корзину.");
    },
    noSelectedVersions(product, uniqueItem) {
      if (uniqueItem) {
      }
    },
    addBundle(product) {
      if (!this.cart.bundles) {
        this.cart.bundles = [];
      }
      let existingItem = this.cart.bundles.find(function(bundle) {
        return bundle.id === product.id;
      });

      if (existingItem === undefined) {
        let newBundle = Object.assign({}, itemModel);

        newBundle.id = product.id;
        newBundle.title = product.title;
        newBundle.price = product.prices[this.activeCurrency.code];
        newBundle.total = product.prices[this.activeCurrency.code];

        newBundle.items = product.items;

        this.cart.bundles.push(newBundle);
      } else {
        let itemIndex = this.cart.bundles.indexOf(existingItem);
        this.incrementBundleQuantity(itemIndex);
      }
      //Show success notification
      this.showNotification("success", "Bundle has been added to cart.");
    },
    removeItem(index) {
      this.cart.items.splice(index, 1);
      this.sendUpdatedProducts();
    },
    removeBundle(index) {
      this.cart.bundles.splice(index, 1);
      this.sendUpdatedBundles();
    },
    calculatePrices() {
      let cart = this.cart;
      let subtotal = 0;
      let cur = this.activeCurrency.code;
      if (cart.items.length) {
        cart.items.forEach(function(item) {
          let itemTotal = Math.round(item.price * item.quantity * 100) / 100;

          // Add version price

          if (item.versions) {
            let verPrice = 0;
            item.versions.forEach(function(option) {
              verPrice += option[0].versions[option[1]].version_prices[cur];
            });

            // add version cost to item.price

              if(item.promotion !== null && item.promotion !== undefined){

                  let promo = item.promotion;

                  if(promo.is_fixed_price && verPrice >= promo.value){

                      verPrice -= promo.value;

                  }
                  else{

                      let discount = (verPrice * parseInt(promo.value)) / 100;
                      verPrice -= discount;

                  }

              }

              itemTotal += verPrice * item.quantity;

            item.versionsCost = verPrice;

            // End of add version cost to item.price
          }

          // End of "Add version price'

          item.total = itemTotal;

          subtotal += item.total;
        });
      }

      if (cart.bundles.length) {
        cart.bundles.forEach(function(bundle) {
          subtotal += bundle.price * bundle.quantity;
          bundle.total = bundle.price * bundle.quantity;
        });
      }

      cart.subtotal = subtotal;

      cart.total = subtotal;
    },
    incrementQuantity(index) {
      let item = this.cart.items[index];

      item.quantity += 1;

      //Update checkout products list
      this.sendUpdatedProducts();
    },
    incrementBundleQuantity(index) {
      let item = this.cart.bundles[index];

      item.quantity += 1;

      //Update checkout products list
      this.sendUpdatedProducts();
    },
    decrementQuantity(index) {
      let item = this.cart.items[index];

      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        this.removeItem(index);
      }

      //Update checkout products list
      this.sendUpdatedProducts();
    },
    countProducts() {
      let productsCount = 0;

      this.cart.items.forEach(function(item) {
        productsCount += item.quantity;
      });
      this.cart.bundles.forEach(function(item) {
        productsCount += item.quantity;
      });

      this.cart.productsCount = productsCount;
    },
    saveCart() {
      //Save a cart to the localStorage
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    listenEvents() {
      //Add quantity from product page
      this.EventBus.$on("product-page-quantity", productObj => {
        let existingItem = this.cart.items.find(function(item) {
          return item.productId === productObj.prod.id;
        });

        let itemIndex = this.cart.items.indexOf(existingItem);
        let times = productObj.quantity;

        if (!productObj.versions) {
          for (let i = 0; i < times; i++) {
            this.incrementQuantity(itemIndex);
          }
        } else {
          let verStr = "";
          for (let version of productObj.versions) {
            verStr += version[0].versions[version[1]].id;
            verStr += version[1];
          }

          let similarProducts = [];
          let indexItem = 0;
          for (let item of this.cart.items) {
            if (item.productId === productObj.prod.id) {
              similarProducts.push(indexItem);
            }
            indexItem++;
          }

          for (let i of similarProducts) {
            let similarProdVerStr = "";
            for (let ver of this.cart.items[i].versions) {
              similarProdVerStr += ver[0].versions[ver[1]].id;
              similarProdVerStr += ver[1];
            }

            if (similarProdVerStr === verStr) {
              for (let x = 0; x < times; x++) {
                this.incrementQuantity(i);
              }
              break;
            }
          }
        }
      });
      //Receive data form an add to cart button
      this.EventBus.$on("add-to-cart", product => {
        this.addItem(product);
      });

      this.EventBus.$on("add-bundle-to-cart", product => {
        this.addBundle(product);
      });

      //Receive a data from a checkout page
      this.EventBus.$on("product-quantity-increment", product => {
        this.incrementQuantity(product);
      });

      //Update product list
      this.EventBus.$on("products-updated", updatedList => {
        this.cart.items = updatedList;
      });

      this.EventBus.$on("bundles-updated", updatedList => {
        this.cart.bundles = updatedList;
      });

      this.EventBus.$on("product-quantity-decrement", product => {
        this.decrementQuantity(product);
      });

      this.EventBus.$on("order-placed", result => {
        this.setNewCart();
      });

      this.EventBus.$on("cart-recalculated", cart => {
        this.cart = Object.assign({}, cart);
      });
    },
    sendUpdatedProducts() {
      //Update a checkout product list
      this.EventBus.$emit("products-updated", this.cart.items);
    },
    sendUpdatedBundles() {
      //Update a checkout product list
      this.EventBus.$emit("bundles-updated", this.cart.bundles);
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },
  mounted() {
    // Set currancy
    this.setCurrency();
    //Set the cart data
    this.setCart();

    //Listen to the events
    this.listenEvents();

    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="scss" scoped>
.widget_shopping_cart {
  max-height: calc(100vh - 88px);
  overflow: auto;
}
</style>