import { render, staticRenderFns } from "./Featured.vue?vue&type=template&id=7958ae01&scoped=true"
import script from "./Featured.vue?vue&type=script&lang=js"
export * from "./Featured.vue?vue&type=script&lang=js"
import style0 from "./Featured.vue?vue&type=style&index=0&id=7958ae01&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7958ae01",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/fotofonu/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7958ae01')) {
      api.createRecord('7958ae01', component.options)
    } else {
      api.reload('7958ae01', component.options)
    }
    module.hot.accept("./Featured.vue?vue&type=template&id=7958ae01&scoped=true", function () {
      api.rerender('7958ae01', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/user/components/product/Featured.vue"
export default component.exports