var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "product-page__main-component shop-single-v1-section shop-single-v2-section section-box featured-hp-1 featured-hp-4",
    },
    [
      _c("div", { staticClass: "woocommerce" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "content-area" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 product-page__galery",
                    staticStyle: { overflow: "hidden" },
                  },
                  [
                    _c("div", { staticClass: "woocommerce-product-gallery" }, [
                      _c(
                        "div",
                        { staticClass: "owl-carousel product-slider" },
                        [
                          !_vm.product.images.length
                            ? _c("div", { staticClass: "item" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.defaultImage.url,
                                    alt: _vm.defaultImage.alt,
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.product.images, function (image, index) {
                            return _c(
                              "div",
                              { key: "image" + index, staticClass: "item" },
                              [
                                _c("img", {
                                  attrs: { src: image.url, alt: image.alt },
                                }),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 height-680",
                  },
                  [
                    _c("div", { staticClass: "summary entry-summary" }, [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "abr-product-title product_title entry-title",
                        },
                        [_vm._v(_vm._s(_vm.product.title))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "woocommerce-product-rating" }, [
                        _c(
                          "div",
                          { staticClass: "star-rating" },
                          [
                            _vm._l(_vm.rating, function (star, index) {
                              return _c("i", {
                                key: "star" + index,
                                staticClass: "zmdi zmdi-star",
                              })
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.emptyStars, function (star, index) {
                              return _c("i", {
                                key: "emptyStar" + index,
                                staticClass: "zmdi zmdi-star-outline",
                              })
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "woocommerce-review-link",
                            attrs: { href: "#" },
                          },
                          [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.product.reviews_count) +
                                " отзывы покупателей)"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "price active-color" }, [
                        _c("ins", [
                          _c(
                            "span",
                            {
                              staticClass: "woocommerce-Price-amount amount",
                              class: {
                                "crossed-out":
                                  _vm.product.prices["RUB"].sale_price,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-currencySymbol",
                                },
                                [_vm._v(_vm._s(_vm.activeCurrency.code))]
                              ),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.itemPrice) +
                                  "\n                  "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.product.prices["RUB"].sale_price
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-amount amount",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-currencySymbol",
                                    },
                                    [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                  ),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.showItemPrice()) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "woocommerce-product-details__short-description",
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.product.short_description)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "form",
                        { staticClass: "cart", attrs: { method: "post" } },
                        [
                          _c("div", { staticClass: "quantity" }, [
                            _c("input", {
                              staticClass: "nput-text qty text",
                              attrs: {
                                type: "number",
                                name: "quantity",
                                id: "quantity",
                                min: "1",
                              },
                              domProps: { value: _vm.item.quantity },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "modify-qty plus",
                                on: {
                                  click: function ($event) {
                                    return _vm.incrementQuantity()
                                  },
                                },
                              },
                              [_vm._v("+")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "modify-qty minus",
                                on: {
                                  click: function ($event) {
                                    return _vm.decrementQuantity()
                                  },
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "single_add_to_cart_button button alt au-btn btn-small active-background active-abr-btn",
                              attrs: { type: "submit", name: "add-to-cart" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addToCart(_vm.product)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  Добавить в корзину\n                  "
                              ),
                              _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.versions !== undefined &&
                          _vm.product.selectedVersions !== undefined &&
                          _vm.versionValues !== undefined
                            ? _c(
                                "div",
                                { staticClass: "versions abr-versions__style" },
                                _vm._l(
                                  _vm.product.version_items,
                                  function (option, index) {
                                    return _c(
                                      "div",
                                      { key: index + option.name },
                                      [
                                        option.id === 5 || option.id === 15
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "versions__wrap abr_double",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "versions__title",
                                                  },
                                                  [_vm._v(_vm._s(option.name))]
                                                ),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  key: `${option.name}-${
                                                    index + option.id
                                                  }`,
                                                  attrs: {
                                                    label: "id",
                                                    options: option.versions,
                                                    clearable: false,
                                                    searchable: false,
                                                  },
                                                  on: {
                                                    input: (val) => {
                                                      _vm.selectVersion(
                                                        val,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selected-option",
                                                        fn: function ({
                                                          option_value,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "abr-versions_row",
                                                              },
                                                              [
                                                                option_value.image !==
                                                                null
                                                                  ? _c("img", {
                                                                      attrs: {
                                                                        width:
                                                                          "50",
                                                                        height:
                                                                          "50",
                                                                        src: option_value
                                                                          .image
                                                                          .url,
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option_value.value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "option",
                                                        fn: function ({
                                                          option_value,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "abr-versions_row",
                                                              },
                                                              [
                                                                option_value.image !==
                                                                null
                                                                  ? _c("img", {
                                                                      attrs: {
                                                                        width:
                                                                          "50",
                                                                        height:
                                                                          "50",
                                                                        src: option_value
                                                                          .image
                                                                          .url,
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option_value.value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.versionValues[index],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.versionValues,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "versionValues[index]",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : option.id !== 14
                                          ? _c(
                                              "div",
                                              { staticClass: "versions__wrap" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "versions__title",
                                                  },
                                                  [_vm._v(_vm._s(option.name))]
                                                ),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  key: `${option.name}-${
                                                    index + option.id
                                                  }`,
                                                  attrs: {
                                                    label: "id",
                                                    options: option.versions,
                                                    clearable: false,
                                                    searchable: false,
                                                  },
                                                  on: {
                                                    input: (val) => {
                                                      _vm.selectVersion(
                                                        val,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selected-option",
                                                        fn: function ({
                                                          option_value,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "abr-versions_row",
                                                              },
                                                              [
                                                                option_value.image !==
                                                                null
                                                                  ? _c("img", {
                                                                      attrs: {
                                                                        width:
                                                                          "50",
                                                                        height:
                                                                          "50",
                                                                        src: option_value
                                                                          .image
                                                                          .url,
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option_value.value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "option",
                                                        fn: function ({
                                                          option_value,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "abr-versions_row",
                                                              },
                                                              [
                                                                option_value.image !==
                                                                null
                                                                  ? _c("img", {
                                                                      attrs: {
                                                                        width:
                                                                          "50",
                                                                        height:
                                                                          "50",
                                                                        src: option_value
                                                                          .image
                                                                          .url,
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option_value.value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.versionValues[index],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.versionValues,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "versionValues[index]",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("div"),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "product_meta" }, [
                        _c(
                          "span",
                          { staticClass: "posted_in" },
                          [
                            _vm._v(
                              "\n                  Категории:\n                  "
                            ),
                            _vm._l(_vm.categories, function (cat, index) {
                              return _c(
                                "a",
                                {
                                  key: index + cat.title,
                                  attrs: { href: `/categories/` + cat.slug },
                                },
                                [_vm._v(_vm._s(_vm.categoryTitle(cat, index)))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-share" }, [
                        _c("span", [_vm._v("Поделиться:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "https://www.facebook.com/sharer/sharer.php?u=" +
                                _vm.currentUrl,
                            },
                          },
                          [_c("i", { staticClass: "zmdi zmdi-facebook" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.applicationParams.instagram_url,
                            },
                          },
                          [_c("i", { staticClass: "zmdi zmdi-instagram" })]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "woocommerce-tabs" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-tabs wc-tabs",
                        attrs: { id: "myTab", role: "tablist" },
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "nav-item reviews_tab",
                            attrs: {
                              id: "tab-title-reviews",
                              role: "tab",
                              "aria-controls": "tab-reviews",
                              "aria-selected": "false",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#tab-reviews",
                                  "data-toggle": "tab",
                                },
                              },
                              [
                                _vm._v(
                                  "Отзывы(" +
                                    _vm._s(_vm.product.reviews_count) +
                                    ")"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-content",
                        attrs: { id: "myTabContent" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "woocommerce-Tabs-panel tab-pane fade show active",
                            attrs: {
                              id: "tab-description",
                              role: "tabpanel",
                              "aria-labelledby": "tab-title-description",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row product-description-images",
                                class: {
                                  "product-page__description": !(
                                    _vm.product.images.length > 1
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12",
                                  },
                                  [
                                    _c("div", [
                                      _c("img", {
                                        staticClass: "image-object",
                                        staticStyle: {
                                          width: "100%",
                                          "object-fit": "cover",
                                          "object-position": "center",
                                        },
                                        attrs: {
                                          src: _vm.product.images.length
                                            ? _vm.product.images[0].url
                                            : _vm.defaultImage.url,
                                          alt: _vm.product.images.length
                                            ? _vm.product.images[0].alt
                                            : _vm.defaultImage.alt,
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.product.images.length > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12",
                                      },
                                      [
                                        _c("figure", [
                                          _c("img", {
                                            staticClass: "image-object",
                                            staticStyle: {
                                              width: "100%",
                                              "object-fit": "cover",
                                              "object-position": "center",
                                            },
                                            attrs: {
                                              src: _vm.product.images[1].url,
                                              alt: _vm.product.images[1].alt,
                                            },
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "woocommerce-Tabs-panel tab-pane",
                            attrs: {
                              id: "tab-reviews",
                              role: "tabpanel",
                              "aria-labelledby": "tab-title-reviews",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "woocommerce-Reviews",
                                attrs: { id: "reviews" },
                              },
                              [
                                _c("h2", [
                                  _vm._v(
                                    _vm._s(_vm.product.reviews_count) +
                                      " обзор для " +
                                      _vm._s(_vm.product.title)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { attrs: { id: "comments" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "comment-list" },
                                    _vm._l(
                                      _vm.product.reviews,
                                      function (comment, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: "comment" + index,
                                            staticClass: "comment-item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "comment-content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "userPic" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "userPic__init",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.userInitials(
                                                              comment.user.name
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "comment-body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "comment-author",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "author",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                comment.user
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "star-rating",
                                                          },
                                                          [
                                                            _vm._l(
                                                              comment.rating,
                                                              function (
                                                                star,
                                                                index
                                                              ) {
                                                                return _c("i", {
                                                                  key:
                                                                    "rating" +
                                                                    index,
                                                                  staticClass:
                                                                    "zmdi zmdi-star",
                                                                })
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              5 -
                                                                comment.rating,
                                                              function (
                                                                star,
                                                                index
                                                              ) {
                                                                return _c("i", {
                                                                  key:
                                                                    "emptyRating" +
                                                                    index,
                                                                  staticClass:
                                                                    "zmdi zmdi-star-outline",
                                                                })
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "comment-time",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.dateFormat(
                                                              comment.updated_at
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(comment.content)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { attrs: { id: "review_form_wrapper" } },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { id: "review_form" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "comment-respond",
                                            attrs: { id: "respond" },
                                          },
                                          [
                                            _vm.applicationParams.user
                                              ? _c(
                                                  "form",
                                                  {
                                                    staticClass:
                                                      "comment-form common-form js-contact-form",
                                                    attrs: {
                                                      id: "commentform",
                                                      action:
                                                        "includes/contact-form.php",
                                                      method: "POST",
                                                    },
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        return _vm.submitReview(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._m(1),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "comment-form-rating",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Рейтинг"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "stars",
                                                          },
                                                          [
                                                            _c("input", {
                                                              staticClass:
                                                                "stars__checkbox",
                                                              attrs: {
                                                                type: "checkbox",
                                                                required: "",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  _vm.userRating,
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.userRating,
                                                              function (
                                                                star,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "a",
                                                                  {
                                                                    key:
                                                                      "userStarPos" +
                                                                      index,
                                                                    staticClass:
                                                                      "star-1",
                                                                    attrs: {
                                                                      id:
                                                                        index +
                                                                        1,
                                                                      href: "#",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.rateItem(
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "zmdi zmdi-star",
                                                                    }),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              5 -
                                                                _vm.userRating,
                                                              function (
                                                                emptyStar,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "a",
                                                                  {
                                                                    key:
                                                                      "userStarNeg" +
                                                                      index,
                                                                    staticClass:
                                                                      "star-1",
                                                                    attrs: {
                                                                      id:
                                                                        _vm.userRating +
                                                                        (index +
                                                                          1),
                                                                      href: "#",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.rateItem(
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "zmdi zmdi-star-outline",
                                                                    }),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "comment-form-comment",
                                                      },
                                                      [
                                                        _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.reviewContent,
                                                              expression:
                                                                "reviewContent",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id: "comment",
                                                            name: "comment",
                                                            required: "",
                                                            placeholder:
                                                              "Напишите ваш отзыв...",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.reviewContent,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.reviewContent =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._m(2),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "bundles" }, [
                _c(
                  "div",
                  { staticClass: "bundles__container owl-carousel owl-theme" },
                  _vm._l(_vm.product.all_bundles, function (bundle, index) {
                    return _c(
                      "div",
                      {
                        key: index + "item",
                        staticClass: "bundles__item-container",
                      },
                      [
                        _c("div", { staticClass: "break" }),
                        _vm._v(" "),
                        _c("h2", { staticClass: "special-heading" }, [
                          _vm._v(_vm._s(bundle.title)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "bundles__total-container" }, [
                          _c("h4", { staticClass: "bundles__tottal" }, [
                            _vm._v(
                              "Total: " +
                                _vm._s(
                                  _vm.getProductPrice(bundle) +
                                    " " +
                                    _vm.activeCurrency.code
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "swal-cancel-custom bundles__btn-buy",
                              on: {
                                click: function ($event) {
                                  return _vm.addBundleToCart(bundle)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  Купить\n                  "
                              ),
                              _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(bundle.items, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index + "item-box",
                              staticClass: "bundles__item-box",
                            },
                            [
                              item.discount_percentage
                                ? _c(
                                    "div",
                                    { staticClass: "bundles__discount-flag" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "-" + item.discount_percentage + "%"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.buildUrl(
                                        _vm.applicationParams.locale
                                      ) + item.product.slug,
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "bundles__img",
                                    attrs: {
                                      src: item.product.images
                                        ? item.product.images[0].url
                                        : _vm.defaultImage.url,
                                      alt: item.product.images
                                        ? item.product.images[0].alt
                                        : _vm.defaultImage.alt,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "bundles__text" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.buildUrl(
                                          _vm.applicationParams.locale
                                        ) + item.product.slug,
                                    },
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "bundles__title" },
                                      [_vm._v(_vm._s(item.product.title))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "bundles__price",
                                    class: {
                                      "crossed-out": _vm.checkIfSale(item),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showBundlePrice(item) +
                                          " " +
                                          _vm.activeCurrency.code
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.checkIfSale(item)
                                  ? _c(
                                      "span",
                                      { staticClass: "bundles__price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showBundlePrice(item, true) +
                                              " " +
                                              _vm.activeCurrency.code
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.loaded
                ? _c("productrow", {
                    attrs: {
                      title: "Related",
                      "product-id": _vm.product.id,
                      "load-related": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loaded
                ? _c("productrow", { attrs: { title: "Bestseller" } })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      {
        staticClass: "nav-item description_tab",
        attrs: {
          id: "tab-title-description",
          role: "tab",
          "aria-controls": "tab-description",
          "aria-selected": "true",
        },
      },
      [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: { href: "#tab-description", "data-toggle": "tab" },
          },
          [_vm._v("Описание")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "comment-notes" }, [
      _c("span", [_vm._v("Добавить отзыв")]),
      _vm._v(" "),
      _c("span", { attrs: { id: "email-notes" } }, [
        _vm._v(
          "\n                                Ваш электронный адрес не будет опубликован. необходимые поля отмечены\n                                "
        ),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-submit" }, [
      _c("input", {
        staticClass: "submit au-btn btn-small",
        attrs: {
          name: "submit",
          type: "submit",
          id: "submit",
          value: "Submit",
        },
      }),
      _vm._v(" "),
      _c("span", [_c("i", { staticClass: "zmdi zmdi-arrow-right" })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }