<template>
  <footer class="footer-section section-box">
    <div class="footer-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="footer-items">
              <div class="logo">
                <a href="https://fotofonu.com">
                  <img src="/images/header/fotofonu-logo-white.png" alt="PonOmarys" />
                </a>
              </div>
              <p class="font-13">Магазин декору, який допомагає вашій творчості</p>
              <div class="socials">
                <a :href="applicationParams.fb_url">
                  <i class="zmdi zmdi-facebook"></i>
                </a>
                <a :href="applicationParams.instagram_url">
                  <i class="zmdi zmdi-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="footer-items footer-items-1">
              <h4>Контакт</h4>
              <div class="contact">
                <i class="zmdi zmdi-map"></i>
                <span class="font-13">
                  Київ,
                  <br />вул. Зарічна 4 к 1,
                  <br />02132
                </span>
              </div>
              <div class="contact">
                <i class="zmdi zmdi-phone"></i>
                <span>
                  <a href="tel:380731515125" class="font-13">+38 073 1515125</a>
                </span>
              </div>
              <div class="contact">
                <i class="zmdi zmdi-email"></i>
                <span class="font-13">fotofonu@gmail.com</span>
              </div>
              <div class="contact">
                <i class="zmdi zmdi-globe"></i>
                <span class="font-13">www.fotofonu.com.ua</span>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="footer-items footer-items-2">
              <h4>Інформація</h4>
              <div class="profile">
                <i class="zmdi zmdi-account"></i>
                <span>
                  <a href="my-account.html" class="font-13">Особистий кабінет</a>
                </span>
              </div>
              <div class="profile">
                <i class="zmdi zmdi-shopping-cart"></i>
                <span>
                  <a href="check-out.html" class="font-13">Кошик</a>
                </span>
              </div>
              <!-- <div class="profile">
								<i class="zmdi zmdi-eye"></i>
								<span><a href="order-tracking.html">Order Tracking</a></span>
              </div>-->
              <!-- <div class="profile">
								<i class="zmdi zmdi-pin-help"></i>
								<span><a href="#">Help & Suppport</a></span>
              </div>-->
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="footer-items">
              <h4>Newsletter</h4>
              <p class="font-13">Підпишіться та дізнавайтесь першими про наші акції, розпродажі, конкурси, знижки.</p>
              <div class="email">
                <div @click="sendEmailAddress" class="send">
                  <i class="zmdi zmdi-mail-send"></i>
                </div>
                <input
                  type="email"
                  v-model="userEmail"
                  required
                  pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                  name="email"
                  placeholder="Ваш e-mail..."
                />
              </div>
              <span class="font-13">@ 2024 Fotofonu / Ponomarys</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "CommonFooter",
  data() {
    return {
      userEmail: ""
    };
  },
  methods: {
    sendEmailAddress() {
      let mailFormat = /[^@]+@[^@]+.[a-zA-Z]{2,6}/;
      if (this.userEmail.match(mailFormat)) {
        let requestUrl = this.API.newsletterSubscription.create;

        let requestParams = {
          email: this.userEmail
        };
        this.userEmail = "";
        axios.post(requestUrl, requestParams).then(response => {
          this.showNotification("success", "Subscribed!");
        });
      } else {
        this.showNotification("error", "Please enter a valid email address");
      }
    }
  },
  computed: {
    currentYear() {
      let date = new Date();

      return date.getFullYear();
    }
  }
};
</script>