var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-3 col-md-3 col-sm-12 col-xs-12" }, [
      _c("div", { staticClass: "profile-sidebar" }, [
        _c("div", { staticClass: "profile-sidebar-list" }, [
          _c("div", { staticClass: "profile-sidebar-list-item" }, [
            _c(
              "div",
              { staticClass: "sidebar-list-parent profile-list-active" },
              [
                _c("a", { attrs: { href: "/profile/edit" } }, [
                  _c("span", { staticClass: "parent-title" }, [
                    _vm._v("Personal Information"),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-sidebar-list-item" }, [
            _c("div", { staticClass: "sidebar-list-parent" }, [
              _c("a", { attrs: { href: "/profile/subscription" } }, [
                _c("span", { staticClass: "parent-title" }, [
                  _vm._v("Newsletter"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-sidebar-list-item" }, [
            _c("div", { staticClass: "sidebar-list-parent" }, [
              _c("a", { attrs: { href: "/profile/orders" } }, [
                _c("span", { staticClass: "parent-title" }, [
                  _vm._v("Order History"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-sidebar-list-item" }, [
            _c("div", { staticClass: "sidebar-list-parent" }, [
              _c("a", { attrs: { href: "/profile/shipping" } }, [
                _c("span", { staticClass: "parent-title" }, [
                  _vm._v("Shipping Address"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }