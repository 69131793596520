<template>
  <section class="my-account-section section-box">
    <div class="woocommerce">
      <div class="container">
        <div class="content-area">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="entry-content">
                <h2 class="special-heading">Login account</h2>
                <form
                  action="/login"
                  name="login"
                  class="woocommerce-form-login js-contact-form"
                  method="POST"
                >
                  <p class="woocommerce-form-row">
                    <input
                      type="text"
                      class="woocommerce-Input input-text"
                      @focus="login.invalidEmail = false"
                      v-bind:class="{ 'invalid-pass': login.invalidEmail }"
                      name="email"
                      id="username"
                      v-model="login.email"
                      value
                      placeholder="Email address *"
                    />
                  </p>
                  <p class="woocommerce-form-row">
                    <input
                      class="woocommerce-Input input-text"
                      type="password"
                      @focus="login.invalidPassword = false"
                      v-bind:class="{ 'invalid-pass': login.invalidPassword }"
                      v-model="login.password"
                      name="password"
                      id="password"
                      placeholder="Password *"
                    />
                  </p>
                  <p class="form-button">
                    <label>
                      <input
                        type="submit"
                        @click.prevent="validateForm('login')"
                        class="woocommerce-Button au-btn btn-small"
                        name="login"
                        value="Login"
                      />
                      <span class="arrow-right">
                        <i class="zmdi zmdi-arrow-right"></i>
                      </span>
                    </label>
                    <!-- <label class="woocommerce-form__label">
                      <input
                        class="woocommerce-form__input"
                        name="rememberme"
                        type="checkbox"
                        id="rememberme"
                        value="forever"
                      />
                      <span>Remember me</span>
                    </label> -->
                  </p>
                  <p class="woocommerce-LostPassword">
                    <a v-if="login.invalidEmail" href="#">Please enter a valid email address</a>
                  </p>
                  <p class="woocommerce-LostPassword">
                    <a v-if="login.invalidPassword" href="lost-password.html">Lost your password?</a>
                  </p>
                </form>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="novas-form-signup">
                <h2 class="special-heading">Register</h2>
                <form
                  class="woocommerce-form-register js-contact-form"
                  action="/register"
                  name="register"
                  method="post"
                >

                <p class="woocommerce-form-row">
                    <input
                      type="text"
                      @focus="register.invalidName = false"
                      v-bind:class="{ 'invalid-pass': register.invalidName }"
                      class="woocommerce-Input input-text"
                      v-model="register.name"
                      name="name"
                      id="register_username"
                      value
                      placeholder="Username *"
                    />
                  </p>
                
                  <p class="woocommerce-form-row">
                    <input
                      type="text"
                      @focus="register.invalidEmail = false"
                      v-bind:class="{ 'invalid-pass': register.invalidEmail }"
                      class="woocommerce-Input input-text"
                      v-model="register.email"
                      name="email"
                      id="register_email"
                      value
                      placeholder="Email address *"
                    />
                  </p>
                  <p class="woocommerce-form-row">
                    <input
                      class="woocommerce-Input input-text"
                      type="password"
                      @focus="register.invalidPassword = false"
                      v-bind:class="{ 'invalid-pass': register.invalidPassword }"
                      v-model="register.password"
                      name="password"
                      id="register_password"
                      placeholder="Password *"
                    />
                  </p>
                  <p class="woocommerce-form-row">
                    <input
                      class="woocommerce-Input input-text"
                      type="password"
                      @focus="register.invalidConfirmPassword = false"
                      v-bind:class="{ 'invalid-pass': register.invalidConfirmPassword }"
                      v-model="register.confirmPassword"
                      name="password_confirmation"
                      id="register_password_confirmation"
                      placeholder="Password Confirmation *"
                    />
                  </p>
                  <div class="reg-btn__container">
                    <p class="form-button reg-btn__form-button">
                      <label class="form-reg-btn">
                        <input
                          type="submit"
                          @click.prevent="validateForm('reg')"
                          class="woocommerce-Button au-btn btn-small"
                          name="register"
                          value="Register"
                        />
                        <span class="arrow-right">
                          <i class="zmdi zmdi-arrow-right"></i>
                        </span>
                      </label>
                    </p>
                    <label class="woocommerce-form__label reg-checkbox__label">
                      <i v-if="register.check" class="zmdi zmdi-check reg-checkbox__zmdi-check"></i>
                      <input
                        class="woocommerce-form__input reg-checkbox__ch-box"
                        name="rememberme"
                        type="checkbox"
                        id="rememberme"
                        value="forever"
                      />
                      <span @focus="register.invalidCheck = false" @click="logTest" :class="{'invalid-check' : register.invalidCheck}" class="reg-checkbox__text">I agree to privacy policy</span>
                      
                    </label>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="novas-login-recommend">
            <h2 class="special-heading">Recommended</h2>
            <div class="socials-logins">
              <a href="/auth/redirect/facebook" class="button-social-login login-facebook">
                Facebook
                <i class="zmdi zmdi-facebook-box"></i>
              </a>
            </div>
            <p>Connect with Social Networks</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Library for working with requests
import axios from "axios";

import { log } from "util";

import EventBus from "../../EventBus";

export default {
  name: "LoginEn",
  data() {
    return {
      categories: [],
      login: {
        email: "",
        invalidEmail: false,
        password: "",
        invalidPassword: false,
        validated: false
      },
      register: {
        name: '',
        invalidName: false,
        email: "",
        invalidEmail: false,
        password: "",
        invalidPassword: false,
        confirmPassword: "",
        invalidConfirmPassword: false,
        validated: false,
        check: false,
        invalidCheck: false
      }
    };
  },
  methods: {
    logTest() {
      console.log('test');
      this.register.check = !this.register.check; 
      this.register.invalidCheck = false;
      
    },
    validateForm(form) {
      let mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (form === "login") {
        if (this.login.email.match(mailRegExp)) {
          this.login.invalidEmail = false;
        } else {
          this.login.invalidEmail = true;
        }

        if (this.login.password === "") {
          this.login.invalidPassword = true;
        } else {
          this.login.invalidPassword = false;
        }
        if (
          this.login.invalidPassword === false &&
          this.login.invalidEmail === false
        ) {
          this.login.validated = true;
          // console.log("login validated");
          console.log(document.login);
          document.login.submit();
        } else {
          this.login.validated = false;
          // console.log("login is not validated");
        }
      } else {

        if (this.register.name === "") {
          this.register.invalidName = true;
        } else {
          this.register.invalidName = false;
        }

        if (this.register.email.match(mailRegExp)) {
          this.register.invalidEmail = false;
        } else {
          this.register.invalidEmail = true;
        }

        if (this.register.password === "") {
          this.register.invalidPassword = true;
        } else {
          this.register.invalidPassword = false;
        }

        if (
          this.register.confirmPassword !== "" &&
          this.register.confirmPassword === this.register.password
        ) {
          this.register.invalidConfirmPassword = false;
        } else {
          this.register.invalidConfirmPassword = true;
        }

        if (!this.register.check) {
          this.register.invalidCheck = true;
        } else {
          this.register.invalidCheck = false;
        }

        if (
          !this.register.invalidPassword &&
          !this.register.invalidEmail &&
          !this.register.invalidConfirmPassword &&
          this.register.check &&
          !this.register.invalidName
        ) {
          this.register.validated = true;
          document.register.submit();
          // console.log("reg validated");
        } else {
          this.register.validated = false;
          // console.log("reg is not validated");
        }
      }
    }
  },
  mounted() {
    //Get products
    // this.getProducts();
  }
};
</script>

<style lang="sass" scoped>
    .reg-btn__form-button
        margin-right: 18px

    .invalid-value 
        color: #ff6666
    
    .invalid-pass 
        background: lighten(#ff6666, 20%)

    .form-reg-btn
        position: relative

    // .reg-checkbox__label
    //     float: left

    .reg-btn__container
      display: flex
      margin-top: 50px
      flex-wrap: wrap


    .reg-checkbox__label
      display: flex
      height: 50px
      position: relative

    .reg-checkbox__text
      flex-basis: 190px
      display: flex
      align-items: center

    .reg-checkbox__text::before
      content: ''
      margin-right: 7px
      width: 11px
      height: 11px
      border: 1px solid #cbcbcb
      border-radius: 3px
      background: #f0f0f0

    .reg-checkbox__ch-box
      display: none

    .reg-checkbox__zmdi-check
      position: absolute
      pointer-events: none
      transform: translateY(-50%)
      top: 50%

    .invalid-check
      color: #ff6666

</style>