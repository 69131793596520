var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-wrapper" }, [
    _vm.cart.productsCount
      ? _c("section", { staticClass: "shop-cart-section section-box" }, [
          _c("div", { staticClass: "woocommerce" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "entry-content" }, [
                _c(
                  "form",
                  {
                    staticClass: "woocommerce-cart-form",
                    attrs: { method: "POST" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass:
                          "shop_table shop_table_responsive cart woocommerce-cart-form__contents",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.cart.items, function (item, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass:
                                    "woocommerce-cart-form__cart-item cart_item",
                                },
                                [
                                  _c("td", { staticClass: "product-remove" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "remove",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeItem(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "zmdi zmdi-close",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "product-name d-flex",
                                      attrs: { "data-title": "Product" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              _vm.buildUrl(
                                                _vm.applicationParams.locale
                                              ) + item.slug,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "image-object",
                                            attrs: {
                                              src: item.imageUrl
                                                ? item.imageUrl
                                                : _vm.defaultImage.url,
                                              alt: item.title,
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "item-title-wrapper" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.buildUrl(
                                                    _vm.applicationParams.locale
                                                  ) + item.slug,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "versions-wrapper" },
                                            _vm._l(
                                              item.versions,
                                              function (ver, index) {
                                                return _c(
                                                  "a",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: item.versions,
                                                        expression:
                                                          "item.versions",
                                                      },
                                                    ],
                                                    key: index + ver[0].name,
                                                    staticClass:
                                                      "version-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.verTitle(
                                                          item.versions,
                                                          index
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "product-price",
                                      attrs: { "data-title": "Price" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "woocommerce-Price-amount amount",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "woocommerce-Price-currencySymbol",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.activeCurrency.code)
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.countCost(item)) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "product-quantity",
                                      attrs: { "data-title": "Quantity" },
                                    },
                                    [
                                      _c("div", { staticClass: "quantity" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "modify-qty minus",
                                            on: {
                                              click: function ($event) {
                                                return _vm.decrementQuantity(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "input-text qty text",
                                          attrs: {
                                            type: "number",
                                            name: "quantity",
                                            id: "quantity",
                                            min: "1",
                                          },
                                          domProps: { value: item.quantity },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "modify-qty plus",
                                            on: {
                                              click: function ($event) {
                                                return _vm.incrementQuantity(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("+")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "product-subtotal",
                                      attrs: { "data-title": "Total" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "woocommerce-Price-amount amount",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "woocommerce-Price-currencySymbol",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.activeCurrency.code)
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.total) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.cart.bundles, function (bundle, index) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: index + "bundle",
                                    staticClass:
                                      "woocommerce-cart-form__cart-item cart_item",
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "product-remove" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "remove",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeBundle(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "zmdi zmdi-close",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-name",
                                        attrs: { "data-title": "Продукт" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: bundle.items[0].product
                                                  .images
                                                  ? bundle.items[0].product
                                                      .images[0].url
                                                  : _vm.defaultImage.url,
                                                alt: "Bundle image",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(bundle.title))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-price",
                                        attrs: { "data-title": "Цена" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "woocommerce-Price-amount amount",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "woocommerce-Price-currencySymbol",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.activeCurrency.code
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(bundle.total) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-quantity",
                                        attrs: { "data-title": "Количество" },
                                      },
                                      [
                                        _c("div", { staticClass: "quantity" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "modify-qty minus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.decrementBundleQuantity(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("-")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "input-text qty text",
                                            attrs: {
                                              type: "number",
                                              name: "quantity",
                                              id: "quantity",
                                              min: "1",
                                            },
                                            domProps: {
                                              value: bundle.quantity,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "modify-qty plus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.incrementBundleQuantity(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "product-subtotal",
                                        attrs: { "data-title": "Итог" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "woocommerce-Price-amount amount",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "woocommerce-Price-currencySymbol",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.activeCurrency.code
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  bundle.total * bundle.quantity
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(bundle.items, function (item, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index + bundle.title,
                                      staticClass:
                                        "woocommerce-cart-form__cart-item cart_item",
                                      class: {
                                        "cart_item--no-border":
                                          index !== bundle.items.length - 1,
                                        padding0: index !== 0,
                                        "padding-top30": index === 0,
                                        "padding-bottom30":
                                          index === bundle.items.length - 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "product-remove product-remove--bundle-item padding15",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "remove",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeBundle(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "zmdi zmdi-close",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "product-name padding15",
                                          attrs: { "data-title": "Продукт" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "product-name--bundle-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              item.discount_percentage
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "bundles__discount-flag",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "-" +
                                                            item.discount_percentage +
                                                            "%"
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("img", {
                                                attrs: {
                                                  src: item.product.images
                                                    ? item.product.images[0].url
                                                    : _vm.defaultImage.url,
                                                  alt: "Bundle item image",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.product.title))]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "product-price padding15",
                                          attrs: { "data-title": "Цена" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "woocommerce-Price-amount amount",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-currencySymbol",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.activeCurrency.code
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    item.product.prices[
                                                      _vm.activeCurrency.code
                                                    ].price
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "product-subtotal padding15",
                                          attrs: { "data-title": "Итог" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "woocommerce-Price-amount amount",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-currencySymbol",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.activeCurrency.code
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.substractPercent(
                                                      item.product.prices[
                                                        _vm.activeCurrency.code
                                                      ].price,
                                                      item.discount_percentage
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cart-collaterals" }, [
                  _c("div", { staticClass: "cart_totals" }, [
                    _c("h2", [_vm._v("Підсумки замовлення")]),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "shop_table shop_table_responsive" },
                      [
                        _c("tbody", [
                          _c("tr", { staticClass: "cart-subtotal" }, [
                            _c("th", [_vm._v("Сума")]),
                            _vm._v(" "),
                            _c("td", { attrs: { "data-title": "Subtotal" } }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-amount amount",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-currencySymbol",
                                    },
                                    [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                  ),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.cart.subtotal) +
                                      "\n                      "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.discount
                            ? _c("tr", { staticClass: "cart-subtotal" }, [
                                _c("th", [_vm._v("Знижка")]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { "data-title": "Discount" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "woocommerce-Price-amount amount",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "woocommerce-Price-currencySymbol",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.activeCurrency.code)
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.discount) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tr", { staticClass: "cart-subtotal" }, [
                            _c("th", [_vm._v("Разом")]),
                            _vm._v(" "),
                            _c("td", { attrs: { "data-title": "Subtotal" } }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-amount amount",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-currencySymbol",
                                    },
                                    [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                  ),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.cart.total) +
                                      "\n                      "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(2),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.cart.productsCount
      ? _c("div", { staticClass: "empty-cart" }, [
          _c("h3", { staticClass: "empty-cart__text" }, [
            _vm._v(
              "Ваш кошик порожній. Будь ласка, додайте деякі продукти до нього."
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "product-remove" }),
        _vm._v(" "),
        _c("th", { staticClass: "product-name" }, [_vm._v("Продукт")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-price" }, [_vm._v("Ціна")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-quantity" }, [_vm._v("Кількість")]),
        _vm._v(" "),
        _c("th", { staticClass: "product-subtotal" }, [_vm._v("Разом")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "product-quantity padding15",
        attrs: { "data-title": "Количество" },
      },
      [
        _c("div", { staticClass: "quantity" }, [
          _c("input", {
            staticClass: "input-text qty text qty--bundle-item",
            attrs: {
              type: "number",
              name: "quantity",
              id: "quantity",
              value: "1",
              min: "1",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wc-proceed-to-checkout" }, [
      _c(
        "a",
        {
          staticClass: "checkout-button button wc-forward au-btn btn-small",
          attrs: { href: "/checkout" },
        },
        [
          _vm._v("\n                  Оформити замовлення\n                  "),
          _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }