<template>
  <section
    class="breadcrumb-contact-us breadcrumb-section section-box"
    style="background-image: url(/images/1920x330.jpg)"
  >
    <div class="container">
      <div class="breadcrumb-inner">
        <h1>{{ selectedCategory !== null ? selectedCategory : breadcrumbs[breadcrumbs.length - 1].title }}</h1>
        <ul class="breadcrumbs">
          <li :key="index" v-for="(link, index) in breadcrumbs">
            <a
              class="breadcrumbs-1"
              :class=" { 'breadcrumbs-1--remove-slash' : (index + 1) === breadcrumbs.length } "
              :href="link.url"
            >{{ link.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { log } from "util";

export default {
  name: "Commonbreadcrumbs",
  props: {
    breadcrumbs: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      //   products: [],
      selectedCategory: null
    };
  },
  methods: {},
  mounted() {

      this.EventBus.$on('categorySelected', category => {

          if(this.applicationParams.locale === 'en'){

              console.log(category);

              this.selectedCategory = JSON.parse(category.translations).title;

          }
          else{

              this.selectedCategory = category.title;

          }

      });

  }
};
</script>

<style lang="scss" scoped>
.breadcrumbs-1--remove-slash::after {
  display: none;
}
</style>