<template>
  <div class="wishlist-table">
    <div v-if="!products.length" class="empty-cart">
      <h3
        class="empty-cart__text"
      >Cписок желаний пуст. Добавляйте товары в список желаний, делитесь списками с друзьями и обсуждайте товары вместе. Если у вас уже есть списки желаний, авторизуйтесь</h3>
    </div>
    <section v-if="products.length" class="shop-cart-section wishlist-section section-box">
      <div class="woocommerce">
        <div class="container">
          <div class="entry-content">
            <form class="woocommerce-cart-form" method="POST">
              <table
                class="shop_table shop_table_responsive cart woocommerce-cart-form__contents wishlist_table"
              >
                <thead>
                  <tr>
                    <th class="product-remove"></th>
                    <th class="product-name">Товар</th>
                    <th class="product-price">Цена</th>
                    <th class="product-quantity product-stock-status"></th>
                    <th class="product-subtotal product-add-to-cart">Добавить в список желаний</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :key="index"
                    v-for="(product, index) in products"
                    class="woocommerce-cart-form__cart-item cart_item"
                  >
                    <td class="product-remove">
                      <a @click.prevent="removeItem(index)" href="#" class="remove">
                        <i class="zmdi zmdi-close"></i>
                      </a>
                    </td>
                    <td class="product-name" data-title="Product">
                      <a :href="buildUrl(applicationParams.locale) + product.slug">
                        <img
                          :src="product.images.length ? product.images[0].url : defaultImage.url"
                          :alt="product.images.length ? product.images[0].alt : defaultImage.alt"
                        />
                      </a>
                      <a
                        :href="buildUrl(applicationParams.locale) + product.slug"
                      >{{ product.title }}</a>
                    </td>
                    <td class="product-price" data-title="Price">
                      <span class="price">
                        <div :class="{'crossed-out' : product.prices['RUB'].sale_price }">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ showItemPrice(product) }}
                          </span>
                        </div>
                        <div v-if="product.prices['RUB'].sale_price">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ showItemPrice(product, true) }}
                          </span>
                        </div>
                      </span>
                    </td>
                    <td class="product-quantity product-stock-status" data-title="Stock Status">
                      <div class="quantity">
                        <!-- <span class="wishlist-in-stock">
                        <i class="zmdi zmdi-check"></i>In Stock
                        </span>-->
                      </div>
                    </td>
                    <td class="product-subtotal product-add-to-cart" data-title="Add to Cart">
                      <a
                        :href="buildUrl(applicationParams.locale) + product.slug"
                        class="au-btn btn-small"
                      >
                        Заказать сейчас
                        <i class="zmdi zmdi-arrow-right"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="actions" colspan="6">
                      <div>
                        <a
                          @click.prevent="clearWishList"
                          href="#"
                          class="empty-wishlist au-btn btn-small"
                        >
                          Очистить список желаний
                          <i class="zmdi zmdi-arrow-right"></i>
                        </a>
                      </div>
                      <a href="/categories" class="continue au-btn-black btn-small">
                        Продолжить покупки
                        <i class="zmdi zmdi-arrow-right"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//Library for working with requests
import axios from "axios";

import { log } from "util";

import EventBus from "../../EventBus";

export default {
  name: "Wishlisttable",
  data() {
    return {
      products: [],
      activeCurrency: {},
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  methods: {
    showItemPrice(product, sale) {
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
        console.log("salePrice: ", salePrice);
      }

      if (product.version_items) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },
    // getProductPrice(product){

    //   let priceStr = '';
    //   let code = this.activeCurrency.code;

    //   if (this.activeCurrency.code) {

    //     priceStr = product.prices[code].price;

    //     if (priceStr === undefined) {
    //       priceStr = product.prices[code];
    //     }

    //   }

    //   return priceStr

    // },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    updateWishList(id) {
      let requestUrl =
        this.API.wishlist.update + this.$attrs.wishlist.id + "/remove";

      let requestParams = {
        products: id
      };

      axios.post(requestUrl, requestParams);
    },
    removeItem(index) {
      let conf = this.$swal({
        title: "Remove this item from your wishlist?",
        // showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-confirm-custom",
          cancelButton: "swal-cancel-custom"
        }
      }).then(result => {
        if (result.value) {
          let productId = [this.products[index].id];

          this.products.splice(index, 1);
          this.updateWishList(productId);
        }
      });
    },
    clearWishList() {
      let conf = this.$swal({
        title: "Clear wishlist?",
        showCancelButton: true,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-confirm-custom",
          cancelButton: "swal-cancel-custom"
        }
      }).then(result => {
        if (result.value) {
          let productsId = [];
          for (let product of this.products) {
            productsId.push(product.id);
          }
          this.updateWishList(productsId);
          this.products = [];
        }
      });
    },

    getProducts() {
      for (let product of this.$attrs.wishlist.products) {
        this.products.push(product);
      }
    },
    addToCart(product) {
      EventBus.$emit("add-to-cart", product);
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },

  mounted() {
    // Set currancy
    this.setCurrency();

    this.getProducts();

    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="scss" scoped>
.empty-cart__text {
  width: 86.4%;
  margin: 0 auto;
  padding: 150px 0;
}
</style>