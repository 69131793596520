export const API = {

    user: {
        editProfile: '/profile/edit'
    },
    address: {
        create: '/addresses',
        update: '/addresses/',
        delete: '/addresses/'
    },
    newsletterSubscription: {
        status: '/subscriptions/status',
        create: '/subscriptions',
        delete: '/subscriptions',
        remove: '/subscriptions/remove'
    },
    product: {
        list: '/products/list',
        show: '/products/',
        showEn: '/en/products/'
    },
    category: {
        list: '/categories/list',
        show: '/categories/',
        vip: '/categories/vip',
        new: '/categories/novi',
        promo: 'categories/promo'
    },
    filter: {
        list: '/filters/list'
    },
    paymentMethod: {
        list: '/payment_methods/list'
    },
    shippingMethod: {
        list: '/shipping_methods/list'
    },
    cart: {
        calculate: '/cart/calculate'
    },
    order: {
        calculate: '/orders/calculate',
        create: '/orders'
    },
    coupon: {
        validate: '/coupons/validate'
    },
    giftCard: {
        validate: '/gift_cards/validate'
    },
    resources: {
        defaultImages: {
            product: '/images/default/product/default_product_image.jpg'
        }
    },
    review: {
        create: '/reviews'
    },
    wishlist: {
        update: '/wishlists/'
    }

};