var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "my-account-section section-box" }, [
    _c("div", { staticClass: "woocommerce" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content-area" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" },
              [
                _c("div", { staticClass: "entry-content" }, [
                  _c("h2", { staticClass: "special-heading" }, [
                    _vm._v("Вход в аккаунт"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "woocommerce-form-login js-contact-form",
                      attrs: {
                        action: "/login",
                        name: "login",
                        method: "POST",
                      },
                    },
                    [
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.login.email,
                              expression: "login.email",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: { "invalid-pass": _vm.login.invalidEmail },
                          attrs: {
                            type: "text",
                            name: "email",
                            id: "username",
                            value: "",
                            placeholder: "Ваш Email *",
                          },
                          domProps: { value: _vm.login.email },
                          on: {
                            focus: function ($event) {
                              _vm.login.invalidEmail = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.login, "email", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.login.password,
                              expression: "login.password",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: { "invalid-pass": _vm.login.invalidPassword },
                          attrs: {
                            type: "password",
                            name: "password",
                            id: "password",
                            placeholder: "Пароль *",
                          },
                          domProps: { value: _vm.login.password },
                          on: {
                            focus: function ($event) {
                              _vm.login.invalidPassword = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.login,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "form-button" }, [
                        _c("label", [
                          _c("input", {
                            staticClass: "woocommerce-Button au-btn btn-small",
                            attrs: {
                              type: "submit",
                              name: "login",
                              value: "Войти",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.validateForm("login")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm._m(0),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-LostPassword" }, [
                        _vm.login.invalidEmail
                          ? _c("a", { attrs: { href: "#" } }, [
                              _vm._v(
                                "Пожалуйста, введите действительный адрес электронной почты"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-LostPassword" }, [
                        _vm.login.invalidPassword
                          ? _c("a", { attrs: { href: "lost-password.html" } }, [
                              _vm._v("Напомнить пароль"),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" },
              [
                _c("div", { staticClass: "novas-form-signup" }, [
                  _c("h2", { staticClass: "special-heading" }, [
                    _vm._v("Регистрация"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "woocommerce-form-register js-contact-form",
                      attrs: {
                        action: "/register",
                        name: "register",
                        method: "post",
                      },
                    },
                    [
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.register.name,
                              expression: "register.name",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: { "invalid-pass": _vm.register.invalidName },
                          attrs: {
                            type: "text",
                            name: "name",
                            id: "register_username",
                            value: "",
                            placeholder: "Ваше имя *",
                          },
                          domProps: { value: _vm.register.name },
                          on: {
                            focus: function ($event) {
                              _vm.register.invalidName = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.register,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.register.email,
                              expression: "register.email",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: { "invalid-pass": _vm.register.invalidEmail },
                          attrs: {
                            type: "text",
                            name: "email",
                            id: "register_email",
                            value: "",
                            placeholder: "Ваш Email *",
                          },
                          domProps: { value: _vm.register.email },
                          on: {
                            focus: function ($event) {
                              _vm.register.invalidEmail = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.register,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.register.password,
                              expression: "register.password",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: {
                            "invalid-pass": _vm.register.invalidPassword,
                          },
                          attrs: {
                            type: "password",
                            name: "password",
                            id: "register_password",
                            placeholder: "Пароль *",
                          },
                          domProps: { value: _vm.register.password },
                          on: {
                            focus: function ($event) {
                              _vm.register.invalidPassword = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.register,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "woocommerce-form-row" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.register.confirmPassword,
                              expression: "register.confirmPassword",
                            },
                          ],
                          staticClass: "woocommerce-Input input-text",
                          class: {
                            "invalid-pass": _vm.register.invalidConfirmPassword,
                          },
                          attrs: {
                            type: "password",
                            name: "password_confirmation",
                            id: "register_password_confirmation",
                            placeholder: "Подтверждение пароля *",
                          },
                          domProps: { value: _vm.register.confirmPassword },
                          on: {
                            focus: function ($event) {
                              _vm.register.invalidConfirmPassword = false
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.register,
                                "confirmPassword",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "reg-btn__container" }, [
                        _c(
                          "p",
                          { staticClass: "form-button reg-btn__form-button" },
                          [
                            _c("label", { staticClass: "form-reg-btn" }, [
                              _c("input", {
                                staticClass:
                                  "woocommerce-Button au-btn btn-small",
                                attrs: {
                                  type: "submit",
                                  name: "register",
                                  value: "Войти",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.validateForm("reg")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(1),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass:
                              "woocommerce-form__label reg-checkbox__label",
                          },
                          [
                            _vm.register.check
                              ? _c("i", {
                                  staticClass:
                                    "zmdi zmdi-check reg-checkbox__zmdi-check",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              staticClass:
                                "woocommerce-form__input reg-checkbox__ch-box",
                              attrs: {
                                name: "rememberme",
                                type: "checkbox",
                                id: "rememberme",
                                value: "forever",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "reg-checkbox__text",
                                class: {
                                  "invalid-check": _vm.register.invalidCheck,
                                },
                                on: {
                                  focus: function ($event) {
                                    _vm.register.invalidCheck = false
                                  },
                                  click: _vm.logTest,
                                },
                              },
                              [
                                _vm._v(
                                  "Я согласен с политикой конфиденциальности"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow-right" }, [
      _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow-right" }, [
      _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "novas-login-recommend" }, [
      _c("h2", { staticClass: "special-heading" }, [
        _vm._v("Зарегистрироваться с помощью"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "socials-logins" }, [
        _c(
          "a",
          {
            staticClass: "button-social-login login-facebook",
            attrs: { href: "/auth/redirect/facebook" },
          },
          [
            _vm._v("\n              Facebook\n              "),
            _c("i", { staticClass: "zmdi zmdi-facebook-box" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Вы можете зарегистрироваться с помощью учетней записи фейсбук"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }