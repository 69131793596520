<template>
  <section
    class="product-page__main-component shop-single-v1-section shop-single-v2-section section-box featured-hp-1 featured-hp-4"
  >
    <div class="woocommerce">
      <div class="container">
        <div class="content-area">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 product-page__galery">
              <!-- <div class="featured__promo-flags-container">
                <a v-if="product.promo" :href="API.category.promo" class="onsale">SALE</a>
                <a v-if="product.new" :href="API.category.new" class="onnew">NEW</a>
              </div> -->
              <div class="woocommerce-product-gallery">
                <div class="owl-carousel product-slider">
                  <div v-if="!product.images.length" class="item">
                    <img :src="defaultImage.url" :alt="defaultImage.alt" />
                  </div>
                  <div :key="'image' + index" v-for="(image, index) in product.images" class="item">
                    <img :src="image.url" :alt="image.alt" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 height-680">
              <div class="summary entry-summary">
                <h1 class="abr-product-title product_title entry-title">{{ JSON.parse(product.translations).title }}</h1>
                <div class="woocommerce-product-rating">
                  <div class="star-rating">
                    <i :key="'star' + index" v-for="(star, index) in rating" class="zmdi zmdi-star"></i>
                    <i
                      :key="'emptyStar' + index"
                      v-for="(star, index) in emptyStars"
                      class="zmdi zmdi-star-outline"
                    ></i>
                  </div>
                  <span
                    href="#"
                    class="woocommerce-review-link"
                  >({{ product.reviews_count }} customer review)</span>
                </div>
                <p class="price active-color">
                  <ins>
                    <span
                      class="woocommerce-Price-amount amount"
                      :class="{'crossed-out' : product.prices['RUB'].sale_price }"
                    >
                      <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                      {{ itemPrice }}
                    </span>
                    <br />
                    <span
                      v-if="product.prices['RUB'].sale_price"
                      class="woocommerce-Price-amount amount"
                    >
                      <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                      {{ showItemPrice() }}
                    </span>
                  </ins>
                </p>
                <div class="woocommerce-product-details__short-description">
                  <p>{{ JSON.parse(product.translations).short_description }}</p>
                </div>
                <form class="cart" method="post">
                  <div class="quantity">
                    <input
                      type="number"
                      name="quantity"
                      id="quantity"
                      :value="item.quantity"
                      min="1"
                      class="nput-text qty text"
                    />
                    <span class="modify-qty plus" @click="incrementQuantity()">+</span>
                    <span class="modify-qty minus" @click="decrementQuantity()">-</span>
                  </div>
                  <button
                    @click.prevent="addToCart(product)"
                    type="submit"
                    name="add-to-cart"
                    class="single_add_to_cart_button button alt au-btn btn-small active-background active-abr-btn"
                  >
                    Add to cart
                    <i class="zmdi zmdi-arrow-right"></i>
                  </button>
                  <div class="versions abr-versions__style" v-if="versions !== undefined && product.selectedVersions !== undefined && versionValues !== undefined">
                    <div
                      :key="index + option.name"
                      v-for="(option, index) in product.version_items"
                    >
                      <div class="versions__wrap abr_double" v-if="option.id === 5 || option.id === 15">
                        <div class="versions__title">{{ option.name }}</div>
                        <!--<select @change="versionSelected($event, index)" class="product-option">
                          <option
                            :key="index + version.option_value.value"
                            v-for="(version, index) in option.versions"
                            :value="version.option_value.value"
                          >{{ version.option_value.value }}</option>
                        </select>-->
                        <v-select :key="`${option.name}-${index + option.id}`"
                                  label="id"
                                  :options="option.versions"
                                  v-model="versionValues[index]"
                                  :clearable="false"
                                  :searchable="false"
                                  @input="(val) => { selectVersion(val, index) }"
                        >
                          <template #selected-option="{option_value}">
                            <div class="abr-versions_row">
                              <img width="50" height="50" v-if="option_value.image !== null" :src="option_value.image.url"/>
                              <p>{{ option_value.value }}</p>
                            </div>
                          </template>
                          <template #option="{option_value}">
                            <div class="abr-versions_row">
                              <img width="50" height="50" v-if="option_value.image !== null" :src="option_value.image.url"/>
                              <p>{{ option_value.value }}</p>
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <div class="versions__wrap" v-else-if="option.id !== 14">
                        <div class="versions__title">{{ option.name }}</div>
                        <!--<select @change="versionSelected($event, index)" class="product-option">
                          <option
                            :key="index + version.option_value.value"
                            v-for="(version, index) in option.versions"
                            :value="version.option_value.value"
                          >{{ version.option_value.value }}</option>
                        </select>-->
                        <v-select :key="`${option.name}-${index + option.id}`"
                                  label="id"
                                  :options="option.versions"
                                  v-model="versionValues[index]"
                                  :clearable="false"
                                  :searchable="false"
                                  @input="(val) => { selectVersion(val, index) }"
                        >
                          <template #selected-option="{option_value}">
                            <div class="abr-versions_row">
                              <img width="50" height="50" v-if="option_value.image !== null" :src="option_value.image.url"/>
                              <p>{{ option_value.value }}</p>
                            </div>
                          </template>
                          <template #option="{option_value}">
                            <div class="abr-versions_row">
                              <img width="50" height="50" v-if="option_value.image !== null" :src="option_value.image.url"/>
                              <p>{{ option_value.value }}</p>
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <div v-else></div>
                    </div>
                  </div>
                </form>
                <div class="product_meta">
                  <!-- <span class="sku_wrapper">
                    Article No.:
                    <span class="sku">{{ product.article_number }}</span>
                  </span>-->
                  <span class="posted_in">
                    Category:
                    <a
                      :key="index + cat.title"
                      v-for="(cat, index) in categories"
                      :href="`/en/categories/` + cat.slug"
                    >{{ categoryTitle(cat.translations, index) }}</a>
                  </span>
                </div>
                <div class="product-share">
                  <span>Share:</span>
                  <a
                    target="_blank"
                    :href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl"
                  >
                    <i class="zmdi zmdi-facebook"></i>
                  </a>
                  <a :href="applicationParams.instagram_url">
                    <i class="zmdi zmdi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="woocommerce-tabs">
                <ul class="nav nav-tabs wc-tabs" id="myTab" role="tablist">
                  <li
                    class="nav-item description_tab"
                    id="tab-title-description"
                    role="tab"
                    aria-controls="tab-description"
                    aria-selected="true"
                  >
                    <a class="nav-link active" href="#tab-description" data-toggle="tab">Description</a>
                  </li>
                  <li
                    class="nav-item reviews_tab"
                    id="tab-title-reviews"
                    role="tab"
                    aria-controls="tab-reviews"
                    aria-selected="false"
                  >
                    <a
                      class="nav-link"
                      href="#tab-reviews"
                      data-toggle="tab"
                    >Reviews({{ product.reviews_count }})</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="woocommerce-Tabs-panel tab-pane fade show active"
                    id="tab-description"
                    role="tabpanel"
                    aria-labelledby="tab-title-description"
                  >
                    <div
                      class="row product-description-images"
                      :class="{ 'product-page__description' : !(product.images.length > 1) }"
                    >
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div>
                          <img
                            class="image-object"
                            style="width: 100%; object-fit: cover; object-position: center;"
                            :src="product.images.length ? product.images[0].url : defaultImage.url"
                            :alt="product.images.length ? product.images[0].alt : defaultImage.alt"
                          />
                        </div>
                      </div>
                      <div
                        v-if="product.images.length > 1"
                        class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                      >
                        <figure>
                          <img
                            class="image-object"
                            style="width: 100%; object-fit: cover; object-position: center;"
                            :src="product.images[1].url"
                            :alt="product.images[1].alt"
                          />
                        </figure>
                      </div>
                      <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <span>Measurements:</span>
                          <p>Width: {{ product.width + product.length_unit }}</p>
                          <p>Height: {{ product.height + product.length_unit }}</p>
                          <p>Weight: {{ product.weight + product.weight_unit }}</p>
                      </div>-->
                    </div>
                  </div>
                  <div
                    class="woocommerce-Tabs-panel tab-pane"
                    id="tab-reviews"
                    role="tabpanel"
                    aria-labelledby="tab-title-reviews"
                  >
                    <div class="woocommerce-Reviews" id="reviews">
                      <h2>{{ product.reviews_count }} review for {{ product.title }}</h2>
                      <div id="comments">
                        <div class="comment-list">
                          <div
                            :key="'comment' + index"
                            v-for="(comment, index) in product.reviews"
                            class="comment-item"
                          >
                            <div class="comment-content">
                              <div class="userPic">
                                <p class="userPic__init">{{ userInitials(comment.user.name) }}</p>
                              </div>
                              <div class="comment-body">
                                <div class="comment-author">
                                  <span class="author">{{ comment.user.name }}</span>
                                  <div class="star-rating">
                                    <i
                                      :key="'rating' + index"
                                      v-for="(star, index) in comment.rating"
                                      class="zmdi zmdi-star"
                                    ></i>
                                    <i
                                      :key="'emptyRating' + index"
                                      v-for="(star, index) in (5 - comment.rating)"
                                      class="zmdi zmdi-star-outline"
                                    ></i>
                                  </div>
                                </div>
                                <span class="comment-time">{{ dateFormat(comment.updated_at) }}</span>
                                <p>{{ comment.content }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="review_form_wrapper">
                        <div id="review_form">
                          <div id="respond" class="comment-respond">
                            <form
                              id="commentform"
                              @submit="submitReview($event)"
                              v-if="applicationParams.user"
                              class="comment-form common-form js-contact-form"
                              action="includes/contact-form.php"
                              method="POST"
                            >
                              <p class="comment-notes">
                                <span>Add a review</span>
                                <span id="email-notes">
                                  Your email address will not be published. Required fields are marked
                                  <span
                                    class="required"
                                  >*</span>
                                </span>
                              </p>
                              <div class="comment-form-rating">
                                <label>Your rating</label>
                                <p class="stars">
                                  <input
                                    type="checkbox"
                                    :checked="userRating"
                                    class="stars__checkbox"
                                    required
                                  />
                                  <a
                                    :key="'userStarPos' + index"
                                    v-for="(star, index) in (userRating)"
                                    :id="index + 1"
                                    href="#"
                                    @click.prevent="rateItem($event)"
                                    class="star-1"
                                  >
                                    <i class="zmdi zmdi-star"></i>
                                  </a>
                                  <a
                                    :key="'userStarNeg' + index"
                                    v-for="(emptyStar, index) in (5 - userRating)"
                                    :id="userRating + (index + 1)"
                                    href="#"
                                    @click.prevent="rateItem($event)"
                                    class="star-1"
                                  >
                                    <i class="zmdi zmdi-star-outline"></i>
                                  </a>
                                </p>
                              </div>
                              <p class="comment-form-comment">
                                <textarea
                                  id="comment"
                                  name="comment"
                                  required
                                  v-model="reviewContent"
                                  placeholder="Write Your Review..."
                                ></textarea>
                              </p>
                              <p class="form-submit">
                                <input
                                  name="submit"
                                  type="submit"
                                  id="submit"
                                  class="submit au-btn btn-small"
                                  value="Submit"
                                />
                                <span>
                                  <i class="zmdi zmdi-arrow-right"></i>
                                </span>
                              </p>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="bundles">
            <div class="bundles__container owl-carousel owl-theme">
              <div
                :key="index + 'item'"
                v-for="(bundle, index) in product.all_bundles"
                class="bundles__item-container"
              >
                <div class="break"></div>
                <h2 class="special-heading">{{ bundle.title }}</h2>
                <!-- <div class="bundles__total-container-wrapper"></div> -->

                <div class="bundles__total-container">
                  <h4
                    class="bundles__tottal"
                  >Total: {{ getProductPrice(bundle) + ' ' + activeCurrency.code }}</h4>
                  <div @click="addBundleToCart(bundle)" class="swal-cancel-custom bundles__btn-buy">
                    Buy
                    <i class="zmdi zmdi-arrow-right"></i>
                  </div>
                </div>
                <div
                  :key="index + 'item-box'"
                  v-for="(item, index) in bundle.items"
                  class="bundles__item-box"
                >
                  <div
                    v-if="item.discount_percentage"
                    class="bundles__discount-flag"
                  >{{ '-' + item.discount_percentage + '%' }}</div>
                  <a :href="buildUrl(applicationParams.locale) + item.product.slug">
                    <img
                      class="bundles__img"
                      :src="item.product.images ? item.product.images[0].url : defaultImage.url"
                      :alt="item.product.images ? item.product.images[0].alt : defaultImage.alt"
                    />
                  </a>

                  <div class="bundles__text">
                    <a :href="buildUrl(applicationParams.locale) + item.product.slug">
                      <h5 class="bundles__title">{{ item.product.title }}</h5>
                    </a>

                    <!-- showBundlePrice(item, sale) -->
                    <span
                      class="bundles__price"
                      :class="{'crossed-out' : checkIfSale(item) }"
                    >{{ showBundlePrice(item) + " " + activeCurrency.code }}</span>
                    <span
                      v-if="checkIfSale(item)"
                      class="bundles__price"
                    >{{ showBundlePrice(item, true) + " " + activeCurrency.code }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <productrow :title="'Related'" :product-id="product.id" :load-related="true" v-if="loaded"></productrow>
          <productrow :title="'Bestseller'" v-if="loaded"></productrow>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Library for working with requests
import axios from "axios";

import { log } from "util";

import { cartModel, itemModel } from "../../static/cart/cart";

import EventBus from "../../EventBus";
import Productrow from "./ProductRow";

//Vue select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: { Productrow, vSelect },
  name: "ProductpageEn",
  props: {
    product: {
      type: Object
    },
    relatedProducts: {
      type: Array
    },
    bestsellerProducts: {
      type: Array
    }
  },
  data() {
    return {
      products: [],
      cart: {
        items: []
      },
      rating: [],
      emptyStars: [],
      userRating: 0,
      reviewContent: "",
      categories: [],
      productIndex: 0,
      productQuantity: 0,
      wasInCart: false,
      item: {},
      pageLoad: true,
      currentUrl: "",
      activeCurrency: {},
      productPrice: 0,
      versions: [],
      versionsCost: 0,
      versionsCostInitial: 0,
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      },
      loaded: false,
      versionValues: []
    };
  },
  computed: {
    itemPrice() {
      // return this.showItemPrice;
      return this.productPrice + this.versionsCostInitial;
    }
  },
  methods: {
    checkIfSale(item) {
      let check = false;
      if (
        this.showBundlePrice(item, true) &&
        this.showBundlePrice(item, true) < this.showBundlePrice(item)
      ) {
        check = true;
      }
      return check;
    },
    showBundlePrice(item, sale) {
      let prodPrice = 0;
      let salePrice = item.prices[this.activeCurrency.code];

      prodPrice = item.product.prices[this.activeCurrency.code].price;

      if (sale && salePrice) {
        prodPrice = salePrice;
      }

      return prodPrice;
    },
    showItemPrice() {
      let product = Object.assign({}, this.product);
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (salePrice) {
        prodPrice = salePrice;
        let versionPrice = this.versionsCost;
        if (product.promotion !== null && product.promotion !== undefined) {
          let promo = product.promotion;

          if (promo.is_fixed_price && versionPrice >= promo.value) {
            versionPrice -= promo.value;
          } else {
            let discount = (versionPrice * parseInt(promo.value)) / 100;
            versionPrice -= discount;
          }
        }
        prodPrice += versionPrice;
      } else {
        prodPrice += this.versionsCost;
      }

      /*if (product.version_items.length) {
            for (let option of product.version_items) {
                verCost +=
                    option.versions[0].version_prices[this.activeCurrency.code];
            }
            prodPrice += verCost;
        }*/

      //console.log("Price");
      return prodPrice;
    },
    categoryTitle(cat, index) {
      let str = cat.title;
      if (index !== this.categories.length - 1) {
        str += ", ";
      }
      return str;
    },
    versionSelected(event, index) {
      // let option = this.product.version_items[index];
      let option = Object.assign({}, this.product.version_items[index]);
      let id = 0;
      let selectedVersion;
      let selectedVersionIndex = 0;
      for (let version of option.versions) {
        if (version.option_value.value === event.target.value) {
          selectedVersion = version;
          break;
        }
        selectedVersionIndex++;
      }

      this.versions[index] = [option, selectedVersionIndex];

      // this.product.selectedVersions = this.versions;

      this.product.selectedVersions = Object.assign({}, this.versions);

      this.updateVersionsCost();
    },
    selectVersion(value, index) {

        // let option = this.product.version_items[index];
        let option = Object.assign({}, this.product.version_items[index]);
        let id = 0;
        let selectedVersion;
        let selectedVersionIndex = 0;

        /*for (let version of option.versions) {
            if (version.option_value_id === value.option_value_id) {
                selectedVersion = version;
                break;
            }
            selectedVersionIndex++;
        }*/

        let existing = option.versions.find(version => {
            return version.option_value_id === value.option_value_id
        });

        if(existing !== undefined){

            selectedVersionIndex = option.versions.indexOf(existing);

            this.versions[index] = [option, selectedVersionIndex];

            //this.product.selectedVersions = this.versions;

            this.product.selectedVersions[index] = this.versions[index]; //Object.assign({}, this.product.selectedVersions, {[index]: this.versions[index]});

            this.updateVersionsCost();
            console.log("Update Versions Cost");

        }
    },
    updateVersionsCost() {
        this.versionsCost = 0;
        this.versionsCostInitial = 0;
        this.versions.map(option => {
            this.versionsCost +=
                option[0].versions[option[1]].version_prices[
                    this.activeCurrency.code
                    ];
            this.versionsCostInitial +=
                option[0].versions[option[1]].initial_prices[
                    this.activeCurrency.code
                    ];
            console.log('Price: ' +
                option[0].versions[option[1]].version_prices[this.activeCurrency.code]
            );
            console.log('Price initial: ' +
                option[0].versions[option[1]].initial_prices[this.activeCurrency.code]
            );
        });
    },
    getSelectedVersion(index){
        const versionIndex = this.versions[index][1];
        const versionList =  this.versions[index][0].versions;

        if(versionList !== undefined && versionIndex !== undefined){

            return versionList[versionIndex];

        }
    },
    setVersions() {
      if (this.product.version_items) {
        this.product.version_items.map(version => {
          let option = [version, 0];
          this.versions.push(option);
          this.versionsCost +=
            version.versions[0].version_prices[this.activeCurrency.code];
          this.versionsCostInitial +=
              version.versions[0].initial_prices[this.activeCurrency.code];
          // this.product.selectedVersions = this.versions;
          this.product.selectedVersions = Object.assign({}, this.versions);
        });
        this.setVersionValues();
      }
    },
    setVersionValues(){

        if(this.versions !== undefined){

            this.versions.forEach((version, index) => {

                this.versionValues.push(this.getSelectedVersion(index));

            });

        }

    },
    getProductPrice(product) {
      let priceStr = "";
      let code = this.activeCurrency.code;

      if (this.activeCurrency.code) {
        priceStr = product.prices[code].price;

        if (priceStr === undefined) {
          priceStr = product.prices[code];
        }
      }

      return priceStr;
    },

    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
      let index = 0;
      for (let cur in this.product.prices) {
        if (cur === this.activeCurrency.code) {
          index = cur;
          break;
        }
        index++;
      }

      this.productPrice = this.product.prices[index].price;
    },
    addBundleToCart(product) {
      EventBus.$emit("add-bundle-to-cart", product);
      //Show success notification
    },
    dateFormat(date) {
      let dateStr = "";
      if (typeof date === "string") {
        let dateArr = date.split("-");
        for (let x of dateArr) {
          dateStr += x + ".";
        }
        dateArr = dateStr.split(" ");
        dateStr = dateArr[0];
        dateArr = dateStr.split(".");
        dateArr.reverse();
        dateStr = dateArr[0] + "." + dateArr[1] + "." + dateArr[2];
      }
      return dateStr;
    },
    checkIfInCart() {
      let i = 0;
      for (let item of this.cart.items) {
        if (item.title === this.product.title && i < this.productIndex) {
          this.wasInCart = true;

          break;
        } else {
          this.wasInCart = false;
        }
        i++;
      }
    },
    setCart() {
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.setNewCart();
      }
    },
    setNewCart() {
      this.cart = Object.assign({}, cartModel);

      this.cart.items = [];

      this.cart.bundles = [];

      this.cart.currency = this.applicationParams.currency;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    createCartItem() {
      let newItem = Object.assign({}, itemModel);
      newItem.productId = this.product.id;
      newItem.title = this.product.title;
      newItem.slug = this.product.slug;
      newItem.price = this.product.price;
      newItem.quantity = 1;

      if (this.product.images.length) {
        newItem.imageUrl = this.product.images[0].url;
      }
      this.cart.items.push(newItem);
    },
    setQuantity() {
      this.productQuantity = this.cart.items[
        this.cart.items.length - 1
      ].quantity;
    },
    addToCart(product) {
      // this.checkIfInCart();
      EventBus.$emit("add-to-cart", product);

      if (this.item.quantity) {
        let index = 0;
        let prodVersions;
        if (product.selectedVersions) {
          prodVersions = product.selectedVersions;
          if (!product.selectedVersions.length) {
            prodVersions = null;
          }
        }

        let productObj = {
          prod: product,
          versions: prodVersions,
          quantity: this.item.quantity - 1
        };
        EventBus.$emit("product-page-quantity", productObj);
      }
      // if (this.wasInCart) {
      //   for (let i = 0; i < this.item.quantity - 1; i++) {
      //     EventBus.$emit("product-page-quantity", this.productIndex - 1);
      //   }
      // } else {
      //   for (let i = 0; i < this.item.quantity - 1; i++) {
      //     EventBus.$emit("product-page-quantity", this.productIndex);
      //   }
      // }

      // EventBus.$emit("products-updated", this.cart.items);
    },

    incrementQuantity() {
      let item = this.cart.items[this.productIndex];
      item.quantity += 1;

      //Update checkout products list
      // this.sendUpdatedProducts();
    },
    decrementQuantity() {
      let item = this.cart.items[this.productIndex];

      if (item.quantity > 1) {
        item.quantity -= 1;
      }

      //Update checkout products list
      // this.sendUpdatedProducts();
    },
    sendUpdatedProducts() {
      //Update a checkout product list
      this.EventBus.$emit("products-updated", this.cart.items);
    },
    setIndex() {
      this.productIndex = this.cart.items.length - 1;
    },
    submitReview(event) {
      event.preventDefault();
      if (this.userRating && this.reviewContent) {
        let requestUrl = this.API.review.create;

        let requestParams = {
          product_id: this.product.id,
          rating: this.userRating,
          content: this.reviewContent
        };

        axios.post(requestUrl, requestParams).then(response => {
          if (response.data === "success") {
            this.showNotification(
              "success",
              "Review has been added and is waiting for acceptation."
            );
          } else {
            this.showNotification("error", "Error. Something went wrong.");
          }
        });
      }
      return false;
    },
    userInitials(name) {
      let initials = "";
      for (let word of name.split(" ")) {
        initials += word.slice(0, 1);
      }
      return initials;
    },
    setRating() {
      for (let i = 1; i - 1 < this.product.rating; i++) {
        this.rating.push(`star${i}`);
      }
    },
    rateItem(event) {
      this.userRating = +event.target.id;
    },
    countEmptyStars() {
      for (let i = 1; i - 1 < 5 - this.product.rating; i++) {
        this.emptyStars.push(`star${i}`);
      }
    },
    getCategories() {
      for (let cat of this.product.categories) {
        console.log(cat);
        cat.translations = JSON.parse(cat.translations);
        console.log(cat.translations);
        this.categories.push(cat);
      }
    },

    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    },

  },
  beforeMount() {
    // Set currancy
    this.setCurrency();
  },

  mounted() {

    //Set rating
    this.setRating();
    this.countEmptyStars();

    //Convert product.categories to String
    // this.categoriesToString();
    this.getCategories();
    //Set cart
    this.setCart();
    // Set currancy
    // this.setCurrency();
    // Add this product
    this.createCartItem();

    this.setIndex();
    this.setQuantity();
    this.item = this.cart.items[this.productIndex];

    this.checkIfInCart();

    // Set default image
    this.setDefaultImage();

    // Set product versions
    this.setVersions();

    this.EventBus.$on("bundles-updated", updatedList => {
      this.cart.bundles = updatedList;
    });

    $(".bundles__container").owlCarousel({
      items: 1,
      nav: true,
      dots: true,
      autoplay: true,
      autoplayTimeout: 20000,
      rewind: true
    });
    this.currentUrl = window.location.href;

    window.addEventListener('load', () => {

        this.loaded = true;

    });

  }
};
</script>

<style lang="sass" scoped>

  

.product-page__description
  justify-content: center

  .product-page__galery
    position: relative

  .product-page__galery .featured__promo-flags-container
    position: absolute
    left: 145px
    top: 30px
    width: 72px
    z-index: 10
    @media (max-width: 575px)
      left: 15px
			
    @media (max-width: 425px)
      top: 20px
      width: 60px

  .product-page__galery .featured__promo-flags-container .onnew,
  .product-page__galery .featured__promo-flags-container .onsale
    width: 100%
    height: 34px
    text-align: center
    padding-top: 5px
    margin-bottom: calc( 34px / 4 )
    @media (max-width: 575px)
			

    @media (max-width: 425px)
      height: 25px
      padding-top: 0px
      margin-bottom: 5px
			
  .stars i
    pointer-events: none


  .stars
    display: flex
    position: relative

  .star-rating
    display: flex
	
  .stars__checkbox
    position: absolute
    left: 0
    top: 0
    opacity: 0
    width: 15px
    pointer-events: none

  .userPic
    width: 81px
    height: 81px
    float: left
    background: lighten(gray, 45%)
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    font-size: 25px
    margin-top: 8px
  

</style>