var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-header-cart" }, [
    _c("div", { staticClass: "cart-contents" }, [
      _c("img", {
        attrs: { src: "/images/icons/header-icon-2.png", alt: "cart" },
      }),
      _vm._v(" "),
      _vm.cart.productsCount
        ? _c("span", { staticClass: "cartAmount" }, [
            _vm._v(_vm._s(this.cart.productsCount)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "widget_shopping_cart" }, [
      _c("div", { staticClass: "widget_shopping_cart_content" }, [
        _c(
          "ul",
          {
            staticClass: "woocommerce-mini-cart cart_list product_list_widget",
          },
          [
            _vm._l(_vm.cart.items, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass:
                    "woocommerce-mini-cart-item mini_cart_item clearfix",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "product-image",
                      attrs: {
                        href:
                          _vm.buildUrl(_vm.applicationParams.locale) +
                          item.slug,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.imageUrl
                            ? item.imageUrl
                            : _vm.defaultImage.url,
                          alt: item.title,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "product-title",
                      attrs: {
                        href:
                          _vm.buildUrl(_vm.applicationParams.locale) +
                          item.slug,
                      },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "quantity" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(item.quantity) +
                        " ×\n            "
                    ),
                    _c(
                      "span",
                      { staticClass: "woocommerce-Price-amount amount" },
                      [
                        _c(
                          "span",
                          { staticClass: "woocommerce-Price-currencySymbol" },
                          [_vm._v(_vm._s(_vm.activeCurrency.code))]
                        ),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.countCost(item)) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeItem(index)
                        },
                      },
                    },
                    [_c("span", { staticClass: "lnr lnr-cross" })]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.cart.bundles, function (bundle, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cart.bundles,
                      expression: "cart.bundles",
                    },
                  ],
                  key: index + "bundle",
                  staticClass:
                    "woocommerce-mini-cart-item mini_cart_item clearfix",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "product-image",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: bundle.items[0].product.images[0].url
                            ? bundle.items[0].product.images[0].url
                            : _vm.defaultImage.url,
                          alt: bundle.items[0].product.images[0].alt
                            ? bundle.items[0].product.images[0].alt
                            : _vm.defaultImage.alt,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "product-title",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [_vm._v(_vm._s(bundle.title))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "quantity" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(bundle.quantity) +
                        " ×\n            "
                    ),
                    _c(
                      "span",
                      { staticClass: "woocommerce-Price-amount amount" },
                      [
                        _c(
                          "span",
                          { staticClass: "woocommerce-Price-currencySymbol" },
                          [_vm._v(_vm._s(_vm.activeCurrency.code))]
                        ),
                        _vm._v(
                          "\n              " +
                            _vm._s(bundle.total) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeBundle(index)
                        },
                      },
                    },
                    [_c("span", { staticClass: "lnr lnr-cross" })]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.cart.items.length
          ? _c("p", { staticClass: "woocommerce-mini-cart__total total" }, [
              _c("span", [_vm._v("Subtotal:")]),
              _vm._v(" "),
              _c("span", { staticClass: "woocommerce-Price-amount amount" }, [
                _c(
                  "span",
                  { staticClass: "woocommerce-Price-currencySymbol" },
                  [_vm._v(_vm._s(_vm.activeCurrency.code))]
                ),
                _vm._v(
                  "\n          " + _vm._s(_vm.cart.subtotal) + "\n        "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "woocommerce-mini-cart__buttons buttons" }, [
          _c(
            "a",
            {
              staticClass: "button wc-forward au-btn btn-small",
              attrs: {
                href:
                  _vm.applicationParams.locale === "en" ? "/en/cart" : "/cart",
              },
            },
            [_vm._v("View Cart")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "button checkout wc-forward au-btn au-btn-black btn-small",
              attrs: {
                href:
                  _vm.applicationParams.locale === "en"
                    ? "/en/checkout"
                    : "/checkout",
              },
            },
            [_vm._v("Checkout")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }