var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-instagram", {
    attrs: {
      token: "accessTokenHere",
      count: 5,
      tags: ["hashtag1", "hashtag2"],
      mediaType: "image",
    },
    scopedSlots: _vm._u([
      {
        key: "feeds",
        fn: function (props) {
          return [
            _c("li", { staticClass: "fancy-list" }, [
              _vm._v(_vm._s(props.feed.link)),
            ]),
          ]
        },
      },
      {
        key: "error",
        fn: function (props) {
          return [
            _c("div", { staticClass: "fancy-alert" }, [
              _vm._v(_vm._s(props.error.error_message)),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }