<template>
  <header class="header">
    <!-- Show Desktop Header -->
    <div class="show-desktop-header header-hp-1 style-header-hp-1">
      <div id="js-navbar-fixed" class="menu-desktop">
        <div class="container-fluid">
          <div class="menu-desktop-inner">
            <!-- Logo -->
            <div class="logo">
              <a :href="applicationParams.locale === 'ru' ? '/' : '/en'">
                <img :src="applicationParams.siteLogo" alt="logo" />
              </a>
            </div>
            <!-- Main Menu -->
            <nav class="main-menu">
              <ul>
                <li :key="index + menu.title" v-for="(menu, index) in menu" class="menu-item">
                  <a
                    :href="menu.url"
                    class="main-menu__main-link"
                    :class="{'current' : ifActive(menu.url)}"
                  >{{ menu.title }}</a>
                  <ul v-if="menu.children.length" class="sub-menu">
                    <li :key="index + subMenu.title" v-for="(subMenu, index) in menu.children">
                      <a :href="subMenu.url">{{ subMenu.title }}</a>
                      <ul v-if="subMenu.children" class="sub-menu menu-levels">
                        <li
                          :key="index + subMenu.children"
                          v-for="(subMenu1, index) in subMenu.children"
                        >
                          <a :href="subMenu1.url">{{ subMenu1.title }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <a href="/profile/edit">Profile</a>
                  <ul class="sub-menu">
                    <li class="drop">
                      <a class="drop-link" href="/en/wishlist">Wishlist</a>
                    </li>
                    <form
                      v-if="applicationParams.user"
                      action="/logout"
                      name="logout"
                      method="post"
                    >
                      <input class="hide-submit" type="submit" />
                      <a @click.prevent="logOut" href="#">Sign out</a>
                    </form>

                    <li v-if="!applicationParams.user">
                      <a href="/en/login">Login/Register</a>
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <a href="/" class="main-menu__main-link">Укр.</a>
                </li>
              </ul>
            </nav>
            <!-- Header Right -->
            <div class="header-right">
              <ul class="main-menu main-menu--right">
                <!--<li class="menu-item menu-item--right">
                  <a @click.prevent href="#" class="current">{{ activeCurrency.code }}</a>
                  <ul class="sub-menu">
                    <li
                      @click="changeCurrency(cur)"
                      v-show="cur.code !== activeCurrency.code"
                      :key="index + cur.code"
                      v-for="(cur, index) in currencies"
                    >
                      <a @click.prevent href="#">{{ cur.code }}</a>
                    </li>
                  </ul>
                </li>-->
                <li class="menu-item menu-item--right">
                  <a @click.prevent href="#" class="current">{{ activeLang.title }}</a>
                  <ul class="sub-menu">
                    <li
                      v-show="lang !== activeLang"
                      :key="index + lang.title"
                      v-for="(lang, index) in langList"
                    >
                      <a :href="lang.url">{{ lang.title }}</a>
                    </li>
                  </ul>
                </li>
              </ul>

              <!-- Search -->
              <div class="search-btn-wrap">
                <button class="search-btn" data-toggle="modal" data-target="#searchModal">
                  <img src="/images/icons/header-icon-1.png" alt="search" />
                </button>
              </div>
              <!-- Cart -->
              <DropDownCartEn></DropDownCartEn>
              <!-- Canvas -->
              <div class="canvas canvas-btn-wrap">
                <button
                  class="canvas-/images canvas-btn"
                  data-toggle="modal"
                  data-target="#canvasModal"
                >
                  <img src="/images/icons/header-icon-3.png" alt="canvas" />
                </button>
              </div>
            </div>
          </div>
          <!-- SEARCH MODAL-->
          <Search></Search>
          <!-- END SEARCH MODAL-->

          <!-- CANVAS MODAL-->
          <div class="modal fade" id="canvasModal" role="dialog">
            <button class="close" type="button" data-dismiss="modal">
              <i class="zmdi zmdi-close"></i>
            </button>
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="canvas-content">
                    <div class="logo">
                      <a href="index1.html">
                        <img src="/images/icons/logo-black.png" alt="logo" />
                      </a>
                    </div>
                    <div class="insta">
                      <div class="insta-inner">
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-1.jpg" alt="insta" />
                          </a>
                        </div>
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-2.jpg" alt="insta" />
                          </a>
                        </div>
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-3.jpg" alt="insta" />
                          </a>
                        </div>
                      </div>
                      <div class="insta-inner">
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-4.jpg" alt="insta" />
                          </a>
                        </div>
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-5.jpg" alt="insta" />
                          </a>
                        </div>
                        <div class="/images">
                          <a href="#" class="/images-inner">
                            <div class="overlay"></div>
                            <img src="/images/hp-1-canvas-6.jpg" alt="insta" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="contact">
                      <h4>Contact</h4>
                      <div class="contact-inner">
                        <i class="zmdi zmdi-map"></i>
                        <div class="contact-info">
                          <span>No 40 Baria Sreet 133/2</span>
                        </div>
                      </div>
                      <div class="contact-inner">
                        <i class="zmdi zmdi-phone"></i>
                        <div class="contact-info">
                          <span>
                            <a href="tel:15618003666666">+ (156) 1800-366-6666</a>
                          </span>
                        </div>
                      </div>
                      <div class="contact-inner">
                        <i class="zmdi zmdi-email"></i>
                        <div class="contact-info">
                          <span>Eric-82@example.com</span>
                        </div>
                      </div>
                      <div class="contact-inner">
                        <i class="zmdi zmdi-globe"></i>
                        <div class="contact-info">
                          <span>www.novas.com</span>
                        </div>
                      </div>
                    </div>
                    <div class="email">
                      <div class="send">
                        <i class="zmdi zmdi-mail-send"></i>
                      </div>
                      <input
                        type="email"
                        required
                        pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                        name="email"
                        placeholder="Your e-mail..."
                      />
                    </div>
                    <div class="socials">
                      <a href="#">
                        <i class="zmdi zmdi-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="zmdi zmdi-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="zmdi zmdi-tumblr"></i>
                      </a>
                      <a href="#">
                        <i class="zmdi zmdi-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END CANVAS MODAL-->
        </div>
      </div>
    </div>
    <!-- Show Mobile Header -->
    <div id="js-navbar-mb-fixed" class="show-mobile-header">
      <!-- Logo And Hamburger Button -->
      <div class="mobile-top-header">
        <div class="logo-mobile">
          <a href="/en">
            <img :src="applicationParams.siteLogo" alt="logo" />
          </a>
        </div>
        <div class="hamburger-container">
          <button class="hamburger hamburger--spin hidden-tablet-landscape-up" id="toggle-icon">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
          <a href="https://fotofonu.com/en/cart">
            <div class="cart-contents padding-10">
              <img src="/images/icons/header-icon-2.png" alt="cart" />
              <span v-if="cart.productsCount" class="cartAmount">{{ this.cart.productsCount }}</span>
            </div>
          </a>
        </div>
      </div>
      <!-- Au Navbar Menu -->
      <div class="au-navbar-mobile navbar-mobile-1">
        <div class="au-navbar-menu">
          <ul>
            <!-- <li :key="index + menu.title + 'mobile'" v-for="(menu, index) in menu" class="drop">
              <a class="drop-link" :href="menu.children.length ? '#' : menu.url">
                {{ menu.title }}
                <span v-if="menu.children.length" class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul v-if="menu.children.length" class="drop-menu bottom-right">
                <li
                  :key="index + subMenu.title + 'mobile'"
                  v-for="(subMenu, index) in menu.children"
                  class="drop"
                >
                  <a class="drop-link" :href="subMenu.children ? '#' : menu.url">
                    {{ subMenu.title }}
                    <span v-if="subMenu.children" class="arrow">
                      <i class="zmdi zmdi-chevron-down"></i>
                    </span>
                  </a>
                  <ul v-if="subMenu.children" class="drop-menu bottom-right">
                    <li
                      :key="index + subMenu.children + 'mobile'"
                      v-for="(subMenu1, index) in subMenu.children"
                    >
                      <a class="drop-menu-inner" :href="subMenu1.url">{{ subMenu1.title }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>-->

            <li class="drop">
              <a class="drop-link" :class="{'current' : ifActive(menu.url)}">
                {{ menu[0].title }}
                <span class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul v-if="menu[0].children.length" class="drop-menu bottom-right">
                <li
                  :key="index + subMenu.title"
                  v-for="(subMenu, index) in menu[0].children"
                  class="drop"
                >
                  <a v-if="subMenu.children.length" class="drop-link">
                    {{ subMenu.title }}
                    <span class="arrow">
                      <i class="zmdi zmdi-chevron-down"></i>
                    </span>
                  </a>

                  <a v-else :href="subMenu.url" class="drop-link">{{ subMenu.title }}</a>

                  <ul v-if="subMenu.children" class="drop-menu bottom-right">
                    <li
                      :key="index + subMenu.children"
                      v-for="(subMenu1, index) in subMenu.children"
                    >
                      <a :href="subMenu1.url" class="bottom-right-plus">{{ subMenu1.title }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li class="drop">
              <a class="drop-link" @click.prevent href="'#'">
                Profile
                <span class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul class="drop-menu bottom-right">
                <li class="drop">
                  <a class="drop-link" href="/profile/edit">Edit profile</a>
                </li>
                <li class="drop">
                  <a class="drop-link" href="/en/wishlist">Wishlist</a>
                </li>
                <li class="drop" v-if="applicationParams.user">
                  <a @click.prevent="logOut" class="drop-link" href="#">Sign out</a>
                </li>

                <li class="drop" v-if="!applicationParams.user">
                  <a class="drop-link" href="/en/login">Login/Register</a>
                </li>
              </ul>
            </li>
            <li class="drop">
              <a class="drop-link" href="#">
                Cart
                <span class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul class="drop-menu bottom-right">
                <li class="drop">
                  <a class="drop-link" href="/en/cart">View cart</a>
                </li>

                <li class="drop">
                  <a class="drop-link" href="/en/checkout">Checkout</a>
                </li>
              </ul>
            </li>
            <!--<li class="drop">
              <a class="drop-link" href="#">
                {{ activeCurrency.code }}
                <span class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul class="drop-menu bottom-right">
                <li
                  v-show="cur.code !== activeCurrency.code"
                  :key="index + cur.code"
                  v-for="(cur, index) in currencies"
                  class="drop"
                >
                  <a class="drop-link" @click="changeCurrency(cur)" href="#">{{ cur.code }}</a>
                </li>
              </ul>
            </li>-->
            <li class="drop">
              <a class="drop-link" href="#">
                {{ activeLang.title }}
                <span class="arrow">
                  <i class="zmdi zmdi-chevron-down"></i>
                </span>
              </a>
              <ul class="drop-menu bottom-right">
                <li
                  v-show="lang !== activeLang"
                  :key="index + lang.title"
                  v-for="(lang, index) in langList"
                  class="drop"
                >
                  <a class="drop-link" :href="lang.url">{{ lang.title }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
//Import the data for the cart
import { cartModel, itemModel } from "../static/cart/cart";
//Library for sending requests
import axios from "axios";

import EventBus from "../EventBus";

export default {
  name: "CommonHeaderEn",
  props: {
    menu: {
      type: Array
    }
  },
  data() {
    return {
      search: {
        active: false,
        query: "",
        results: [],
        defaultImage: "//images/default/product/posebanpoklon_product.jpg"
      },
      cart: {},
      langList: [
        { title: "EN / USD", url: "/en" },
        { title: "УКР / ГРН", url: "/" }
      ],
      currencies: [],
      activeCurrency: {},
      activeLang: {}
    };
  },
  watch: {
    activeCurrency: function() {
      this.reCalculateCart();
    }
  },
  methods: {
    ifActive(menuUrl) {
      let active = false;

      if (
        menuUrl + "/" === window.location.href ||
        menuUrl === window.location.href
      ) {
        active = true;
      }

      return active;
    },
    logOut() {
      document.forms.logout[0].click();
    },
    setLang() {
      if (applicationParams.locale === "ru") {
        this.langList = [
          { title: "УКР / ГРН", url: "/" },
          { title: "EN / USD", url: "/en" }
        ];
      } else {
        this.langList = [
          { title: "EN / USD", url: "/en" },
          { title: "УКР / ГРН", url: "/" }
        ];
      }
    },
    setCart() {
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.setNewCart();
      }
    },
    setNewCart() {
      this.cart = Object.assign({}, cartModel);

      this.cart.items = [];

      this.cart.bundles = [];

      this.cart.currency = this.applicationParams.currency;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    saveCart() {
      //Save a cart to the localStorage
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    reCalculateCart() {
      let requestUrl = this.API.cart.calculate;
      let cart = this.cart;
      cart.currency_code = this.activeCurrency.code;

      let requestParams = cart;

      axios.post(requestUrl, requestParams).then(response => {
        this.cart = Object.assign({}, this.cart, response.data);
        this.EventBus.$emit("cart-recalculated", this.cart);
        this.saveCart();
        //location.reload();
      });
    },
    getCurrencies() {
      this.currencies = applicationParams.currencies;
      this.activeCurrency = this.currencies[0];
    },

    changeLang(lang) {
      this.activeLang = lang;
      if (lang.title.indexOf("RU") !== -1) {
        let selectedCurrency = {
          code: "RUB",
          id: 2
        };

        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(selectedCurrency)
        );

        setTimeout(() => {
          window.location.pathname = "/";
        }, 0);
      } else {
        let selectedCurrency = {
          code: "USD",
          id: 3
        };

        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(selectedCurrency)
        );

        setTimeout(() => {
          window.location.pathname = "/en";
        }, 0);
      }
    },

    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        let currency = JSON.parse(localStorage.getItem("selectedCurrency"));
        const existing = applicationParams.currencies.find(item => {
          return item.code === currency.code;
        });

        if (existing !== undefined) {
          this.activeCurrency = currency;
        } else {
          this.activeCurrency = applicationParams.currency;
        }
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },

    changeCurrency(cur) {
      this.activeCurrency = cur;

      let selectedCurrency = {
        code: cur.code,
        id: cur.id
      };

      localStorage.setItem(
        "selectedCurrency",
        JSON.stringify(selectedCurrency)
      );

      this.reCalculateCart();
    },

    searchItems() {
      let requestUrl = this.API.product.list;

      let requestParams = {
        search: this.search.query
      };

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.search.results = response.data.data;
      });
    },
    productUrl(slug) {
      return this.API.product.show + slug;
    }
  },
  mounted() {
    // Set language
    this.setLang();
    // Load currency list
    this.getCurrencies();
    // Set currancy
    this.setCurrency();
    //Set the cart data
    this.setCart();

    // Set language
    this.activeLang = this.langList[0];

    //Receive data form an add to cart button
    this.EventBus.$on("headerCartUpdated", data => {
      this.cart = data;
    });
  }
};
</script>

<style lang='scss' scoped>
.hide-submit {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.lang-switch-select,
.currency-switch-select {
  border: none;
}

.lang-switch-select option:focus,
.currency-switch-select option:focus {
  border: none !important;
  outline: none !important;
}

.main-menu--right .current::before {
  display: none;
}

.main-menu--right .sub-menu {
  // width: auto !important;
  width: 200% !important;
  left: 0 !important;
}

.main-menu__main-link::before {
  display: block;
}
.bottom-right-plus {
  padding: 13px 75px !important;
}
</style>