<template>
  <div class="related" v-show="products.length">
    <h2 class="special-heading">{{ title }} Products</h2>
    <div class="owl-carousel owl-theme abr-related-products" id="related-products">
      <div class="item" :key="index" v-for="(product, index) in products">
        <div class="product type-product">
          <div class="woocommerce-LoopProduct-link">
            <div class="product-image">
              <a :href="buildUrl(applicationParams.locale) + product.slug" class="wp-post-image">
                <img
                  v-bind:src="product.images.length ? product.images[0].url : defaultImage.url"
                  v-bind:alt="product.images.length ? product.images[0].alt : defaultImage.alt"
                />
              </a>
              <div class="featured__promo-flags-container">
                <a v-if="product.promo" :href="API.category.promo" class="onsale">SALE</a>
                <a v-if="product.new" :href="API.category.new" class="onnew">NEW</a>
              </div>
              <div class="yith-wcwl-add-button show">
                <a
                  @click.prevent="addToWishList(product.id, product)"
                  href="#"
                  class="add_to_wishlist"
                >
                  <i
                    class="zmdi favourite-icon zmdi-favorite-outline"
                    :class="{ 'zmdi-favorite' : product.in_wishlist }"
                  ></i>
                </a>
              </div>
              <div class="product-text">
                <div @click.prevent="addToCart(product)" style="filter: none" class="button add_to_cart_button add_to_cart_button--product-row">
                  <a href="#">
                    <img
                      class="product-row__img"
                      src="/images/icons/shopping-cart-black-icon.png"
                      alt="cart"
                    />
                  </a>
                </div>
                <h5 class="woocommerce-loop-product__title">
                  <a :href="buildUrl(applicationParams.locale) + product.slug">{{ product.title }}</a>
                </h5>
                <span class="price active-color">
                  <div :class="{'crossed-out' : product.prices['RUB'].sale_price }">
                    <span class="woocommerce-Price-amount amount">
                      <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                      {{ showItemPrice(product) }}
                    </span>
                  </div>
                  <ins v-if="product.prices['RUB'].sale_price">
                    <span class="woocommerce-Price-amount amount">
                      <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                      {{ showItemPrice(product, true) }}
                    </span>
                  </ins>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import EventBus from "../../EventBus";

//Library for working with requests
import axios from "axios";

export default {
  name: "Productrow",
  props: {
    categoryId: {
      type: String,
      default: "1"
    },
    title: {
      type: String,
      default: "Related Products"
    },
    productId: {
      type: Number,
      default: null
    },
    loadRelated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeCurrency: {},
      productPriceCode: "",
      defaultImage: {
        url: "",
        alt: "Default image"
      },
      products: []
    };
  },
  methods: {
    showItemPrice(product, sale) {
      if (!this.activeCurrency.code) {
        this.setCurrency();
      }
      
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
      }

      if (product.version_items) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },

    productPrice(product) {
      let priceStr = "";
      let code = this.productPriceCode;

      if (this.productPriceCode) {
        priceStr = product.prices[this.productPriceCode].price;
      }

      return priceStr;
    },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
      let index = 0;
      for (let cur in this.products[0].prices) {
        if (cur === this.activeCurrency.code) {
          index = cur;
          break;
        }
        index++;
      }

      this.productPriceCode = index;
    },
    addToWishList(ProdId, product) {

      if (this.applicationParams.user) {
        if (!product.in_wishlist) {
          product.in_wishlist = true;
          let requestUrl =
            this.API.wishlist.update + applicationParams.user.wishlist.id;

          let requestParams = {
            products: ProdId
          };

          axios.put(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Товар был добавлен в корзину."
          );
        } else {
          product.in_wishlist = false;
          let requestUrl =
            this.API.wishlist.update +
            applicationParams.user.wishlist.id +
            "/remove";

          let requestParams = {
            products: [ProdId]
          };

          axios.post(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Product has been removed from wishlist."
          );
        }
      } else {
        this.showNotification("error", "Please log in to your account.");
      }
    },
    productUrl(slug) {
      return buildUrl(applicationParams.locale) + slug;
    },
    addToCart(product) {
      EventBus.$emit("add-to-cart", product);
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    },
    getProducts(){

        let requestUrl = '/products/bestseller';

        let requestParams = {};

        console.log(this.productId);
        console.log(this.loadRelated);

        if(this.productId !== null && this.loadRelated){

            requestUrl = '/products/related';

            requestParams.product_id = this.productId;

        }

        axios.get(requestUrl, {params: requestParams}).then(response => {

            if(response.status === 200){

                if(response.data !== undefined && response.data !== null){

                    this.products = response.data;

                    this.setDefaultImage();
                    // Set currancy
                    this.setCurrency();

                }

                setTimeout(() => {

                    /* Shop Single v1*/
                    $('.abr-related-products').owlCarousel({
                        items:4,
                        loop: false,
                        margin: 30,
                        nav:true,
                        navText: [
                            "<i class='zmdi zmdi-chevron-left'></i>",
                            "<i class='zmdi zmdi-chevron-right'></i>"],
                        slideSpeed: 300,
                        panigationSpeed: 400,
                        responsiveClass:true,
                        responsive:{
                            0:{
                                items:1,
                                nav:false
                            },
                            576:{
                                items:2

                            },
                            768:{
                                items:3

                            },
                            1200:{
                                items:4
                            }
                        }
                    });

                }, 1000);

            }

        }).catch(error => {

            console.log(error.response);

        });

    },
  },

  mounted() {
    //Load products
    this.getProducts();
  }
};
</script>

<style lang="sass" scoped>
  .add_to_cart_button--product-row:hover img
    filter: invert(0%) !important
    
</style>