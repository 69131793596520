var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.text,
          expression: "text",
        },
      ],
      staticClass: "item-comment-message",
      attrs: {
        cols: _vm.cols,
        rows: _vm.rows,
        maxlength: _vm.limit,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.text },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.text = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "item-comment-message-count" }, [
      _vm._v(_vm._s(_vm.charactersTyped) + "/" + _vm._s(_vm.maxCharacters)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }