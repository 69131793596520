var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-lg-9 col-md-9 profile-content" }, [
    _c("div", { staticClass: "profile-information-form" }, [
      _c("h4", { staticClass: "information-form-title" }, [
        _vm._v("\n            Personal information\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "information-form-container form-top" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.name,
              expression: "user.name",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: { type: "text", placeholder: "Name", autocomplete: "off" },
          domProps: { value: _vm.user.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.user, "name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.validateField("user", "name"),
                expression: "validateField('user', 'name')",
              },
            ],
            staticClass: "text-danger text-xs",
          },
          [_vm._v("This field is invalid")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.username,
              expression: "user.username",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: { type: "text", placeholder: "Username", autocomplete: "off" },
          domProps: { value: _vm.user.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.user, "username", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.email,
              expression: "user.email",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: { type: "email", placeholder: "Email", autocomplete: "off" },
          domProps: { value: _vm.user.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.user, "email", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.validateField("user", "email"),
                expression: "validateField('user', 'email')",
              },
            ],
            staticClass: "text-danger text-xs",
          },
          [_vm._v("This field is invalid")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.phone_number,
              expression: "user.phone_number",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: { type: "tel", placeholder: "Number", autocomplete: "off" },
          domProps: { value: _vm.user.phone_number },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.user, "phone_number", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "information-form-title" }, [
        _vm._v("\n            Change password\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "information-form-container form-bottom" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordForm.newPassword,
              expression: "passwordForm.newPassword",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: {
            type: "password",
            placeholder: "New password",
            autocomplete: "off",
          },
          domProps: { value: _vm.passwordForm.newPassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.passwordForm, "newPassword", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordForm.confirmPassword,
              expression: "passwordForm.confirmPassword",
            },
          ],
          staticClass: "information-form-input input-col",
          attrs: {
            type: "password",
            placeholder: "Confirm password",
            autocomplete: "off",
          },
          domProps: { value: _vm.passwordForm.confirmPassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.passwordForm, "confirmPassword", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "information-form-btn",
            on: { click: _vm.submitForm },
          },
          [_vm._v("Save")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }