<template>
  <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
    <div class="content-area">
      <div class="storefront-sorting">
        <p
          v-if="products.length"
          class="woocommerce-result-count"
        >Showing {{this.productsOnPage.firstProduct}} – {{this.productsOnPage.lastProduct}} of {{this.totalProducts}} results</p>
        <form v-if="products.length" class="woocommerce-ordering" method="get">
          <select v-model="filter.sort" name="orderby" class="orderby">
            <option
              :key="index"
              v-for="(option, index) in sortOptions"
              :value="option"
            >{{ option.label }}</option>
          </select>
          <span>
            <i class="zmdi zmdi-chevron-down"></i>
          </span>
        </form>
      </div>
      <div v-for="(row, index) in productRows" :key="index" class="row">
        <!-- Product 1 -->
        <div v-for="(product, index) in row" :key="index" class="col">
          <div class="product type-product">
            <div class="woocommerce-LoopProduct-link">
              <div class="product-image">
                <a :href="buildUrl(applicationParams.locale) + product.slug" class="wp-post-image">
                  <img
                    class="image-cover"
                    v-bind:src="product.images.length ? product.images[0].url : defaultImage.url"
                    v-bind:alt="product.images.length ? product.images[0].alt : defaultImage.alt"
                  />
                  <img
                    v-if="product.images.length > 1"
                    class="image-secondary"
                    v-bind:src="product.images.length ? product.images[1].url : defaultImage.url"
                    v-bind:alt="product.images.length ? product.images[1].alt : defaultImage.alt"
                  />
                </a>
                <div class="featured__promo-flags-container">
                  <a v-if="product.promo" :href="API.category.promo" class="onsale">SALE</a>
                  <a v-if="product.new" :href="API.category.new" class="onnew">NEW</a>
                </div>
                <div class="yith-wcwl-add-button show">
                  <a
                    @click.prevent="addToWishList(product.id, product)"
                    href="#"
                    class="add_to_wishlist"
                  >
                    <i
                      class="zmdi favourite-icon zmdi-favorite-outline"
                      :class="{ 'zmdi-favorite' : product.in_wishlist }"
                    ></i>
                  </a>
                </div>
                <div class="product-text">
                  <div @click.prevent="addToCart(product)" class="button add_to_cart_button">
                    <a :href="buildUrl(applicationParams.locale) + product.slug">
                      <img src="/images/icons/shopping-cart-black-icon.png" alt="cart" />
                    </a>
                  </div>
                  <h5 class="woocommerce-loop-product__title">
                    <a :href="buildUrl(applicationParams.locale) + product.slug">{{ product.title }}</a>
                  </h5>
                  <span class="price active-color">
                    <div :class="{'crossed-out' : product.prices['RUB'].sale_price }">
                      <span class="woocommerce-Price-amount amount">
                        <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                        {{ showItemPrice(product) }}
                      </span>
                    </div>
                    <ins v-if="product.prices['RUB'].sale_price">
                      <span class="woocommerce-Price-amount amount">
                        <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                        {{ showItemPrice(product, true) }}
                      </span>
                    </ins>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pagination.lastPage > 1" class="navigation pagination">
      <div class="page-numbers">
        <a
          v-if="1 < pagination.currentPage - 1"
          @click.prevent="changePage(pagination.firstPage)"
          href="#"
          class="page-numbers"
        >
          <span>
            <i class="zmdi zmdi-chevron-left"></i>
          </span>
        </a>
        <a
          href="#"
          @click.prevent="changePage(pagination.prevPage - 1)"
          v-if="(pagination.prevPage - 1) >= 1"
          class="page-numbers"
        >
          <span>{{ pagination.prevPage - 1 }}</span>
        </a>
        <a
          href="#"
          @click.prevent="changePage(pagination.prevPage)"
          v-if="pagination.prevPage !== pagination.currentPage"
          class="page-numbers"
        >
          <span>{{ pagination.prevPage }}</span>
        </a>
        <a href="#" class="page-numbers current">
          <span>{{ pagination.currentPage }}</span>
        </a>
        <a
          href="#"
          @click.prevent="changePage(pagination.nextPage)"
          v-if="pagination.nextPage !== pagination.currentPage"
          class="page-numbers"
        >
          <span>{{ pagination.nextPage }}</span>
        </a>
        <a
          href="#"
          @click.prevent="changePage(pagination.nextPage + 1)"
          v-if="(pagination.nextPage + 1) <= pagination.lastPage"
          class="page-numbers"
        >
          <span>{{ pagination.nextPage + 1 }}</span>
        </a>
        <a href="#" v-if="(pagination.nextPage + 1) < pagination.lastPage" class="page-more">
          <span>...</span>
        </a>
        <a
          v-if="pagination.lastPage > pagination.currentPage + 1"
          @click.prevent="changePage(pagination.lastPage)"
          href="#"
          class="page-numbers"
        >
          <span>
            <i class="zmdi zmdi-chevron-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div v-if="!products.length" class="no-match" :class="{'op-0' : noProducts}">
      <h3 class="no-match__text">Sorry, there are no products matching your search</h3>
    </div>
  </div>
</template>

<script>
//Library for working with requests
import axios from "axios";

import EventBus from "../../EventBus";

export default {
  name: "ProductgridEn",
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    perPage: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      products: [],
      productRows: [],
      pagination: {
        links: [],
        suffix: "?page=",
        currentPage: 1,
        lastPage: 1,
        prevPage: 1,
        nextPage: 1
      },
      priceRange: {
        priceMin: "",
        priceMax: ""
      },
      search: "",
      activeCurrency: {},
      totalProducts: 0,
      noProducts: false,
      firstUpdate: true,
      updatePriceRange: true,
      sortOptions: [
        {
          label: "Newest products first",
          key: "created_at",
          order: "desc"
        },
        {
          label: "Oldest products first",
          key: "created_at",
          order: "asc"
        },
        {
          label: "Cheapest products first",
          key: "price",
          order: "asc"
        },
        {
          label: "Most expensive products first",
          key: "price",
          order: "desc"
        },
        {
          label: "Title",
          key: "title",
          order: "asc"
        }
      ],
      filter: {
        categoryId: "",
        sort: {},
        location: "",
        visitorsNumber: "",
        locationOptions: [],
        visitorsNumberOptions: []
      },
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      },
      selectedValues: []
    };
  },
  watch: {
    "filter.sort": function(oldValue, newValue) {
      //Load sorted products
      this.getProducts();
    },
    "filter.location": function(oldValue, newValue) {
      //Load filtered products
      this.getProducts();
    },
    "filter.visitorsNumber": function(oldValue, newValue) {
      //Load filtered products
      this.getProducts();
    }
  },

  methods: {
    showItemPrice(product, sale) {
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
        console.log("salePrice: ", salePrice);
      }

      if (product.version_items.length) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },

    getCurrencies() {
      this.currencies = applicationParams.currencies;
      this.activeCurrency = this.currencies[0];
    },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    addToCart(product) {
      EventBus.$emit("add-to-cart", product);
      // EventBus.$emit("products-updated", this.cart.items);
    },
    addToWishList(ProdId, product) {
      if (this.applicationParams.user) {
        if (!product.in_wishlist) {
          product.in_wishlist = true;
          let requestUrl =
            this.API.wishlist.update + applicationParams.user.wishlist.id;

          let requestParams = {
            products: ProdId
          };

          axios.put(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Product has been added to wishlist."
          );
        } else {
          product.in_wishlist = false;
          let requestUrl =
            this.API.wishlist.update +
            applicationParams.user.wishlist.id +
            "/remove";

          let requestParams = {
            products: [ProdId]
          };

          axios.post(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Product has been removed from wishlist."
          );
        }
      } else {
        this.showNotification("error", "Please log in to your account.");
      }
    },

    getFilter() {
      let requestUrl = this.API.filter.list;

      axios.get(requestUrl).then(response => {
        this.filter.locationOptions = response.data.location.attributes;
        this.filter.visitorsNumberOptions =
          response.data.visitors_number.attributes;
      });
    },

    getProducts(page) {
      this.noProducts = true;
      let requestUrl = this.API.product.list;

      if (page !== undefined) {
        requestUrl += this.pagination.suffix + page;
      }

      let requestParams = {
        currency_code: this.activeCurrency.code,
        category_id: this.filter.categoryId,
        per_page: this.perPage,
        sort_key: this.filter.sort.key,
        sort_order: this.filter.sort.order,
        search: this.search,
        price_from: this.priceRange.priceMin,
        price_to: this.priceRange.priceMax,
        locale: this.applicationParams.locale
      };

      if(this.selectedValues.length){

          requestParams.option_values = this.selectedValues;

      }

      let selectedAttributes = this.assembleAttributes();

      if (selectedAttributes !== undefined) {
        requestParams.category_attributes = selectedAttributes;
      }

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.products = response.data.data;

        if (this.firstUpdate) {
          let priceRange = [response.data.min_price, response.data.max_price];
          EventBus.$emit("setPriceRange", priceRange);
        }

        this.firstUpdate = false;

        if (this.updatePriceRange) {
          this.priceRange.priceMin = response.data.min_price;
          this.priceRange.priceMax = response.data.max_price;
          this.updatePriceRange = false;
        }

        this.totalProducts = response.data.total;
        this.productRows = [];
        let rows = Math.ceil(this.products.length / 3);
        let sliceStart = 0;
        for (let i = 1; i <= rows; i++) {
          let newProductRow = this.products.slice(sliceStart, i * 3);
          sliceStart += 3;
          this.productRows.push(newProductRow);
        }

        this.setPagination(response.data.current_page, response.data.last_page);
        this.noProducts = false;
      });
    },
    assembleAttributes() {
      let attributes = [];

      if (this.filter.location !== "") {
        attributes.push(this.filter.location);
      }

      if (this.filter.visitorsNumber !== "") {
        attributes.push(this.filter.visitorsNumber);
      }

      if (attributes.length > 0) {
        return attributes;
      }
    },
    changePage(page) {
      //Load products from specific page
      this.getProducts(page);
    },
    setPagination(currentPage, lastPage) {
      //Import the pagination object
      let pagination = this.pagination;

      //Set pagination current page
      pagination.currentPage = currentPage;

      //Set pagination last page
      pagination.lastPage = lastPage;

      //Set pagination previous page
      if (currentPage > 1) {
        pagination.prevPage = currentPage - 1;
      } else {
        pagination.prevPage = currentPage;
      }

      //Set pagination next page
      if (currentPage < lastPage) {
        pagination.nextPage = currentPage + 1;
      } else {
        pagination.nextPage = currentPage;
      }

      //Clear the pagination links list
      pagination.links = [];

      //Populate the pagination links list
      for (let i = 1; i <= pagination.lastPage; i++) {
        pagination.links.push(i);
      }
    },
    setFilter() {
      //Get URL parameters for setting a filter
      let url = new URL(window.location.href);

      let location = url.searchParams.get("location");

      let visitorsNumber = url.searchParams.get("visitors_number");

      if (location !== null) {
        this.filter.location = location;
      }

      if (visitorsNumber !== null) {
        this.filter.visitorsNumber = visitorsNumber;
      }

      //Category
      if (this.categoryId !== null) {
        this.filter.categoryId = this.categoryId;
      }

      //Sort
      this.filter.sort = Object.assign({}, this.sortOptions[0]);
    },
    selectCategory(id) {
      //Change filter category
      this.filter.categoryId = id;

      //Load category products
      this.getProducts();
    },
    listenEvents() {
      this.EventBus.$on("category-selected", id => {
        this.selectCategory(id);
      });
      this.EventBus.$on("priceRangeUpdated", range => {
        this.priceRange.priceMin = `${range.priceMin}`;
        this.priceRange.priceMax = `${range.priceMax}`;
        this.getProducts();
      });
      this.EventBus.$on("selectedValues", values => {
          this.selectedValues = values;
          this.getProducts();
      });
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },
  computed: {
    productsOnPage() {
      return {
        firstProduct: 1 + this.perPage * (this.pagination.currentPage - 1),
        lastProduct:
          this.perPage * this.pagination.currentPage > this.totalProducts
            ? this.totalProducts
            : this.perPage * this.pagination.currentPage
      };
    }
  },
  mounted() {
    // Set currancy
    this.setCurrency();

    //Set default filter
    this.setFilter();

    //Get products
    this.getProducts();

    //Listen to events
    this.listenEvents();

    //Listen to category change
    EventBus.$on("categoryChanged", data => {
      this.updatePriceRange = true;
      this.filter.categoryId = data;
      this.firstUpdate = true;
      this.getProducts();
    });

    //Listen to search
    EventBus.$on("searchProducts", data => {
      this.search = data;
      this.getProducts();
    });

    this.getFilter();

    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="scss" scoped>
.no-match__text {
  margin-bottom: 40px;
}
.no-match {
  transition-property: opacity;
  transition-duration: 500ms;
}
.op-0 {
  opacity: 0;
}
</style>




