var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.products.length,
          expression: "products.length",
        },
      ],
      staticClass: "related",
    },
    [
      _c("h2", { staticClass: "special-heading" }, [
        _vm._v(_vm._s(_vm.title) + " Products"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "owl-carousel owl-theme abr-related-products",
          attrs: { id: "related-products" },
        },
        _vm._l(_vm.products, function (product, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "product type-product" }, [
              _c("div", { staticClass: "woocommerce-LoopProduct-link" }, [
                _c("div", { staticClass: "product-image" }, [
                  _c(
                    "a",
                    {
                      staticClass: "wp-post-image",
                      attrs: {
                        href:
                          _vm.buildUrl(_vm.applicationParams.locale) +
                          product.slug,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: product.images.length
                            ? product.images[0].url
                            : _vm.defaultImage.url,
                          alt: product.images.length
                            ? product.images[0].alt
                            : _vm.defaultImage.alt,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "featured__promo-flags-container" },
                    [
                      product.promo
                        ? _c(
                            "a",
                            {
                              staticClass: "onsale",
                              attrs: { href: _vm.API.category.promo },
                            },
                            [_vm._v("SALE")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      product.new
                        ? _c(
                            "a",
                            {
                              staticClass: "onnew",
                              attrs: { href: _vm.API.category.new },
                            },
                            [_vm._v("NEW")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "yith-wcwl-add-button show" }, [
                    _c(
                      "a",
                      {
                        staticClass: "add_to_wishlist",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addToWishList(product.id, product)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "zmdi favourite-icon zmdi-favorite-outline",
                          class: { "zmdi-favorite": product.in_wishlist },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "product-text" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "button add_to_cart_button add_to_cart_button--product-row",
                        staticStyle: { filter: "none" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addToCart(product)
                          },
                        },
                      },
                      [_vm._m(0, true)]
                    ),
                    _vm._v(" "),
                    _c(
                      "h5",
                      { staticClass: "woocommerce-loop-product__title" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.buildUrl(_vm.applicationParams.locale) +
                                product.slug,
                            },
                          },
                          [_vm._v(_vm._s(product.title))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "price active-color" }, [
                      _c(
                        "div",
                        {
                          class: {
                            "crossed-out": product.prices["RUB"].sale_price,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "woocommerce-Price-amount amount" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-currencySymbol",
                                },
                                [_vm._v(_vm._s(_vm.activeCurrency.code))]
                              ),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.showItemPrice(product)) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      product.prices["RUB"].sale_price
                        ? _c("ins", [
                            _c(
                              "span",
                              {
                                staticClass: "woocommerce-Price-amount amount",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "woocommerce-Price-currencySymbol",
                                  },
                                  [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                ),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.showItemPrice(product, true)) +
                                    "\n                  "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "product-row__img",
        attrs: {
          src: "/images/icons/shopping-cart-black-icon.png",
          alt: "cart",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }