var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12" },
    [
      _c(
        "div",
        { staticClass: "content-area" },
        [
          _c("div", { staticClass: "storefront-sorting" }, [
            _vm.products.length
              ? _c("p", { staticClass: "woocommerce-result-count" }, [
                  _vm._v(
                    "Показано " +
                      _vm._s(this.productsOnPage.firstProduct) +
                      " – " +
                      _vm._s(this.productsOnPage.lastProduct) +
                      " з " +
                      _vm._s(this.totalProducts) +
                      " продуктів"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.products.length
              ? _c(
                  "form",
                  {
                    staticClass: "woocommerce-ordering",
                    attrs: { method: "get" },
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter.sort,
                            expression: "filter.sort",
                          },
                        ],
                        staticClass: "orderby",
                        attrs: { name: "orderby" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filter,
                              "sort",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.sortOptions, function (option, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: option } },
                          [_vm._v(_vm._s(option.label))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._l(_vm.productRows, function (row, index) {
            return _c(
              "div",
              { key: index, staticClass: "row" },
              _vm._l(row, function (product, index) {
                return _c("div", { key: index, staticClass: "col" }, [
                  _c("div", { staticClass: "product type-product" }, [
                    _c("div", { staticClass: "woocommerce-LoopProduct-link" }, [
                      _c("div", { staticClass: "product-image" }, [
                        _c(
                          "a",
                          {
                            staticClass: "wp-post-image",
                            attrs: {
                              href:
                                _vm.buildUrl(_vm.applicationParams.locale) +
                                product.slug,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "image-cover",
                              attrs: {
                                src: product.images.length
                                  ? product.images[0].url
                                  : _vm.defaultImage.url,
                                alt: product.images.length
                                  ? product.images[0].alt
                                  : _vm.defaultImage.alt,
                              },
                            }),
                            _vm._v(" "),
                            product.images.length > 1
                              ? _c("img", {
                                  staticClass: "image-secondary",
                                  attrs: {
                                    src: product.images.length
                                      ? product.images[1].url
                                      : _vm.defaultImage.url,
                                    alt: product.images.length
                                      ? product.images[1].alt
                                      : _vm.defaultImage.alt,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "featured__promo-flags-container" },
                          [
                            product.promo
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "onsale",
                                    attrs: { href: _vm.API.category.promo },
                                  },
                                  [_vm._v("SALE")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            product.new
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "onnew",
                                    attrs: { href: _vm.API.category.new },
                                  },
                                  [_vm._v("NEW")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "yith-wcwl-add-button show" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "add_to_wishlist",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addToWishList(
                                      product.id,
                                      product
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "zmdi favourite-icon zmdi-favorite-outline",
                                  class: {
                                    "zmdi-favorite": product.in_wishlist,
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "product-text" }, [
                          _c(
                            "div",
                            {
                              staticClass: "button add_to_cart_button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addToCart(product)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.buildUrl(
                                        _vm.applicationParams.locale
                                      ) + product.slug,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/icons/shopping-cart-black-icon.png",
                                      alt: "cart",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h5",
                            { staticClass: "woocommerce-loop-product__title" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.buildUrl(
                                        _vm.applicationParams.locale
                                      ) + product.slug,
                                  },
                                },
                                [_vm._v(_vm._s(product.title))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "price active-color" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "crossed-out":
                                    product.prices["RUB"].sale_price,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "woocommerce-Price-amount amount",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "woocommerce-Price-currencySymbol",
                                      },
                                      [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                    ),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.showItemPrice(product)) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            product.prices["RUB"].sale_price
                              ? _c("ins", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-amount amount",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "woocommerce-Price-currencySymbol",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.activeCurrency.code)
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.showItemPrice(product, true)
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.pagination.lastPage > 1
        ? _c("div", { staticClass: "navigation pagination" }, [
            _c("div", { staticClass: "page-numbers" }, [
              1 < _vm.pagination.currentPage - 1
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.firstPage)
                        },
                      },
                    },
                    [_vm._m(1)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.prevPage - 1 >= 1
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.prevPage - 1)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.pagination.prevPage - 1))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.prevPage !== _vm.pagination.currentPage
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.prevPage)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.pagination.prevPage))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "page-numbers current", attrs: { href: "#" } },
                [_c("span", [_vm._v(_vm._s(_vm.pagination.currentPage))])]
              ),
              _vm._v(" "),
              _vm.pagination.nextPage !== _vm.pagination.currentPage
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.nextPage)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.pagination.nextPage))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.nextPage + 1 <= _vm.pagination.lastPage
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.nextPage + 1)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.pagination.nextPage + 1))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.nextPage + 1 < _vm.pagination.lastPage
                ? _c("a", { staticClass: "page-more", attrs: { href: "#" } }, [
                    _c("span", [_vm._v("...")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.lastPage > _vm.pagination.currentPage + 1
                ? _c(
                    "a",
                    {
                      staticClass: "page-numbers",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.pagination.lastPage)
                        },
                      },
                    },
                    [_vm._m(2)]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.products.length
        ? _c(
            "div",
            { staticClass: "no-match", class: { "op-0": _vm.noProducts } },
            [
              _c("h3", { staticClass: "no-match__text" }, [
                _vm._v("Вибачте, немає товарів, що відповідають вашому запиту"),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "zmdi zmdi-chevron-down" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "zmdi zmdi-chevron-left" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "zmdi zmdi-chevron-right" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }