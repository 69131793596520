var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "breadcrumb-contact-us breadcrumb-section section-box",
      staticStyle: { "background-image": "url(/images/1920x330.jpg)" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "breadcrumb-inner" }, [
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.selectedCategory !== null
                  ? _vm.selectedCategory
                  : _vm.breadcrumbs[_vm.breadcrumbs.length - 1].title
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "breadcrumbs" },
            _vm._l(_vm.breadcrumbs, function (link, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    staticClass: "breadcrumbs-1",
                    class: {
                      "breadcrumbs-1--remove-slash":
                        index + 1 === _vm.breadcrumbs.length,
                    },
                    attrs: { href: link.url },
                  },
                  [_vm._v(_vm._s(link.title))]
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }