<template>
  <div class="modal fade" id="searchModal" role="dialog">
    <button class="close" type="button" data-dismiss="modal">
      <i class="zmdi zmdi-close"></i>
    </button>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <form id="searchModal__form" method="POST">
            <button id="searchModal__submit" type="submit">
              <i class="zmdi zmdi-search"></i>
            </button>
            <input
              id="searchModal__input"
              @keyup="getProducts"
              @keypress.enter.prevent="getProducts"
              type="text"
              name="search"
              v-model="search"
              placeholder="Search Here ..."
            />
          </form>
        </div>
      </div>
    </div>
    <div v-if=" search !== '' " class="search-results">
      <!-- <div class="search-results__header search-results__header--align">
        <h4 class="search-results__text-product">Product</h4>
        <h4 class="search-results__text-price">Price</h4>
      </div>-->
      <div :key="index" v-for="(product, index) in products" class="search-results__product">
        <a :href="buildUrl(applicationParams.locale) + product.slug" class="wp-post-image">
          <img
            class="search-results__image"
            v-bind:src="product.images.length ? product.images[0].url : defaultImage.url"
            v-bind:alt="product.images.length ? product.images[0].alt : defaultImage.alt"
          />
        </a>
        <div class="search-results__info">
          <h5 class="search-results__title">
            <a :href="buildUrl(applicationParams.locale) + product.slug">{{ product.title }}</a>
          </h5>
          <span class="search-results__price">
            <span class="price">
              <div :class="{'crossed-out' : product.prices['RUB'].sale_price }">
                <span class="woocommerce-Price-amount amount">
                  <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                  {{ showItemPrice(product) }}
                </span>
              </div>
              <div v-if="product.prices['RUB'].sale_price">
                <span class="woocommerce-Price-amount amount">
                  <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                  {{ showItemPrice(product, true) }}
                </span>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Library for working with requests
import axios from "axios";

import { log } from "util";

import EventBus from "../../EventBus";

export default {
  name: "Search",
  data() {
    return {
      products: [],
      search: "",
      activeCurrency: {},
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  methods: {
    showItemPrice(product, sale) {
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
        console.log("salePrice: ", salePrice);
      }

      if (product.version_items.length) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },
    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    getProducts() {
      let requestUrl = this.API.product.list;

      let requestParams = {
        search: this.search,
        currency_code: this.activeCurrency.code
      };

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.products = response.data.data;
      });
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },
  mounted() {
    // Set currancy
    this.setCurrency();
    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="sass" scoped>
  #searchModal
    overflow-y: auto

  .search-results 
    position: relative
    top: calc(20% + 150px / 2)
    // top: 20%
    width: 81.25%
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    padding-left: 15px
    margin-bottom: calc( 135px / 2 )
    
  .search-results__header
    display: flex
    border-bottom: 1px solid lighten(gray, 40%)
    height: 150px
    padding: 30px

  .search-results__product
    @extend .search-results__header
    align-items: center
    border-right: 1px solid lighten(gray, 40%)
    // flex-basis: 350px
    // flex-grow: 1
    width: calc( 50% - 15px )
    margin: 15px
    margin-top: 0
    margin-left: 0
    transition-property: box-shadow
    transition-duration: 150ms
    box-shadow: 0px 0px 0px rgba(0,0 ,0 ,0.1 )

  .search-results__product:hover
    box-shadow: 0px 0px 5px rgba(0,0 ,0 ,0.1 )

  .search-results__header--align
    align-items: flex-end
    padding-bottom: 15px

  // .search-results__text-product
  //   margin-left: 30px

  // .search-results__text-price
  //   margin-left: 45px

  .search-results__image 
    width: 100px
    height: 90px

  .search-results__info
    margin-left: 30px

    

</style>

