var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "home-slider style-home-slider-hp-1" }, [
    _c(
      "div",
      {
        staticClass: "rev_slider fullscreenbanner",
        attrs: { id: "rev_slider_1", "data-version": "5.4.5" },
      },
      [
        _c(
          "ul",
          _vm._l(_vm.slides, function (slide, index) {
            return _c(
              "li",
              { key: index, attrs: { "data-transition": "boxslide" } },
              [
                _c("img", {
                  staticClass: "rev-slidebg",
                  attrs: { src: slide.image, alt: "slide-" + (index + 1) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tp-caption tp-resizeme slide-caption-title-3",
                    attrs: {
                      "data-frames":
                        '[{"delay":0,"speed":300,"frame":"0","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]',
                      "data-fontsize": "['80', '80', '80', '90']",
                      "data-lineheight": "['60', '60', '50', '50']",
                      "data-color": "['#666','#fff','#fff','#fff']",
                      "data-text-align":
                        "['center', 'center', 'center', 'center']",
                      "data-x": "['right','right','right','right']",
                      "data-y": "['bottom','bottom','bottom','bottom']",
                      "data-hoffset": "['27', '18', '18', '60']",
                      "data-voffset": "['28', '30', '30', '30']",
                      "data-width": "['250', '250', '300', '350']",
                      "data-whitespace": "normal",
                      "data-basealign": "slide",
                      "data-responsive_offset": "off",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s("0" + (index + 1)) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tp-caption tp-resizeme slide-caption-title-3",
                    attrs: {
                      "data-frames":
                        '[{"delay":0,"speed":300,"frame":"0","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]',
                      "data-fontsize": "['13', '15', '20', '35']",
                      "data-lineheight": "['32', '35', '40', '45']",
                      "data-color": "['#666','#fff','#fff','#fff']",
                      "data-text-align":
                        "['center', 'center', 'center', 'center']",
                      "data-x": "['right','right','right','right']",
                      "data-y": "['bottom','bottom','bottom','bottom']",
                      "data-hoffset": "['14', '-23', '-20', '35']",
                      "data-voffset": "['63', '56', '50', '37']",
                      "data-width": "['187', '250', '300', '350']",
                      "data-whitespace": "normal",
                      "data-basealign": "slide",
                      "data-responsive_offset": "off",
                    },
                  },
                  [_vm._v("\n          /\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tp-caption tp-resizeme slide-caption-title-3",
                    attrs: {
                      "data-frames":
                        '[{"delay":0,"speed":300,"frame":"0","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]',
                      "data-fontsize": "['20', '25', '30', '40']",
                      "data-lineheight": "['32', '35', '40', '45']",
                      "data-color": "['#666','#fff','#fff','#fff']",
                      "data-text-align":
                        "['center', 'center', 'center', 'center']",
                      "data-x": "['right','right','right','right']",
                      "data-y": "['bottom','bottom','bottom','bottom']",
                      "data-hoffset": "['-6', '-43', '-40', '15']",
                      "data-voffset": "['63', '56', '50', '37']",
                      "data-width": "['187', '250', '300', '350']",
                      "data-whitespace": "normal",
                      "data-basealign": "slide",
                      "data-responsive_offset": "off",
                    },
                  },
                  [_vm._v("\n          03\n        ")]
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }