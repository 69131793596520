<template>
  <section class="featured-hp-1">
    <div class="container">
      <div class="featured-content woocommerce">
        <h2 class="special-heading">{{ langTitle }}</h2>
        <div class="content-area">
          <div v-for="(row, index) in productRows" :key="index" class="row">
            <!-- Product -->
            <div v-for="(product, index) in row" :key="index" class="col">
              <div class="product type-product">
                <div class="woocommerce-LoopProduct-link">
                  <div class="product-image">
                    <a
                      :href="buildUrl(applicationParams.locale) + product.slug"
                      class="wp-post-image"
                    >
                      <img
                        class="image-cover"
                        v-bind:src="product.images.length ? product.images[0].url : defaultImage.url"
                        v-bind:alt="product.images.length ? product.images[0].alt : defaultImage.alt"
                      />
                      <img
                        v-if="product.images.length > 1"
                        class="image-secondary"
                        v-bind:src="product.images.length ? product.images[1].url : defaultImage.url"
                        v-bind:alt="product.images.length ? product.images[1].alt : defaultImage.alt"
                      />
                    </a>
                    <div class="featured__promo-flags-container">
                      <a v-if="product.promo" href="#" class="onsale">SALE</a>
                      <a v-if="product.new" href="#" class="onnew">NEW</a>
                    </div>

                    <div
                      @click.prevent="addToWishList(product.id, product)"
                      class="yith-wcwl-add-button show"
                    >
                      <a href="#" class="add_to_wishlist">
                        <i
                          class="zmdi favourite-icon zmdi-favorite-outline"
                          :class="{ 'zmdi-favorite' : product.in_wishlist }"
                        ></i>
                      </a>
                    </div>
                    <div class="product-text">
                      <div @click.prevent="addToCart(product)" class="button add_to_cart_button">
                        <a :href="buildUrl(applicationParams.locale) + product.slug">
                          <img src="/images/icons/shopping-cart-black-icon.png" alt="cart" />
                        </a>
                      </div>
                      <h5 class="woocommerce-loop-product__title">
                        <a
                          :href="buildUrl(applicationParams.locale) + product.slug"
                        >{{ product.title }}</a>
                      </h5>
                      <span class="price active-color">
                        <div :class="{'crossed-out' : product.prices['RUB'].sale_price }">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ showItemPrice(product) }}
                          </span>
                        </div>
                        <ins v-if="product.prices['RUB'].sale_price">
                          <span class="woocommerce-Price-amount amount">
                            <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                            {{ showItemPrice(product, true) }}
                          </span>
                        </ins>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-all">
          <a :href="langUrl" class="au-btn btn-small">
            {{ langBtn }}
            <i class="zmdi zmdi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Library for sending requests
import axios from "axios";

import { log } from "util";

import EventBus from "../../EventBus";

export default {
  name: "Featured",
  props: {
    perPage: {
      type: Number,
      default: 16
    },
    categoryId: {
      type: Number
    }
  },
  data() {
    return {
      products: [],
      productRows: [],
      self: this,
      activeCurrency: {},
      langTitle: [{ title: "Новинки" }, { title: "New Products" }],
      langBtn: [{ title: "Посмотреть все" }, { title: "Show all" }],
      langUrl: [{ url: "/categories" }, { url: "/categories/en" }],
      defaultImage: {
        url: "/images/default/product/posebanpoklon_product.jpg",
        alt: ""
      }
    };
  },
  methods: {
    showItemPrice(product, sale) {
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
      }

      if (product.version_items.length) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },

    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },

    setLang() {
      if (applicationParams.locale === "ru") {
        this.langTitle = "Новинки";
        this.langBtn = "Посмотреть все";
        this.langUrl = "/categories";
      } else {
        this.langTitle = "New Products";
        this.langBtn = "Show all";
        this.langUrl = "/categories/en/";
      }
    },

    addToWishList(ProdId, product) {
      if (this.applicationParams.user) {
        if (!product.in_wishlist) {
          product.in_wishlist = true;
          let requestUrl =
            this.API.wishlist.update + applicationParams.user.wishlist.id;

          let requestParams = {
            products: ProdId
          };

          axios.put(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Товар был добавлен в корзину."
          );
        } else {
          product.in_wishlist = false;
          let requestUrl =
            this.API.wishlist.update +
            applicationParams.user.wishlist.id +
            "/remove";

          let requestParams = {
            products: [ProdId]
          };

          axios.post(requestUrl, requestParams);
          this.showNotification(
            "success",
            "Товар был добавлен в корзину."
          );
        }
      } else {
        this.showNotification("error", "Please log in to your account.");
      }
    },
    addToCart(product) {
      EventBus.$emit("add-to-cart", product);
      // EventBus.$emit("products-updated", this.cart.items);
    },
    addToCart(product) {
      EventBus.$emit("add-to-cart", product);
    },
    productUrl(slug) {
      return buildUrl(applicationParams.locale) + slug;
    },
    getProducts() {
      let requestUrl = this.API.product.list;

      let requestParams = {
        per_page: this.perPage,
        currency_code: this.activeCurrency.code,
        locale: this.applicationParams.locale
      };

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.products = response.data.data;
        let rows = Math.ceil(this.products.length / 4);
        let sliceStart = 0;
        for (let i = 1; i <= rows; i++) {
          let newProductRow = this.products.slice(sliceStart, i * 4);
          sliceStart += 4;
          this.productRows.push(newProductRow);
        }
      });
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },

  mounted() {
    // Set currancy
    this.setCurrency();
    //Get products
    this.getProducts();
    // Set default image
    this.setDefaultImage();
    // Set Languages
    this.setLang();
  }
};
</script>

<style lang="sass" scoped>
.add_to_cart_button img
  pointer-events: none
</style>