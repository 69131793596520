var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-lg-9 col-md-9 profile-content-newsletter" },
    [
      _c("div", { staticClass: "profile-information-form" }, [
        _c("h4", { staticClass: "information-form-title" }, [
          _vm._v("\n            Change password\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "information-form-text" }, [
          _vm._v(
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit.\n            Molestiae pariatur perferendis tenetur provident perspiciatis odit totam qui, omnis illo\n            libero neque quos fuga est veritatis cum necessitatibus at optio vel."
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "information-form-checkbox" }, [
          _c("label", { staticClass: "product-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.subscriptionStatus,
                  expression: "subscriptionStatus",
                },
              ],
              staticClass: "product-item-checkbox",
              attrs: { type: "checkbox", checked: "" },
              domProps: {
                checked: Array.isArray(_vm.subscriptionStatus)
                  ? _vm._i(_vm.subscriptionStatus, null) > -1
                  : _vm.subscriptionStatus,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.subscriptionStatus,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.subscriptionStatus = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.subscriptionStatus = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.subscriptionStatus = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "product-item-check" }),
            _vm._v(
              "\n                Prijavite se na newsletter\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "information-form-container form-bottom" }, [
          _c(
            "button",
            {
              staticClass: "information-form-btn",
              on: { click: _vm.submitForm },
            },
            [_vm._v("Save")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }