var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "featured-hp-1" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "featured-content woocommerce" }, [
        _c("h2", { staticClass: "special-heading" }, [
          _vm._v(_vm._s(_vm.langTitle)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-area" },
          _vm._l(_vm.productRows, function (row, index) {
            return _c(
              "div",
              { key: index, staticClass: "row" },
              _vm._l(row, function (product, index) {
                return _c("div", { key: index, staticClass: "col" }, [
                  _c("div", { staticClass: "product type-product" }, [
                    _c("div", { staticClass: "woocommerce-LoopProduct-link" }, [
                      _c("div", { staticClass: "product-image" }, [
                        _c(
                          "a",
                          {
                            staticClass: "wp-post-image",
                            attrs: {
                              href:
                                _vm.buildUrl(_vm.applicationParams.locale) +
                                product.slug,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "image-cover",
                              attrs: {
                                src: product.images.length
                                  ? product.images[0].url
                                  : _vm.defaultImage.url,
                                alt: product.images.length
                                  ? product.images[0].alt
                                  : _vm.defaultImage.alt,
                              },
                            }),
                            _vm._v(" "),
                            product.images.length > 1
                              ? _c("img", {
                                  staticClass: "image-secondary",
                                  attrs: {
                                    src: product.images.length
                                      ? product.images[1].url
                                      : _vm.defaultImage.url,
                                    alt: product.images.length
                                      ? product.images[1].alt
                                      : _vm.defaultImage.alt,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "featured__promo-flags-container" },
                          [
                            product.promo
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "onsale",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v("SALE")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            product.new
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "onnew",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v("NEW")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "yith-wcwl-add-button show",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addToWishList(product.id, product)
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "add_to_wishlist",
                                attrs: { href: "#" },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "zmdi favourite-icon zmdi-favorite-outline",
                                  class: {
                                    "zmdi-favorite": product.in_wishlist,
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "product-text" }, [
                          _c(
                            "div",
                            {
                              staticClass: "button add_to_cart_button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addToCart(product)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.buildUrl(
                                        _vm.applicationParams.locale
                                      ) + product.slug,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/icons/shopping-cart-black-icon.png",
                                      alt: "cart",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h5",
                            { staticClass: "woocommerce-loop-product__title" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.buildUrl(
                                        _vm.applicationParams.locale
                                      ) + product.slug,
                                  },
                                },
                                [_vm._v(_vm._s(product.title))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "price active-color" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "crossed-out":
                                    product.prices["RUB"].sale_price,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "woocommerce-Price-amount amount",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "woocommerce-Price-currencySymbol",
                                      },
                                      [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                    ),
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(_vm.showItemPrice(product)) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            product.prices["RUB"].sale_price
                              ? _c("ins", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-amount amount",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "woocommerce-Price-currencySymbol",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.activeCurrency.code)
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.showItemPrice(product, true)
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "view-all" }, [
          _c(
            "a",
            { staticClass: "au-btn btn-small", attrs: { href: _vm.langUrl } },
            [
              _vm._v("\n          " + _vm._s(_vm.langBtn) + "\n          "),
              _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }