var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "show-desktop-header header-hp-1 style-header-hp-1" },
      [
        _c(
          "div",
          { staticClass: "menu-desktop", attrs: { id: "js-navbar-fixed" } },
          [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                _c("div", { staticClass: "menu-desktop-inner" }, [
                  _c("div", { staticClass: "logo" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.applicationParams.locale === "ru" ? "/" : "/en",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.applicationParams.siteLogo,
                            alt: "logo",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("nav", { staticClass: "main-menu" }, [
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.menu, function (menu, index) {
                          return _c(
                            "li",
                            {
                              key: index + menu.title,
                              staticClass: "menu-item",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "main-menu__main-link",
                                  class: { current: _vm.ifActive(menu.url) },
                                  attrs: { href: menu.url },
                                },
                                [_vm._v(_vm._s(menu.title))]
                              ),
                              _vm._v(" "),
                              menu.children.length
                                ? _c(
                                    "ul",
                                    { staticClass: "sub-menu" },
                                    _vm._l(
                                      menu.children,
                                      function (subMenu, index) {
                                        return _c(
                                          "li",
                                          { key: index + subMenu.title },
                                          [
                                            _c(
                                              "a",
                                              { attrs: { href: subMenu.url } },
                                              [_vm._v(_vm._s(subMenu.title))]
                                            ),
                                            _vm._v(" "),
                                            subMenu.children
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "sub-menu menu-levels",
                                                  },
                                                  _vm._l(
                                                    subMenu.children,
                                                    function (subMenu1, index) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            index +
                                                            subMenu.children,
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: subMenu1.url,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subMenu1.title
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "menu-item" }, [
                          _c("a", { attrs: { href: "/profile/edit" } }, [
                            _vm._v("Profile"),
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "sub-menu" }, [
                            _vm._m(0),
                            _vm._v(" "),
                            _vm.applicationParams.user
                              ? _c(
                                  "form",
                                  {
                                    attrs: {
                                      action: "/logout",
                                      name: "logout",
                                      method: "post",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "hide-submit",
                                      attrs: { type: "submit" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.logOut.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Sign out")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.applicationParams.user
                              ? _c("li", [
                                  _c("a", { attrs: { href: "/en/login" } }, [
                                    _vm._v("Login/Register"),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "header-right" },
                    [
                      _c("ul", { staticClass: "main-menu main-menu--right" }, [
                        _c(
                          "li",
                          { staticClass: "menu-item menu-item--right" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "current",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.activeLang.title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "sub-menu" },
                              _vm._l(_vm.langList, function (lang, index) {
                                return _c(
                                  "li",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: lang !== _vm.activeLang,
                                        expression: "lang !== activeLang",
                                      },
                                    ],
                                    key: index + lang.title,
                                  },
                                  [
                                    _c("a", { attrs: { href: lang.url } }, [
                                      _vm._v(_vm._s(lang.title)),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("DropDownCartEn"),
                      _vm._v(" "),
                      _vm._m(3),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("Search"),
                _vm._v(" "),
                _vm._m(4),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "show-mobile-header",
        attrs: { id: "js-navbar-mb-fixed" },
      },
      [
        _c("div", { staticClass: "mobile-top-header" }, [
          _c("div", { staticClass: "logo-mobile" }, [
            _c("a", { attrs: { href: "/en" } }, [
              _c("img", {
                attrs: { src: _vm.applicationParams.siteLogo, alt: "logo" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "hamburger-container" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("a", { attrs: { href: "https://fotofonu.com/en/cart" } }, [
              _c("div", { staticClass: "cart-contents padding-10" }, [
                _c("img", {
                  attrs: {
                    src: "/images/icons/header-icon-2.png",
                    alt: "cart",
                  },
                }),
                _vm._v(" "),
                _vm.cart.productsCount
                  ? _c("span", { staticClass: "cartAmount" }, [
                      _vm._v(_vm._s(this.cart.productsCount)),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "au-navbar-mobile navbar-mobile-1" }, [
          _c("div", { staticClass: "au-navbar-menu" }, [
            _c("ul", [
              _c("li", { staticClass: "drop" }, [
                _c(
                  "a",
                  {
                    staticClass: "drop-link",
                    class: { current: _vm.ifActive(_vm.menu.url) },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.menu[0].title) +
                        "\n              "
                    ),
                    _vm._m(6),
                  ]
                ),
                _vm._v(" "),
                _vm.menu[0].children.length
                  ? _c(
                      "ul",
                      { staticClass: "drop-menu bottom-right" },
                      _vm._l(_vm.menu[0].children, function (subMenu, index) {
                        return _c(
                          "li",
                          { key: index + subMenu.title, staticClass: "drop" },
                          [
                            subMenu.children.length
                              ? _c("a", { staticClass: "drop-link" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(subMenu.title) +
                                      "\n                  "
                                  ),
                                  _vm._m(7, true),
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "drop-link",
                                    attrs: { href: subMenu.url },
                                  },
                                  [_vm._v(_vm._s(subMenu.title))]
                                ),
                            _vm._v(" "),
                            subMenu.children
                              ? _c(
                                  "ul",
                                  { staticClass: "drop-menu bottom-right" },
                                  _vm._l(
                                    subMenu.children,
                                    function (subMenu1, index) {
                                      return _c(
                                        "li",
                                        { key: index + subMenu.children },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "bottom-right-plus",
                                              attrs: { href: subMenu1.url },
                                            },
                                            [_vm._v(_vm._s(subMenu1.title))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "drop" }, [
                _c(
                  "a",
                  {
                    staticClass: "drop-link",
                    attrs: { href: "'#'" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [_vm._v("\n              Profile\n              "), _vm._m(8)]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "drop-menu bottom-right" }, [
                  _vm._m(9),
                  _vm._v(" "),
                  _vm._m(10),
                  _vm._v(" "),
                  _vm.applicationParams.user
                    ? _c("li", { staticClass: "drop" }, [
                        _c(
                          "a",
                          {
                            staticClass: "drop-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.logOut.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Sign out")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.applicationParams.user
                    ? _c("li", { staticClass: "drop" }, [
                        _c(
                          "a",
                          {
                            staticClass: "drop-link",
                            attrs: { href: "/en/login" },
                          },
                          [_vm._v("Login/Register")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(11),
              _vm._v(" "),
              _c("li", { staticClass: "drop" }, [
                _c("a", { staticClass: "drop-link", attrs: { href: "#" } }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.activeLang.title) +
                      "\n              "
                  ),
                  _vm._m(12),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "drop-menu bottom-right" },
                  _vm._l(_vm.langList, function (lang, index) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: lang !== _vm.activeLang,
                            expression: "lang !== activeLang",
                          },
                        ],
                        key: index + lang.title,
                        staticClass: "drop",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "drop-link",
                            attrs: { href: lang.url },
                          },
                          [_vm._v(_vm._s(lang.title))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "drop" }, [
      _c("a", { staticClass: "drop-link", attrs: { href: "/en/wishlist" } }, [
        _vm._v("Wishlist"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "menu-item" }, [
      _c("a", { staticClass: "main-menu__main-link", attrs: { href: "/" } }, [
        _vm._v("Укр."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-btn-wrap" }, [
      _c(
        "button",
        {
          staticClass: "search-btn",
          attrs: { "data-toggle": "modal", "data-target": "#searchModal" },
        },
        [
          _c("img", {
            attrs: { src: "/images/icons/header-icon-1.png", alt: "search" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "canvas canvas-btn-wrap" }, [
      _c(
        "button",
        {
          staticClass: "canvas-/images canvas-btn",
          attrs: { "data-toggle": "modal", "data-target": "#canvasModal" },
        },
        [
          _c("img", {
            attrs: { src: "/images/icons/header-icon-3.png", alt: "canvas" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "canvasModal", role: "dialog" },
      },
      [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button", "data-dismiss": "modal" },
          },
          [_c("i", { staticClass: "zmdi zmdi-close" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "canvas-content" }, [
                _c("div", { staticClass: "logo" }, [
                  _c("a", { attrs: { href: "index1.html" } }, [
                    _c("img", {
                      attrs: {
                        src: "/images/icons/logo-black.png",
                        alt: "logo",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "insta" }, [
                  _c("div", { staticClass: "insta-inner" }, [
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-1.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-2.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-3.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "insta-inner" }, [
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-4.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-5.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "/images" }, [
                      _c(
                        "a",
                        { staticClass: "/images-inner", attrs: { href: "#" } },
                        [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "/images/hp-1-canvas-6.jpg",
                              alt: "insta",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _c("h4", [_vm._v("Contact")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contact-inner" }, [
                    _c("i", { staticClass: "zmdi zmdi-map" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "contact-info" }, [
                      _c("span", [_vm._v("No 40 Baria Sreet 133/2")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contact-inner" }, [
                    _c("i", { staticClass: "zmdi zmdi-phone" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "contact-info" }, [
                      _c("span", [
                        _c("a", { attrs: { href: "tel:15618003666666" } }, [
                          _vm._v("+ (156) 1800-366-6666"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contact-inner" }, [
                    _c("i", { staticClass: "zmdi zmdi-email" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "contact-info" }, [
                      _c("span", [_vm._v("Eric-82@example.com")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contact-inner" }, [
                    _c("i", { staticClass: "zmdi zmdi-globe" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "contact-info" }, [
                      _c("span", [_vm._v("www.novas.com")]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "email" }, [
                  _c("div", { staticClass: "send" }, [
                    _c("i", { staticClass: "zmdi zmdi-mail-send" }),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "email",
                      required: "",
                      pattern: "[^@]+@[^@]+.[a-zA-Z]{2,6}",
                      name: "email",
                      placeholder: "Your e-mail...",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "socials" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "zmdi zmdi-facebook" }),
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "zmdi zmdi-twitter" }),
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "zmdi zmdi-tumblr" }),
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "zmdi zmdi-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "hamburger hamburger--spin hidden-tablet-landscape-up",
        attrs: { id: "toggle-icon" },
      },
      [
        _c("span", { staticClass: "hamburger-box" }, [
          _c("span", { staticClass: "hamburger-inner" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow" }, [
      _c("i", { staticClass: "zmdi zmdi-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow" }, [
      _c("i", { staticClass: "zmdi zmdi-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow" }, [
      _c("i", { staticClass: "zmdi zmdi-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "drop" }, [
      _c("a", { staticClass: "drop-link", attrs: { href: "/profile/edit" } }, [
        _vm._v("Edit profile"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "drop" }, [
      _c("a", { staticClass: "drop-link", attrs: { href: "/en/wishlist" } }, [
        _vm._v("Wishlist"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "drop" }, [
      _c("a", { staticClass: "drop-link", attrs: { href: "#" } }, [
        _vm._v("\n              Cart\n              "),
        _c("span", { staticClass: "arrow" }, [
          _c("i", { staticClass: "zmdi zmdi-chevron-down" }),
        ]),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "drop-menu bottom-right" }, [
        _c("li", { staticClass: "drop" }, [
          _c("a", { staticClass: "drop-link", attrs: { href: "/en/cart" } }, [
            _vm._v("View cart"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "drop" }, [
          _c(
            "a",
            { staticClass: "drop-link", attrs: { href: "/en/checkout" } },
            [_vm._v("Checkout")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "arrow" }, [
      _c("i", { staticClass: "zmdi zmdi-chevron-down" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }